<script lang="ts">
import { defineComponent } from 'vue';
import AfsEnergyLogo from '@/assets/images/afs-energy-white.svg';
import { MAIN_ROUTES } from '@/config/constants/routes';

export default defineComponent({
  name: 'LogoNav',
  components: {
    AfsEnergyLogo,
  },
  setup() {
    return {
      MAIN_ROUTES,
    };
  },
});
</script>

<template>
  <div class="logo">
    <router-link :to="MAIN_ROUTES.DASHBOARD.path">
      <AfsEnergyLogo class="logo__itself" />
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
.logo {
  display: flex;
  width: remCalc(42);
  overflow: hidden;

  &__itself {
    width: remCalc(80);
    transition: all 0.3s ease-out;
  }
}

.iconbar-expanded .logo {
  display: block;
  width: remCalc(120);
  margin-top: var(--space-4xs);
  margin-bottom: var(--space-4xs);
}

:deep(svg) {
  path:first-child {
    display: none;
  }
}
</style>
<style lang="scss">
.iconbar-expanded {
  .logo svg {
    width: remCalc(120) !important;

    path:first-child {
      display: block !important;
    }
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import BaseIcon from '@/components/base/BaseIcon.vue';

export default defineComponent({
  name: 'BackButton',
  components: {
    BaseIcon,
  },
  props: {
    routeName: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const router = useRouter();

    const goBack = (): void => {
      if (props.routeName) {
        router.push({ name: props.routeName });
      } else {
        router.back();
      }
    };

    return {
      goBack,
      router,
    };
  },
});
</script>

<template>
  <div data-cy="HeaderBackButton" class="back-button" @click="goBack()">
    <BaseIcon
      data-cy="BackButtonIcon"
      icon-name="chevron--left"
      class="back-button__icon"
      :size="24"
    />
    <span class="back-button__text">
      {{ $t('base.header.backButton') }}
    </span>
  </div>
</template>
<style lang="scss" scoped>
.back-button {
  @extend %font-button-sm;
  cursor: pointer;
  color: var(--color-secondary);
  display: flex;
  align-items: center;

  &__icon {
    height: remCalc(16);
    width: remCalc(16);
    margin-right: var(--space-6xs);
    stroke: var(--color-secondary);
    stroke-width: 2px;
    stroke-linejoin: round;
  }

  &__text {
    @extend %fw-700;
  }
}
</style>

import { ApolloError } from '@apollo/client/errors';

export enum NotificationType {
  MESSAGE = 'message',
  ALERT = 'alert',
  ERROR = 'error',
  NOTIFY = 'notify',
  SUCCESS = 'success',
}
export interface NotificationModel {
  message: string;
  type: NotificationType;
  description?: string;
  showIcon?: boolean;
  callBackOnClick?: () => void;
}

export interface NotificationsItemModel {
  id: string;
  title: string;
  shortMessage: string;
  message: string;
  isLink: boolean;
  linkText: string;
  link: string;
  date: string;
  isReaded: boolean;
  noClientView?: boolean;
  type?: string;
  companyId?: string;
  chatLink?: boolean;
  occurred_at: string;
}

export interface PushNotificationsStoreModel {
  notifications: (NotificationsItemModel | null)[];
  isLoading: boolean;
  error: ApolloError | null;
}

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { SelectedProductType } from '@/types/user';
import { getTranslationTerms } from '@/utils/composable/localeHelper';
import BaseLoader from '@/components/base/BaseLoader.vue';
// temporarily no-duplicates until we don't receive others images
// eslint-disable-next-line import/no-duplicates
import GO from '@/assets/images/products/GO.jpg';
// eslint-disable-next-line import/no-duplicates
import CFC from '@/assets/images/products/GO.jpg';
import I_REC from '@/assets/images/products/I-REC.jpg';
import F_GAS from '@/assets/images/products/F-Gas.jpg';
import BIOMETHANE_HEATING from '@/assets/images/products/BIOMETHANE_HEATING.jpg';
import BIOMETHANE_TRANSPORT from '@/assets/images/products/BIOMETHANE_TRANSPORT.jpg';
import EU_ETS from '@/assets/images/products/CCM.png';
import VCM from '@/assets/images/products/VCM.jpg';
import HBE from '@/assets/images/products/HBE.jpg';
import THG_Q from '@/assets/images/products/THG_Q.jpg';
import RTFC from '@/assets/images/products/RTFC.jpg';
import BIOFUELS from '@/assets/images/products/BIOFUELS.jpg';
import PPA from '@/assets/images/products/PPA.jpg';
import REFRIGERANT_GAS from '@/assets/images/products/REFRIGERANT_GAS.jpg';
import RTC from '@/assets/images/products/RTC.jpg';
import RECS from '@/assets/images/products/RECS.jpg';
import MANAGEMENT from '@/assets/images/products/Management.jpg';

export default defineComponent({
  name: 'ProductCard',
  components: {
    BaseLoader,
  },
  props: {
    product: {
      type: Object as PropType<SelectedProductType>,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isAvailable: {
      type: Boolean,
      default: true,
    },
    isClientRole: {
      type: Boolean,
      default: false,
    },
    isClientView: {
      type: Boolean,
      default: false,
    },
    isPending: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select', 'request-access'],
  setup(props, { emit }) {
    const getProductName = getTranslationTerms.bind(null, 'products', 'product');
    const getPageTerms = getTranslationTerms.bind(null, 'pages', 'productSwitcher');

    const images = ref({
      GO,
      BIOMETHANE_HEATING,
      BIOMETHANE_TRANSPORT,
      EU_ETS,
      VCM,
      HBE,
      THG_Q,
      RTFC,
      BIOFUELS,
      F_GAS,
      PPA,
      CFC,
      I_REC,
      REFRIGERANT_GAS,
      RTC,
      RECS,
      MANAGEMENT,
    });

    const cardClasses = computed(() => [
      'product-card',
      `product-card-${props.product.abbr}`,
      {
        active: props.isActive,
        'not-available': !props.product.available && props.isClientRole,
        'client-view': props.isClientView,
      },
    ]);

    const handleClick = () => {
      if (props.product.available) {
        emit('select', props.product);
      }
    };

    const handleRequestAccess = (event: Event) => {
      event.stopPropagation();
      emit('request-access', props.product.id);
    };

    return {
      cardClasses,
      getProductName,
      getPageTerms,
      handleClick,
      handleRequestAccess,
      images,
    };
  },
});
</script>

<template>
  <div :class="cardClasses" @click="handleClick">
    <img :src="images[product.abbr as keyof typeof images]" alt="product-image" class="bg-img" />
    <div class="clickable-wrapper">
      <div class="product-name">
        {{ getProductName(product.abbr) }}
      </div>
      <div v-if="!product.available && isClientRole" class="custom-button__wrapper">
        <div class="product-name--requester">
          {{ getProductName(product.abbr) }}
        </div>
        <BaseLoader v-if="isLoading" variant="light" />
        <template v-else>
          <div
            v-if="!isPending && !isClientView"
            class="custom-button"
            @click="handleRequestAccess"
          >
            {{ getPageTerms('requestAccess') }}
          </div>
          <div v-else-if="!isClientView" class="custom-button disabled">
            {{ getPageTerms('requestedAccess') }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.product-card {
  box-shadow: 0 4px 4px 0 var(--color-notification-notify-2);
  position: relative;
  border-radius: 10px;
  min-height: 7rem;
  overflow: hidden;
  max-height: remCalc(200);
  cursor: pointer;
  isolation: isolate;
  margin-bottom: var(--space-xs);

  @media screen and (min-width: $mobile-plus) {
    min-height: 100%;
  }

  &.active {
    border: 3px solid #3137fdbf;
    box-shadow: 0px 24px 32px #3137fd40;
    .product-name {
      @extend %fw-700;
    }

    .role-bsupp & {
      border: 3px solid #8d0220;
      box-shadow: 0px 24px 32px #8d022040;
    }

    .role-advisor & {
      border: 3px solid #174e0dbf;
      box-shadow: 0px 24px 32px #174e0d40;
    }
  }

  &.not-available {
    .custom-button__wrapper {
      transform: translateY(100%);
    }

    .product-name {
      transform: translateY(0%);
    }

    cursor: default;
    img {
      filter: grayscale(1) blur(5px);
      transition: 0.3s ease-in-out filter;
    }
    &:not(.client-view):hover,
    .product-requester & {
      img {
        filter: grayscale(0) blur(5px);
      }

      .custom-button__wrapper {
        transform: translateY(0%);
      }

      .product-name {
        transform: translateY(-100%);
      }
    }
  }

  &:not(.not-available):hover {
    .clickable-wrapper:before {
      background-color: var(--color-primary);
    }
  }

  img {
    filter: blur(0px);
    transition: filter 0.3s ease-in-out;
  }
  &:not(.client-view):hover {
    img {
      filter: blur(5px);
    }
  }

  .bg-img {
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .clickable-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    z-index: 1;

    .product-name {
      padding: 0 remCalc(16);
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      color: var(--color-light);
      font-size: remCalc(16);
      line-height: 1.2;
      flex-direction: column;
      transition: 0.3s ease-in-out;
      z-index: 2;
    }

    .custom-button {
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-top: remCalc(10);
      text-align: center;
      padding: remCalc(8) remCalc(12);
      border-radius: 30px;
      background-color: var(--color-shade-8);
      color: var(--color-main);
      font-size: remCalc(16);
      width: max-content;
      transition:
        0.3s linear background-color,
        0.3s linear color;
      line-height: 1;

      &__wrapper {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s ease-in-out;
        padding: 0 0.5rem;
      }
      &.disabled {
        background-color: var(--color-shade-3);
        color: var(--color-shade-8);
        cursor: default;
        &:hover {
          background-color: var(--color-shade-2);
          color: var(--color-shade-8);
        }
      }

      &:hover {
        background-color: var(--color-main);
        color: var(--color-shade-8);
      }
    }

    &:before {
      pointer-events: none;
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      opacity: 0.5;
      transition:
        0.3s linear background-color,
        0.3s linear opacity;
      background-color: var(--color-main);
      z-index: 1;
    }
  }
}

.product-name {
  &--requester {
    display: none;
  }
}

.product-requester {
  .product-name--requester {
    display: block;
  }
  .product-card {
    min-height: 10rem;
  }

  .custom-button__wrapper {
    z-index: 99;
    display: flex;
    flex-direction: column;
    color: #fff;
  }
}
</style>

import { createI18n, DefaultLocaleMessageSchema } from 'vue-i18n';
import { computed, ComputedRef, nextTick, WritableComputedRef } from 'vue';
import { AllowedTranslationGlobalTypes, Locales } from '@/types/locale';
import enTranslations from '@/lang/en.json';
import { ALLOWED_LOCALES, DEFAULT_LOCALE } from '@/config/constants/global';
// import 'dayjs/locale/de'; // import German dayjs locale
const numberFormats = {
  en: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'code',
      useGrouping: true,
      maximumFractionDigits: 5,
    },
  },
};

export const i18n = createI18n<[DefaultLocaleMessageSchema], 'en'>({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: enTranslations,
  },
  numberFormats,
});

const loadLocaleTerms = async (locale: Locales): Promise<unknown> => {
  if (!ALLOWED_LOCALES.includes(locale)) return Promise.reject();
  /*
   * Disabled this line because in case when i18n in legacy mode locale it is empty object
   * in our case we use composition mode
   * */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const globalTerms: ComputedRef<{}> = i18n.global.messages;

  if (Object.keys(globalTerms.value).includes(locale)) return Promise.resolve();

  try {
    const terms = await import(`@/lang/${locale}.json`);
    i18n.global.setLocaleMessage(locale, terms.default);

    return nextTick();
  } catch (e) {
    return Promise.reject();
  }
};

export const changeGlobalLocale = async (newLocale: Locales): Promise<Locales> => {
  /*
   * Disabled this line because in case when i18n in legacy mode locale it is String
   * in our case we use composition mode
   * */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const globalLocale: WritableComputedRef<Locales> = i18n.global.locale;

  if (globalLocale.value === newLocale) return Promise.reject(globalLocale.value);

  if (newLocale !== DEFAULT_LOCALE) {
    try {
      await loadLocaleTerms(newLocale);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  globalLocale.value = newLocale;

  return Promise.resolve(globalLocale.value);
};

export const getCurrentLocale = computed<Locales>(() => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const globalLocale: WritableComputedRef<Locales> = i18n.global.locale;
  return globalLocale.value;
});

export const getTranslationTerms = (
  globalType: AllowedTranslationGlobalTypes,
  name: string,
  term: string,
  dynamicPart?: { [key: string]: string },
): string => {
  const { t } = i18n.global;

  if (!!dynamicPart && Object.keys(dynamicPart).length > 0) {
    return t(`${globalType}.${name}.${term}`, dynamicPart);
  }

  return t(`${globalType}.${name}.${term}`);
};

export const getInputTerms = getTranslationTerms.bind(null, 'base', 'inputs');

export const getBtnTerms = getTranslationTerms.bind(null, 'base', 'buttons');

export const getBackBtnTerms = getTranslationTerms.bind(null, 'base', 'backButtons');

export const checkTranslationKey = (key: string): boolean => {
  const { te } = i18n.global;
  return te(key);
};

export const { t, n, tm, rt } = i18n.global;

import { Ref, ref, watch, computed, CSSProperties } from 'vue';
import { RouteLocationNormalized } from 'vue-router';
import { useUserStore } from '@/store';
import { iconBarNavItems, userRoleSidebarItems } from '@/config/constants/navigation';
import { navItem } from '@/types/navigation';
import { UserRole, SelectedProductType } from '@/types/user';
import { MODULES_GROUPS, MODULES_LIST, MODULES_NAMES } from '@/config/constants/modules';
import { PRODUCTS_ABBR_LIST } from '@/config/constants/products';
import { ModulesListItem } from '@/types/base-types';
import { useGetModulesWithGroupsQuery } from '@/types/graphql';
import { useGetRepresentativeProductsLazyQuery } from '@/types/graphql/energyExchange';

export const productSwitcherIcon: Ref<string> = ref('dashboard');
export const sideNavItems: Ref<navItem[]> = ref([]);
export const commonItems: Ref<navItem[]> = ref([]);

export const groupedModulesList: Ref<Record<string, ModulesListItem[]>> = ref({});
const allModules: Ref<ModulesListItem[]> = ref([]);

/*
 * Window width
 * */
export const windowWidth = ref(window.innerWidth);
export const isMobileWindowWidth = computed(() => windowWidth.value < 600);
export const updateWidth = () => {
  windowWidth.value = window.innerWidth;
};

/*
 * Toggle menu func helpers
 * */
export const isMobileMenuOpen = ref(false);
export const iconBarOpen = ref(false);
export const isIconBarOpen = computed(() => {
  if (isMobileWindowWidth.value) {
    return true;
  }
  return iconBarOpen.value;
});
export const sideBarOpen = ref(false);

export const toggleMenu = (menuState: boolean): boolean => (isMobileMenuOpen.value = menuState);
export const iconBarToggle = (iconBarState: boolean) => {
  iconBarOpen.value = iconBarState;

  const isSafari = 'webkitTextStroke' in document.body.style;

  // For safari we must trigger a reflow after the menu animation finishes to expand.
  // This is mainly due to issues with grid support and grid-template-columns: auto 1fr;
  if (isSafari) {
    const bodyStyle = document.body.style as unknown as CSSProperties;

    setTimeout(() => {
      bodyStyle.zoom = '99.99%';
      requestAnimationFrame(() => {
        bodyStyle.zoom = '100%';
      });
    }, 500);
  }
};

// Define a function to detect if the screen width implies an open or closed state
function defaultStateBasedOnScreenWidth() {
  // Assuming 768px as a threshold for mobile devices
  return window.innerWidth > 768;
}

// Attempt to read the state from localStorage, fallback to screen width check
const initialState = localStorage.getItem('iconBarOpen')
  ? JSON.parse(localStorage.getItem('iconBarOpen') || '')
  : defaultStateBasedOnScreenWidth();

iconBarOpen.value = initialState;

watch(
  iconBarOpen,
  (newValue): void => {
    localStorage.setItem('iconBarOpen', JSON.stringify(newValue));
  },
  { immediate: true },
);

/*
 * Main menu helpers
 * mainNavItems -> Array with all navigation links
 * initMainNavItems -> Function helper that update mainNavItems var depend on user role
 * cleanMainNav -> Function that help to clean mainNavItems
 * */
export const mainNavItems: Ref<navItem[]> = ref([]);
export const addBorderClasses = (specificItems: navItem[]): navItem[] => {
  const localItems = [...specificItems];
  if (localItems.length > 0) {
    const allowedUserMenuLength = localItems.length;

    if (allowedUserMenuLength === 1) {
      localItems[0].classes = 'border--top-bot';
    } else {
      localItems[0].classes = 'border--top';
      localItems[allowedUserMenuLength - 1].classes = 'border--bot';
    }
  }

  return localItems;
};
export const replaceProductSlug = (items: navItem[], productSlug: string) =>
  items.map((item: navItem) => {
    if (!item?.path) return item;
    return {
      ...item,
      path: item.path.replace(':productSlug', productSlug),
    };
  });

const availableProducts: Ref<SelectedProductType[] | undefined> = ref(undefined);
let userId: string | null | undefined = '';
const getAccountProducts = (): Promise<SelectedProductType[] | undefined> =>
  new Promise((resolve) => {
    const userStore = useUserStore();
    const { load, onError, onResult } = useGetRepresentativeProductsLazyQuery(
      {
        id: userStore.userProfile.id as string,
      },
      { fetchPolicy: 'cache-and-network' },
    );
    onResult((result) => {
      if (!result.loading && result.data?.account) {
        resolve(
          result.data?.account?.companyRepresentativeProducts?.edges?.map((item) => ({
            id: item?.node?.id ?? '',
            abbr: item?.node?.abbreviation ?? '',
            name: item?.node?.name ?? '',
          })),
        );
      }
    });

    onError(async () => {
      resolve([]);
    });
    load();
  });

export const checkEnergyExchangeAvailable = async () => {
  const userStore = useUserStore();
  if (userId !== userStore.userProfile.id) {
    userId = userStore.userProfile.id;
  }
  availableProducts.value = await getAccountProducts();
  return !!availableProducts.value?.find((product) => product.abbr === PRODUCTS_ABBR_LIST.euEts);
};

export const getModulesGroup = (
  groupName: string,
  isEnergyExchangeAvailable?: boolean,
): ModulesListItem[] => {
  const userStore = useUserStore();
  let modulesGroup = allModules.value.filter(
    (item) =>
      item.dashboardGroups?.edges &&
      item.dashboardGroups?.edges[0]?.node?.slug === groupName &&
      (userStore.getSelectedProduct?.id ||
        (item.dashboardGroups?.edges[0]?.node?.slug === MODULES_NAMES.communication &&
          item.slug !== MODULES_LIST.rfq &&
          item.slug !== MODULES_LIST.docLib)),
  );
  if (
    groupName === MODULES_NAMES.energyExchange &&
    userStore.getSelectedProduct?.abbr === PRODUCTS_ABBR_LIST.euEts
  ) {
    if (!userStore.isClientRole) {
      modulesGroup.push(
        {
          id: 'indicationOfInterestId',
          name: 'Indication of Interests',
          slug: MODULES_LIST.indicationOfInterests,
        },
        {
          id: 'energyExchangeId',
          name: 'Energy Exchange',
          slug: MODULES_LIST.energyExchangeManagement,
        },
      );
    } else {
      modulesGroup.push({
        id: 'energyExchangeId',
        name: 'Energy Exchange',
        slug: MODULES_LIST.energyExchange,
      });
    }
  } else if (
    userStore.isClientRole &&
    groupName === MODULES_NAMES.energyExchange &&
    !isEnergyExchangeAvailable
  ) {
    modulesGroup.push({
      id: 'energyExchangeClientId',
      name: 'Energy Exchange',
      slug: MODULES_LIST.energyExchangeClient,
    });
  }
  if (userStore.isClientRole) {
    modulesGroup = modulesGroup.filter(
      (item: ModulesListItem) =>
        item?.slug !== undefined &&
        (userStore.getSelectedProduct?.allowedModules?.includes(item?.slug) ||
          item.slug === MODULES_LIST.energyExchangeClient) &&
        item.slug !== MODULES_LIST.chat,
    );
  }
  return modulesGroup;
};

export const updateModulesGroups = async () => {
  const { getSelectedProduct, isClientRole } = useUserStore();
  let groupsList = MODULES_GROUPS;
  if (!allModules.value.length) {
    const { onResult } = useGetModulesWithGroupsQuery();
    onResult((result) => {
      if (!result.loading) {
        allModules.value = JSON.parse(
          JSON.stringify(
            result.data?.modules?.edges?.map((item) => ({
              id: item?.node?.id,
              slug: item?.node?.slug,
              name: item?.node?.name,
              dashboardGroups: item?.node?.dashboardGroups,
            })),
          ),
        );
        if (
          getSelectedProduct?.abbr !== PRODUCTS_ABBR_LIST.hbe &&
          getSelectedProduct?.abbr !== PRODUCTS_ABBR_LIST.biomethaneTransport &&
          getSelectedProduct?.abbr !== PRODUCTS_ABBR_LIST.thg_q
        ) {
          groupsList = groupsList.filter((item) => item !== MODULES_NAMES.commodityPrices);
        }
        if (getSelectedProduct?.abbr === PRODUCTS_ABBR_LIST.euEts) {
          groupsList.push(MODULES_NAMES.energyExchange);
        }
        groupsList.forEach(async (group) => {
          let isEnergyExchangeAvailable: boolean | undefined = false;
          if (group === MODULES_NAMES.energyExchange && isClientRole) {
            isEnergyExchangeAvailable = await checkEnergyExchangeAvailable();
          }
          groupedModulesList.value[group] = getModulesGroup(group, isEnergyExchangeAvailable);
        });
      }
    });
    return;
  }
  if (
    getSelectedProduct?.abbr !== PRODUCTS_ABBR_LIST.hbe &&
    getSelectedProduct?.abbr !== PRODUCTS_ABBR_LIST.biomethaneTransport &&
    getSelectedProduct?.abbr !== PRODUCTS_ABBR_LIST.thg_q
  ) {
    groupsList = groupsList.filter((item) => item !== MODULES_NAMES.commodityPrices);
    delete groupedModulesList.value.COMMODITY_MARKET;
  }
  if (getSelectedProduct?.abbr === PRODUCTS_ABBR_LIST.euEts) {
    groupsList.push(MODULES_NAMES.energyExchange);
  }
  groupsList.forEach(async (group) => {
    let isEnergyExchangeAvailable: boolean | undefined = false;
    if (group === MODULES_NAMES.energyExchange && isClientRole) {
      isEnergyExchangeAvailable = await checkEnergyExchangeAvailable();
    }
    groupedModulesList.value[group] = getModulesGroup(group, isEnergyExchangeAvailable);
  });
};

export const initMainNavItems = (userRole: UserRole): void => {
  const { getSelectedProduct } = useUserStore();
  const productSlug = computed(() => getSelectedProduct?.abbr?.toLocaleLowerCase());
  const allowedUserRoleMenu: navItem[] = userRoleSidebarItems.filter((el: navItem): boolean => {
    if (!el.role && !Array.isArray(el.role)) return false;

    return el.role?.includes(userRole);
  });

  const transformedNavItems = addBorderClasses(allowedUserRoleMenu);

  commonItems.value = [...iconBarNavItems];
  commonItems.value.splice(1, 0, ...transformedNavItems);
  commonItems.value = replaceProductSlug(commonItems.value, productSlug.value as string);

  mainNavItems.value = [...commonItems.value, ...sideNavItems.value];
};
export const cleanMainNav = (): void => {
  mainNavItems.value = [];
};

export const onRouteChange = (currRoute: RouteLocationNormalized): void => {
  sideBarOpen.value = currRoute.path.includes('dashboard');
};

<script lang="ts">
import { defineComponent, getCurrentInstance } from 'vue';
import BaseIcon from '@/components/base/BaseIcon.vue';
import BaseSkeletonLoader from '@/components/base/BaseSkeletonLoader.vue';
import { createDebounce } from '@/utils/debounce';

export default defineComponent({
  name: 'BaseSearch',
  components: { BaseIcon, BaseSkeletonLoader },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
    clearButton: {
      type: Boolean,
      default: false,
    },
    dataCy: { type: String, required: true },
    isLoading: {
      type: Boolean,
      default: false,
    },
    debounce: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, ctx) {
    const cypressDataAttribute = getCurrentInstance()?.type.name;
    const debounceFn = createDebounce();

    const updateValue = (event: Event) => {
      const eventValue = (event.target as HTMLInputElement).value;
      if (!props.debounce) {
        ctx.emit('update:modelValue', eventValue.trim());
      } else {
        debounceFn(() => {
          ctx.emit('update:modelValue', eventValue.trim());
        }, 300);
      }
    };

    const clear = () => {
      ctx.emit('update:modelValue', '');
    };

    return { updateValue, clear, cypressDataAttribute };
  },
});
</script>

<template>
  <BaseSkeletonLoader v-if="isLoading" min-height="2.5rem" />
  <div v-else class="searchbar">
    <BaseIcon
      icon-name="search"
      :data-cy="`${cypressDataAttribute}-${dataCy}Search`"
      class="searchbar__icon"
      alt="search-icon"
    />
    <input
      :data-cy="`${cypressDataAttribute}SearchInput${dataCy}`"
      :value="modelValue"
      :class="['searchbar__input', { searchbar__input_clear: clearButton && modelValue }]"
      :placeholder="placeholder"
      @input="updateValue"
    />
    <div v-if="clearButton && modelValue" class="searchbar__clear" @click="clear">
      <BaseIcon
        :data-cy="`${cypressDataAttribute}${dataCy}Close`"
        icon-name="close"
        :size="24"
        alt="close-icon"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.searchbar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: remCalc(480);
  min-height: remCalc(40);
  padding: 0 remCalc(7.5);
  border: 1px solid var(--color-shade-5);
  border-radius: remCalc(4);
  background-color: var(--color-shade-9);
  font-size: var(--text-base);
  margin-right: 8px;

  &:focus,
  &:focus-visible {
    border-color: var(--color-highlight);
    outline: var(--color-highlight);
  }

  &__input {
    @extend %font-input;
    font-family: $font-base;
    width: 100%;
    padding: 0 var(--space-4xs);
    border: none;
    background-color: transparent;
    color: var(--input-font-color);
    cursor: text;
    &:focus {
      outline: none;
    }

    &_clear {
      padding-right: var(--space-sm);
    }

    &::placeholder {
      color: var(--color-grey);
    }
  }

  &__icon {
    max-width: remCalc(25);
    padding: var(--space-6xs);
    fill: var(--color-shade-1);
  }

  &__clear {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;

    top: 0;
    right: 0;

    width: remCalc(24);
    height: 100%;

    cursor: pointer;
    overflow: hidden;
    color: var(--color-shade-1);
  }
}
</style>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { NotificationsItemModel } from '@/types/notification';
import { useGetCompanyStatusQuery } from '@/types/graphql';
import { USER_ROLE_ROUTES } from '@/config/constants/routes';
import { useGlobalStore } from '@/store';
import { toggleNotificationWindow } from '@/utils/composable/topNavigationBar';
import { getNotificationDatestring } from '@/utils/composable/notifications';

export default defineComponent({
  name: 'NotificationsItem',
  components: {},
  props: {
    notificationData: {
      type: Object as PropType<NotificationsItemModel | null>,
      required: true,
    },
    showShortMessage: {
      type: Boolean,
      default: false,
    },
    inDropdown: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['on-link-click'],
  setup(props, ctx) {
    const globalStore = useGlobalStore();
    const notificationTime = ref(getNotificationDatestring(props.notificationData?.occurred_at));

    const linkClick = async () => {
      if (props.notificationData?.type === 'awatingApproval') {
        const { onResult } = useGetCompanyStatusQuery(
          { id: `/api/client/${props.notificationData?.companyId}` },
          {
            fetchPolicy: 'network-only',
          },
        );
        let link = props.notificationData?.link;
        onResult((response) => {
          if (response?.data?.clientCompany?.status === 'approved') {
            link = `${USER_ROLE_ROUTES.CLIENT_COMPANY_PROFILE.path}/${props.notificationData?.companyId}?notification=approved`;
          }

          ctx.emit('on-link-click', {
            id: props.notificationData?.id,
            link,
            noClientView: props.notificationData?.noClientView,
          });
        });
      } else if (props?.notificationData?.chatLink) {
        globalStore.toggleChat(true);
        toggleNotificationWindow();
      } else {
        ctx.emit('on-link-click', {
          id: props.notificationData?.id,
          link: props.notificationData?.link,
          noClientView: props.notificationData?.noClientView,
        });
      }
    };

    return {
      linkClick,
      notificationTime,
    };
  },
});
</script>

<template>
  <div
    :class="{ 'notification-item': true, 'notification-item--dropdown': inDropdown }"
    data-unit-test="notification-item"
  >
    <div class="notification-item__left">
      <div v-if="!notificationData?.isReaded" class="notification-item__mark" />
    </div>
    <div class="notification-item__right">
      <div class="notification-item__title-block">
        <div class="notification-item__title">
          {{ notificationData?.title }}
        </div>
        <div class="notification-item__date">
          {{ notificationTime }}
        </div>
      </div>
      <div class="notification-item__text">
        {{ showShortMessage ? notificationData?.shortMessage : notificationData?.message }}
        <span
          v-if="notificationData?.isLink"
          class="notification-item__text-link"
          @click="linkClick"
        >
          {{ notificationData.linkText }}
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.notification-item {
  display: flex;

  &__left {
    width: 20px;
    min-width: 20px;
    display: flex;
    align-items: center;
  }
  &__mark {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--color-primary);
  }
  &__right {
    width: calc(100% - 20px);
    padding: var(--space-3xs);
  }
  &__title-block {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-5xs);

    .notification-item--dropdown & {
      justify-content: space-between;
    }
  }
  &__title {
    @extend %font-field;
  }
  &__date {
    @extend %font-helper;
    margin-left: var(--space-md);
  }
  &__text {
    @extend %font-form-bar;

    &-link {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { rawTimeZones } from '@vvo/tzdb';
import { getCurrentLocale } from '@/utils/composable/localeHelper';
import { MultiselectOptions } from '@/types/base-types';
import { TOption } from '@/utils/composable/EnergyExchange';

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

export const getFormattedDate = (
  dateString: string | Date,
  showYear = true,
  showTime = false,
): string => {
  if (showYear && showTime) {
    return dayjs(dateString).format('DD/MM/YYYY HH:mm');
  }

  if (!showYear && !showTime) {
    return dayjs(dateString).format('DD/MM');
  }

  if (!showYear && showTime) {
    return dayjs(dateString).format('DD/MM HH:mm');
  }

  return dayjs(dateString).format('DD/MM/YYYY');
};

export const formatChatTimestamp = (date: number | string): string => {
  const thisMoment = dayjs();
  const dateObject = dayjs(date);
  if (!dateObject.isValid()) {
    return '';
  }

  const timeDifferenceInSeconds = thisMoment.diff(dateObject, 'second');
  const oneDayInSeconds = dayjs.duration(1, 'day').as('seconds');

  if (timeDifferenceInSeconds > oneDayInSeconds) {
    return dateObject.format('DD/MM/YYYY HH:mm');
  }
  return dateObject.format('HH:mm');
};

export const formatMonthFromNumber = (monthNumber: number, year?: number) => {
  if (year) {
    const date = dayjs()
      .locale(getCurrentLocale.value)
      .set('year', year)
      .set('month', monthNumber - 1);
    return date.format('MMMM YYYY');
  }

  const date = dayjs().set('month', monthNumber - 1);
  return date.locale(getCurrentLocale.value).format('MMMM');
};

export const getUserTimezone = (): string => {
  const currentTimezone = dayjs.tz.guess();
  return (
    rawTimeZones.find(
      (item) => item.name === currentTimezone || item.group.includes(currentTimezone),
    )?.name || ''
  );
};

export const getTimezonesList = (): MultiselectOptions[] => {
  const list = rawTimeZones.sort((a, b) => {
    if (a.continentName.toLocaleLowerCase() > b.continentName.toLocaleLowerCase()) return 1;
    if (a.continentName.toLocaleLowerCase() === b.continentName.toLocaleLowerCase()) {
      return a.countryName.toLocaleLowerCase() > b.countryName.toLocaleLowerCase() ? 1 : -1;
    }
    return -1;
  });
  return list.map((item) => ({
    label: `${item.mainCities.join(', ')} - ${item.countryName} - ${item.continentName}`,
    value: item.name,
  }));
};

export const getUTCTimezoneDate = (date: string | Date): string => dayjs(date).utc().toISOString();

const addDaysUntillBusinessDay = (startDate: string | Date | dayjs.Dayjs): dayjs.Dayjs => {
  let currentDate = dayjs(startDate);

  // On sunday add 1 day
  if (currentDate.day() === 0) {
    currentDate = currentDate.add(1, 'day');
  }

  // On saturday add 2 days
  if (currentDate.day() === 6) {
    currentDate = currentDate.add(2, 'day');
  }
  return currentDate;
};

export const getPaymentAndDeliveryDate = (
  transactionOption: TOption,
  transactionType: 'buy' | 'sell',
  startDate: string | Date = new Date(),
): { paymentDate: string; deliveryDate: string } => {
  // add additional days from T value
  const additionalDays = Number(transactionOption.substring(1));
  let paymentDate = dayjs(startDate).add(additionalDays, 'days');
  let deliveryDate = paymentDate;

  // Add a day based on transaction type
  if (transactionType === 'buy') {
    deliveryDate = dayjs(deliveryDate).add(1, 'days');
  }

  if (transactionType === 'sell') {
    paymentDate = dayjs(paymentDate).add(1, 'days');
  }

  // Make sure day is not a weekend day
  deliveryDate = addDaysUntillBusinessDay(deliveryDate);
  paymentDate = addDaysUntillBusinessDay(paymentDate);

  // After weekend calculation the date might be the same, add day based on transaction type
  if (deliveryDate.day() === paymentDate.day()) {
    if (transactionType === 'buy') {
      deliveryDate = dayjs(deliveryDate).add(1, 'days');
    }

    if (transactionType === 'sell') {
      paymentDate = dayjs(paymentDate).add(1, 'days');
    }
  }

  return {
    paymentDate: getFormattedDate(paymentDate.toString()),
    deliveryDate: getFormattedDate(deliveryDate.toString()),
  };
};

export const convertToCorrectUtcTimestamp = (time: string) => {
  // This function converts incoming from server UTC time to Amsterdam time and returns as new UTC time
  // It's needed because valueOf (in Dayjs) and getTime (js Date object) functions return value for UTC
  const correctTimezoneTime = dayjs(time).tz('Europe/Amsterdam');
  const timeStemp = dayjs
    .utc(time)
    .set('y', correctTimezoneTime.get('y'))
    .set('M', correctTimezoneTime.get('M'))
    .set('D', correctTimezoneTime.get('D'))
    .set('h', correctTimezoneTime.get('h'))
    .set('m', correctTimezoneTime.get('m'))
    .set('s', correctTimezoneTime.get('s'))
    .set('m', correctTimezoneTime.get('m'));
  return timeStemp.valueOf();
};

{
  "base": {
    "header": {
      "backButton": "Back",
      "actionButton": "Create",
      "changeProduct": "Change Product"
    },
    "buttons": {
      "resetPassword": "Reset password",
      "goToCalendly": "Go to Calendly account",
      "sendResetLink": "Send reset link",
      "forgotPassword": "Forgot password?",
      "login": "Login",
      "signIn": "Sign in",
      "backToLogin": "Back to Login",
      "logout": "Log out",
      "verify": "Verify",
      "createNewEvent": "Create Event",
      "createClient": "Create Client",
      "cancel": "Cancel",
      "addRepresentative": "Add Representative",
      "deleteThisRepresentative": "Delete this Representative",
      "decline": "Decline",
      "delete": "Delete",
      "upload": "Upload",
      "publish": "Publish",
      "select": "Select",
      "selectAll": "Select all",
      "DeSelectAll": "Deselect all",
      "chatFiles": "Chat files",
      "create": "Create",
      "button": "Button",
      "addSA": "+ Link Sustainability Advisor",
      "submit": "Submit",
      "edit": "Edit",
      "deactivate": "Deactivate",
      "reactivate": "Reactivate",
      "save": "Save",
      "sendInvite": "Send invite",
      "resendInvite": "Resend invite",
      "createBSupp": "Create Business Support",
      "enable": "Enable",
      "ok": "OK",
      "changeClient": "Change Client",
      "changePassword": "Change Password",
      "reject": "Reject",
      "approve": "Approve",
      "submitForApproval": "Submit for Approval",
      "next": "Next",
      "onboard": "Onboard",
      "openForm": "Open full form",
      "hideForm": "Hide full form",
      "allDocuments": "Go to all documents",
      "addCategory": "Add category",
      "download": "Download",
      "publishBlog": "Publish post",
      "saveBlog": "Save & preview",
      "approvalBlog": "Send for approval",
      "saveChanges": "Save changes",
      "viewRecycleBin": "View recycle bin",
      "restore": "Restore",
      "viewRequest": "View request",
      "viewDocument": "View document",
      "approvalWebinar": "Send for approval",
      "search": "Search",
      "exportButtonText": "Export to xlsx",
      "changeMfa": "Change MFA",
      "dispatch": "Dispatch",
      "viewMore": "View more",
      "scheduleMeeting": "Schedule a meeting",
      "viewAllDocuments": "View all documents",
      "createEvent": "Create an event",
      "gotoCalendly": "Go to Calendly",
      "createAReminder": " + Create an event",
      "changeAgain": "Change again",
      "newPassword": "Create new password",
      "confirm": "Confirm",
      "setUpLater": "Set-up later",
      "attachImage": "Attach image",
      "preview": "Preview",
      "reset": "Reset",
      "resetMFA": "Reset MFA",
      "copy": "Copy",
      "markSettled": "Mark as settled",
      "markUnsettled": "Mark as unsettled"
    },
    "viewSwitcher": {
      "client": "Client",
      "personal": "Personal",
      "clientAbbr": "C",
      "personalAbbr": "P"
    },
    "clientsList": {
      "title": "Clients",
      "helperText": "Choose Client to start working with the Volumes",
      "viewAll": "Go to the  module",
      "noClients": "No matching Client Companies found.",
      "noClientsForNavBar": "You do not have {productName} Clients.",
      "noClientsResultsProductName": "You do not have {productName} Clients with the Volumes module.",
      "noClientsResults": "Please contact Support if you have any questions."
    },
    "backButtons": {
      "dashboardDefaultView": "Back to Dashboard",
      "dashboard": "Back to Dashboard",
      "clients": "Back to All Clients",
      "clientsCompanyProfile": "Back to Client",
      "blog": "Back to Feed",
      "doclib": "Back to the Document Library",
      "userCompany": "Back to Client overview",
      "rfq": "Back to RFQ submission",
      "rfqHistoryPage": "Back to RFQ history",
      "sAdvisors": "Back to All Sustainability Advisors",
      "bSupps": "Back to All Business Supports",
      "Webinars": "Back to Feed"
    },
    "inputs": {
      "email": {
        "label": "Email",
        "placeholder": "",
        "help": "",
        "sameEmails": "You cannot create Representatives with the same email"
      },
      "calendlyId": {
        "label": "Calendly ID",
        "placeholder": "Please, enter the Calendly ID",
        "help": ""
      },
      "bio": {
        "label1": "About me",
        "label2": "Bio",
        "placeholder": "Enter a short  bio",
        "help": ""
      },
      "oldPassword": {
        "label": "Old Password",
        "placeholder": "",
        "help": "Provide your current password"
      },
      "password": {
        "label": "Password",
        "placeholder": "",
        "help": "Password should have at least 8 symbols"
      },
      "confirmPassword": {
        "label": "Confirm Password",
        "placeholder": "",
        "help": "Must be 8 or more character and contain at least 1 number and 1 capitalized character.",
        "helpComplexity": "Password must contain at least 1 number, and 1 capitalized character.",
        "helpLength": "Minimum length is 8 characters.",
        "helpLogout": "After changing your password, you will have to log in again on all your devices."
      },
      "twoFACode": {
        "label": "Code",
        "placeholder": "",
        "help": "Enter code from the two-factor app on your mobile device. Please contact support{'@'}afsenergy.nl if you're having a problem with your log-in. "
      },
      "zohoAccountsID": {
        "label": "Choose Zoho ID",
        "placeholder": "Search a client",
        "help": "e.g. \t117729000014332362"
      },
      "zohoAccountsName": {
        "label": "Zoho Account Name",
        "placeholder": "-",
        "help": ""
      },
      "companyName": {
        "label": "Client Name",
        "placeholder": "",
        "help": ""
      },
      "chooseProducts": {
        "label": "Products",
        "labelCR": "Products covered",
        "placeholder": "Choose products from list",
        "help": ""
      },
      "companySearch": {
        "label": "Client",
        "placeholder": "Type to search",
        "help": ""
      },
      "company": {
        "label": "Client",
        "placeholder": "",
        "help": ""
      },
      "linkedProducts": {
        "label": "Linked products",
        "placeholder": "",
        "help": ""
      },
      "clientsLinked": {
        "label": "Clients linked Per Product",
        "placeholder": "Choose client from list",
        "help": ""
      },
      "firstName": {
        "label": "First name",
        "placeholder": "",
        "help": ""
      },
      "middleName": {
        "label": "Middle name",
        "placeholder": "",
        "help": ""
      },
      "lastName": {
        "label": "Last name",
        "placeholder": "",
        "help": ""
      },
      "phone": {
        "label": "Phone number",
        "placeholder": "xxx-xxx-xxxx",
        "help": ""
      },
      "subEntityName": {
        "label": "Client name",
        "placeholder": "",
        "help": ""
      },
      "legalFullName": {
        "label": "Legal Representative Full Name",
        "placeholder": "",
        "help": ""
      },
      "street": {
        "label": "Street",
        "placeholder": "",
        "help": ""
      },
      "address": {
        "label": "Address",
        "placeholder": "",
        "help": ""
      },
      "postalCode": {
        "label": "Postal code",
        "placeholder": "",
        "help": ""
      },
      "country": {
        "label": "Country",
        "placeholder": "",
        "help": ""
      },
      "SA": {
        "label": "Sustainability Advisor",
        "placeholder": "",
        "help": ""
      },
      "city": {
        "label": "City",
        "placeholder": "",
        "help": ""
      },
      "chamberOfCommerce": {
        "label": "Chamber of Commerce number",
        "placeholder": "",
        "help": ""
      },
      "VAT": {
        "label": "VAT number",
        "placeholder": "",
        "help": ""
      },
      "LEI": {
        "label": "Legal Entity Identifier",
        "placeholder": "",
        "help": ""
      },
      "productAccess": {
        "label": "Product Access",
        "placeholder": "",
        "help": ""
      },
      "productAccessClient": {
        "label": "Products",
        "labelOne": "Product",
        "placeholder": "Select Product",
        "help": "",
        "error": "Value is required"
      },
      "legalRepresentativeName": {
        "label": "Legal Representative Full Name",
        "placeholder": "",
        "help": ""
      },
      "legalRepresentativeEmail": {
        "label": "Legal Representative E-mail Address",
        "placeholder": "",
        "help": ""
      },
      "legalAddress": {
        "label": "Legal Address",
        "placeholder": "",
        "help": ""
      },
      "businessIdentifiers": {
        "label": "Business Identifiers",
        "placeholder": "",
        "help": ""
      },
      "office": {
        "label": "Office, floor, etc",
        "placeholder": "",
        "help": ""
      },
      "servicePerProduct": {
        "label": "Services provided per Product",
        "tableHead": "Service"
      },
      "DocumentTitle": {
        "label": "Title",
        "placeholder": "",
        "errorMessagesOne": "Upload failed. Please,",
        "errorMessagesTwo": "upload another file",
        "errorMessagesThree": "or drag and drop a new file here.",
        "updateDoc": "Please note that Product-specific documents are published only after Product Manager’s review.",
        "maxLengthErrorMessage": "The title should not be longer than 64 characters",
        "errorSize": "The uploaded file is bigger than {size} MB. Please,"
      },
      "Category": {
        "label": "Category",
        "placeholder": ""
      },
      "Client": {
        "label": "Client",
        "placeholder": "",
        "emptyState": "There are no Clients assigned to the selected product. Please, choose another product."
      },
      "AddCategory": {
        "placeholder": "Enter section name",
        "emptyState": "Please, enter Category name",
        "notUniqueName": "Please, use unique Category name",
        "checkbox": "Add  folder to all Products"
      },
      "RejectionReason": {
        "label": "Reason of rejection",
        "placeholder": "Please, specify your rejection reason."
      },
      "Shared": {
        "label": "Shared with",
        "placeholder": "",
        "tooltip": "Please, choose the Product first",
        "placeholderCreate": "Choose Clients to share",
        "placeholderSharedWithEveryone": "Shared with everyone",
        "checkboxLabel": "Share with everyone",
        "error": "Please, choose at least one Client or check the Shared with everyone checkbox"
      },
      "EventTitle": {
        "label": "Event title",
        "placeholder": "Event title"
      },
      "EventDescription": {
        "label": "Event description",
        "placeholder": "Event description"
      },
      "DateAndRime": {
        "label": "Date and time",
        "placeholder": ""
      },
      "Note": {
        "label": "Note",
        "placeholder": ""
      },
      "modulePerProduct": {
        "label": "Product Modules",
        "tableHead": "Module"
      },
      "DefaultDashboard": {
        "label": "Default dashboard"
      },
      "SAPerProduct": {
        "label": "Sustainability Advisors per Product"
      },
      "fullName": {
        "label": "Full Name"
      },
      "emptyState": {
        "label": "No results found"
      },
      "shareAllDocuments": {
        "label": "Share existing product-related documents?"
      },
      "isPrimarySubEntity": {
        "label": "Primary Sub-Entity"
      },
      "isProductManager": {
        "label": "Is Product Manager?"
      },
      "profilePhoto": {
        "label": "Profile Photo",
        "helper": "Allowed extensions - jpg, png, svg, png, gif. Maximum file size - 0.5 MB."
      },
      "termsConditions": {
        "label": "I agree to",
        "linkText": "AFS Client Portal Terms and Conditions"
      },
      "timezone": {
        "label": "Preferred Timezone"
      },
      "errors": {
        "requiredErrorMessage": "This field is required",
        "dontMatchErrorMessage": "Passwords do not match",
        "emailErrorMessage": "Enter valid email address",
        "modulesRequired": "Please, choose at least one Module per each linked Product",
        "advisorsRequired": "Please, assign at least one Advisor per each linked Product",
        "dashboardRequired": "Please, assign at least one Default Dashboard per each linked Product",
        "termsConditions": "You must agree to the terms and conditions of the Client Portal to be onboarded.",
        "onlyInteger": "Please, provide the whole number",
        "multipleOf1000ErrorMessage": "Only multiples of 1000 allowed",
        "limitPriceErrorMessage": "The limit price is below the current market value",
        "limitPriceErrorMessageAbove": "The limit price is below the current market value",
        "limitPriceErrorMessageBelow": "The limit price is above the current market value",
        "maxDateErrorMessage": "Limit orders can be max 90 days",
        "minDateErrorMessage": "Date has to be in the future",
        "maxValueErrorMessage": "Max value is {max}",
        "deliveryDateErrorMessage": "Delivery date has to be after deal date"
      }
    },
    "modals": {
      "addCompanyRepresentative": "Add Client Representative",
      "createNewClient": "Create new client",
      "enableTFA": "Enable Two-Factor Authentication",
      "enableTFADescription": "Please, use your authentication app (Duo, Authy, Google Authenticator) to scan this QR code. Then, please enter the code that will appear on your screen.",
      "changePassword": "Change Password",
      "createCompanyRepresentative": "Request Client Representative",
      "createCompanyRepresentativeSubtitle": "A representative of a Client that gets a client portal account and is responsible for one or multiple products",
      "PublishPost": "Publish post",
      "RejectPost": "Reject post",
      "DeletePost": "Delete post",
      "deletePostDescription": "Are you sure? The deleted post will be removed from the Market Insights overview.",
      "revokeGoogleAccess": {
        "title": "Revoke Access",
        "text": "Are you sure? Your Clients won’t see shared meetings in Calendar. You can synchronize your Calendar again in the Calendar page.",
        "buttons": {
          "decline": "Decline",
          "revoke": "Revoke"
        }
      },
      "createBSupp": {
        "title": "Create Business Support",
        "description": "A support role for the AFS Energy Department that helps with reporting. In the Client Portal, this is a general platform admin role that oversees Sustainability Advisor activity and is an independent arbiter"
      },
      "ClientsSpecific": {
        "title": "Upload document"
      },
      "RejectDocument": {
        "title": "Reject document"
      },
      "Edit": {
        "title": "Edit document"
      },
      "PublishDocument": {
        "title": "Publish document"
      },
      "DeletedFile": {
        "title": "Delete file"
      },
      "DeleteCategory": {
        "title": "Delete category"
      },
      "ApproveDocument": {
        "title": "Approve document"
      },
      "UploadDocument": {
        "title": "Upload document"
      },
      "ViewRequest": {
        "title": "View request"
      },
      "scheduleMeeting": {
        "title": "Schedule a meeting",
        "tip": "Tip: Specify Client Representative’s name and email in the respective fields. You can add additional guests in the “Guest” field during the appointment confirmation.",
        "noAccount": "There is no linked Calendly account.\nPlease, reach out to Support to create Calendly account or\n link already existing account with AFS Client Portal."
      },
      "scheduleAppointment": {
        "title": "Schedule an appointment",
        "tip1": "Tip: You can add guests in the “Guest” field during the appointment confirmation.",
        "tip2": "Tip: You can add guests in the “Guest” field during the appointment confirmation.",
        "step1": "Step 1. Choose  Product",
        "step2": "Step 2. Set up a meeting",
        "step2hasOneProduct": "Step 2. Set up a meeting",
        "step2oneProduct": "Step 1. Choose  Sustainability Advisor",
        "error": "This Sustainability Advisor is currently unavailable. Please contact another Sustainability Advisor of your choice"
      },
      "createReminder": {
        "modalTitle": "Create an event",
        "modalTitleUpdated": "Edit an event",
        "modalTitleDelete": "Delete event"
      },
      "addSAdvisor": {
        "title": "Add Sustainability Advisor",
        "isProductManger": "Is this account a Manager for this product?"
      },
      "profilePhotoTitle": "Resize and position profile photo",
      "docShareEmpty": "This document cannot be shared yet as there are no active Clients with the Document Library module for this product.",
      "resetMFA": {
        "title": "Reset multi-factor authentication",
        "message1": "Are you sure?",
        "message2": "Multi-factor authentication will be disabled for the following user",
        "successMessage": "The MFA has been reset successfully"
      },
      "toggleActivationModal": {
        "defaultMessage": "Are you sure?",
        "user": {
          "deactivateTitle": "Deactivate user",
          "reactivateTitle": "Reactivate user",
          "deactivateContent": "The deactivated user won't be able to log in the system.",
          "reactivateContent": "The reactivated user can log in to the system with their existing credentials.",
          "deactivateSuccess": "{role} has been deactivated successfully",
          "reactivateSuccess": "{role} has been reactivated successfully"
        },
        "company": {
          "deactivateTitle": "Deactivate company",
          "reactivateTitle": "Reactivate company",
          "deactivateContent": "Client company will be visible only to Business Support.",
          "reactivateContent": "Are you sure? The Company will be visible to Advisors. All Company Representatives for this Company will be reactivated",
          "deactivateSuccess": "Client Company has been deactivated successfully",
          "reactivateSuccess": "Client Company and its linked Company Representatives have been reactivated successfully"
        }
      },
      "apiKeyModal": {
        "title": "API key generated",
        "message": "Your API key was generated successfuly! Please note that the key is shown once, please ensure to copy key and store it securely.",
        "apiKeyLabel": "API key",
        "copyMessage": "Your API key was copied to clipboard!"
      }
    },
    "table": {
      "filterMessages": {
        "noClients": "There are no Clients yet. Be the first to create a Client.",
        "noClientsOfFilter": "There are no Client Representatives with this Product. Please, choose another Product or clear filtering",
        "noClientsSearch": "There are no Clients matching your search request. Please try another search.",
        "noClientsSearchTeam": "There are no product-related Clients matching your search request. Please try another search or check your personal Clients.",
        "noClientsSearchPersonal": "There are no personal Clients matching your search request. Please try another search or check your product-related Clients.",
        "noTeamClientsSearch": "There are no product-related Clients matching your search request. Please try another search or check your personal Clients.",
        "searchPlaceholder": "Search by name",
        "noClientsSA": "There are no assigned Clients yet.",
        "noClientsWithProduct": "There are no Clients with this Product. Please, choose another Product or clear filtering",
        "noPendingApprovalRepresentatives": "There are no Client Representatives pending approval with this product. Please, choose another product or clear filtering.",
        "noPendingInviteRepresentatives": "There are no Client Representatives awaiting email invite.",
        "noPendingInviteRepresentativesSearch": "There are no Client Representatives pending an invite with this product. Please, choose another product or clear filtering.",
        "noRepresentatives": "There are no Client Representatives yet.",
        "noRepresentativesWithProduct": "There are no Client Representatives with this Product. Please, choose another Product or clear filtering",
        "noRepresentativesSearch": "There are no Client Representatives matching your search request. Please try another search.",
        "noSAdvisors": "There are no Sustainability Advisors yet. Be the first to create an Advisor.",
        "noSAdvisorsSearch": "There are no Sustainability Advisors matching your search request. Please try another search.",
        "noSAdvisorsLinked": "There are no Sustainability Advisors linked to this Product. Please, choose another Product or clear filtering",
        "noBSupports": "There are no Business Supports yet",
        "noBSupportsSearch": "There are no Business Supports matching your search request. Please try another search."
      },
      "emptyState": {
        "emptyCompanyRepresentativesMessage": "There are no client representatives for the selected client yet.",
        "emptySubEntityMessage": "There are no sub-entities for the selected client yet."
      },
      "sAdvisorTableCells": {
        "fullName": "Full name",
        "productsCovered": "Products covered",
        "clients": "Clients",
        "status": "Status",
        "actions": "Actions",
        "notImplemented": "not implemented yet",
        "resendInvite": "Resend invite"
      },
      "clientTableCells": {
        "companyName": "Client name",
        "productsCovered": "Products covered",
        "clients": "Clients",
        "status": "Status",
        "linkedSAdvisors": "Linked Sustainability Advisors",
        "subEntities": "Sub-entities",
        "companyRepresentatives": "Client Representatives",
        "actions": "Actions",
        "prodRelated": "Product-related clients",
        "direct": "Direct"
      },
      "servicesTable": {
        "service": "Service",
        "All Modules": "All Modules",
        "tradingBrokerage": "Trading/Brokerage",
        "advisoryAccountManagement": "Advisory/Account Management",
        "Document Library": "Document Library",
        "Blog": "Market Insights",
        "Newsletter": "Newsletter",
        "Webinars": "Webinars",
        "Chat": "Chat",
        "Price charts": "Price charts",
        "Calendar": "Calendar",
        "Dashboard": "Dashboard",
        "RFQ": "RFQ",
        "Glossary": "Glossary",
        "Feedback Form": "Feedback Form",
        "Carbon Footprint Calculator Tool": "Carbon Footprint Calculator Tool",
        "PPA tool": "PPA tool",
        "Share documents": "Share documents",
        "productManager": "Product Manager",
        "User Management": "User Management",
        "Trades": "Trades",
        "Client Documents": "Client Documents",
        "shareDocuments": "Display existing files",
        "My documents": "My documents",
        "Commodity Volumes": "Commodity Volumes",
        "editNotAllowedTooltip": "You cannot edit modules for this Product as you are not linked to this Product for this Client",
        "Energy Exchange": "EUA Trading"
      },
      "usersPerProductTable": {
        "sustainabilityAddText": "+ Add SA"
      },
      "defaultDashboard": {
        "product": "Product",
        "defaultDashboardGroup": "Default dashboard group",
        "selectModulesFirst": "Select modules first"
      },
      "documentsPendingReview": {
        "title": "File title",
        "uploadedBy": "Uploaded by",
        "client": "Client",
        "category": "Category",
        "actions": "Actions",
        "note": "Note",
        "productRelatedClients": "Product-related Clients",
        "product": "Product",
        "notFoundText": "There are no documents yet"
      },
      "transactions": {
        "tradeNr": "Trade Nr.",
        "tradeNumber": "Trade Number",
        "tradeType": "Trade Type",
        "deliveryStatus": "Delivery status",
        "commodity": "Commodity",
        "technology": "Technology",
        "country": "Country",
        "period": "Period",
        "volume": "Volume",
        "tradeDate": "Trade Date",
        "sellValue": "Sell",
        "buyValue": "Buy",
        "company": "Client",
        "statuses": {
          "WAITING_FOR_DELIVERY": "Waiting for delivery",
          "DELIVERED": "Delivered",
          "PARTIALLY_DELIVERED": "Delivered ({delivered}/{totalCount})"
        }
      },
      "approvedCRs": {
        "actions": "Actions",
        "name": "Full name",
        "client": "Client",
        "sAdvisors": "Sustainability Advisor",
        "tableName": "Client Representatives",
        "products": "Products"
      },
      "businessSupports": {
        "fullName": "Full name",
        "contacts": "Contact information",
        "status": "Status",
        "resendInvite": "Resend invite",
        "actions": "Actions"
      },
      "linkedToThisCompany": {
        "name": "Full name",
        "product": "Product",
        "subEntity": "Sub-entity",
        "zohoAccountName": "zoho Account Name",
        "actions": "Actions",
        "status": "Status"
      }
    },
    "timezones": {
      "0": "Western Europe Time, London, Lisbon, Casablanca (GMT)",
      "-12:00": "Eniwetok, Kwajalein (GMT -12:00)",
      "-11:00": "Midway Island, Samoa (GMT -11:00)",
      "-10:00": "Hawaii (GMT -10:00)",
      "-9:30": "Taiohae (GMT -9:30)",
      "-9:00": "Alaska (GMT -9:00)",
      "-8:00": "Pacific Time (US & Canada) (GMT -8:00)",
      "-7:00": "Mountain Time (US & Canada) (GMT -7:00)",
      "-6:00": "Central Time (US & Canada), Mexico City (GMT -6:00)",
      "-5:00": "Eastern Time (US & Canada), Bogota, Lima (GMT -5:00)",
      "-4:00": "Atlantic Time (Canada), Caracas, La Paz (GMT -4:00)",
      "-3:30": "Newfoundland (GMT -3:30)",
      "-3:00": "Brazil, Buenos Aires, Georgetown (GMT -3:00)",
      "-2:00": "Mid-Atlantic (GMT -2:00)",
      "-1:00": "Azores, Cape Verde Islands (GMT -1:00)",
      "+1:00": "Amsterdam, Brussels, Madrid, Paris (GMT +1:00)",
      "+2:00": "Athens, Helsinki, Kyiv, Sofia (GMT +2:00)",
      "+3:00": "Baghdad, Riyadh, Moscow, St. Petersburg (GMT +3:00)",
      "+3:30": "Tehran (GMT +3:30)",
      "+4:00": "Abu Dhabi, Muscat, Baku, Tbilisi (GMT +4:00)",
      "+4:30": "Kabul (GMT +4:30)",
      "+5:00": "Ekaterinburg, Islamabad, Karachi, Tashkent (GMT +5:00)",
      "+5:30": "Bombay, Calcutta, Madras, New Delhi (GMT +5:30)",
      "+5:45": "Kathmandu, Pokhara (GMT +5:45)",
      "+6:00": "Almaty, Dhaka, Colombo (GMT +6:00)",
      "+6:30": "Yangon, Mandalay (GMT +6:30)",
      "+7:00": "Bangkok, Hanoi, Jakarta (GMT +7:00)",
      "+8:00": "Beijing, Perth, Singapore, Hong Kong (GMT +8:00)",
      "+8:45": "Eucla (GMT 8:45)",
      "+9:00": "Tokyo, Seoul, Osaka, Sapporo, Yakutsk (GMT +9:00)",
      "+9:30": "Adelaide, Darwin (GMT +9:30)",
      "+10:00": "Eastern Australia, Guam, Vladivostok (GMT +10:00)",
      "+10:30": "Lord Howe Island (GMT +10:30)",
      "+11:00": "Magadan, Solomon Islands, New Caledonia (GMT +11:00)",
      "+11:30": "Norfolk Island (GMT +11:30)",
      "+12:00": "Auckland, Wellington, Fiji, Kamchatka (GMT +12:00)",
      "+13:00": "Apia, Nukualofa (GMT +13:00)",
      "+13:45": "Chatham Islands (GMT +13:45)",
      "+14:00": "Line Islands, Tokelau (GMT +14:00)"
    },
    "stateMessages": {
      "companyWasCreated": "New Client has been created",
      "BSuppInviteWasSent": "Invite was sent succesfully",
      "consultantWasAdded": "Consultant was added successfully",
      "companyRepresentativeWasAdded": "New Client Representative has been created",
      "companyRepresentativeWasApproved": "Client Representative was approved successfully",
      "rejectedDocument": "The document was rejected",
      "approvedDocument": "Document was published successfully",
      "bSuppWasAdded": "Business Support was added successfully",
      "hasInvited": "has been invited",
      "busSuppWasAdded": "New Business Support has been created",
      "SubEntityAdded": "New Sub-entity has been created",
      "crErrorMessagesOne": "It looks like information about Representative #{index} is invalid or you've missed a field. Please provide the necessary details to proceed",
      "crErrorMessagesTwo": "It looks like information about Representatives is invalid or you've missed a field. Please provide the necessary details to proceed.",
      "passwordWasChanged": "Password has been successfully changed",
      "passwordChanged": "Password has been successfully changed. Please login with your new password.",
      "sAdvisorWasAdded": "New Sustainability Advisor has been created",
      "uploadFileCompanyRepresentative": "File uploaded successfully! Sustainability Advisor will review your file shortly",
      "errorMessageNetwork": "Upload failed. Please, upload another file or drag and drop a new file here.",
      "uploadFileDoc": "File uploaded successfully",
      "uploadFileDocForBS": "The document was published successfully!",
      "myDocumentUpload": "The document has been uploaded successfully!",
      "successfully": "Document updated successfully",
      "errorMessages": "Invalid credentials. Please try again",
      "managersReview": "Document was sent for Manager's review",
      "twoFAWasEnabled": "Two-factor authentication was enabled successfully.",
      "postPublishSucessfull": "The post was published successfully",
      "postUpdatedSucessfull": "The post was updated successfully",
      "postSaveDraft": "The post was saved to Drafts successfully",
      "postDeleted": "The post was deleted successfully",
      "postSendForReview": "The post was sent to Manager for review",
      "newsletterPublishSuccessful": "Newsletter was published successfully",
      "newsletterUpdatedSuccessful": "Newsletter was published successfully",
      "newsletterSendForReview": "Newsletter was sent for Business Support approval",
      "newsletterSaveDraft": "Newsletter was saved to Drafts successfully",
      "newsletterDenied": "Newsletter was denied successfully",
      "newsletterDeleted": "The post was deleted successfully",
      "postDenied": "The post was denied successfully",
      "webinarPublished": "Webinar has been published successfully",
      "webinarRejected": "The webinar has been rejected and deleted",
      "webinarDeleted": "The webinar has been deleted successfully!",
      "chooseClient": "Please, choose Client first",
      "addCategorySuccess": "Category has been added successfully",
      "maxLengthError": "Should be less than 45 characters",
      "updateSuccessful": "Information was changed successfully!",
      "documentUpdatedSuccessfully": "Information about the document was updated successfully!",
      "publishedSuccessfully": "Document was published successfully!",
      "maxLengthErrorRejectReason": "The rejection reason should not be longer than 512 characters",
      "deletedTextForProduct": "This document was rejected! Document can be restored by Support within 30 days after rejection",
      "missingFieldsError": "It seems that a field was missed or invalid information was entered. Please provide the necessary details to proceed.",
      "mfaError": "There was a problem changing your MFA. Please, try again.",
      "mfaChanged": "Your multi-factor authentication method has been changed successfully",
      "changePasswordError": "There was a problem changing your password. Please, try again.",
      "changePasswordSuccess": "Your password has been changed successfully"
    },
    "validationMessages": {
      "phoneNotMatch": "Phone number is incorrect",
      "imagesRules": "Please, upload file with JPG, PNG, SVG, BMP,  or GIF extension. Maximum file size - {size} MB.",
      "filesRules": "Maximum file size - {size} MB."
    },
    "errorMessages": {
      "invalidCredentials": "Invalid credentials. Please try again",
      "inactiveUser": "The system shows the message We noticed some irregularities with your account. Please, contact your Sustainability Advisor or email support{'@'}afsenergy.nl to restore access to your account.",
      "invalidToken": "Your session has expired. Please, log in again",
      "defaultApiErrorMessage": "Something went wrong. Please try again later",
      "expiredClientInvite": "Your invitation link has expired. Please contact your Sustainability Advisor to get the valid invitation link",
      "expiredInvite": "Your invitation link has expired. Please contact Business Support to get the valid invitation link",
      "invalidMfaCode": "Looks like you've entered an invalid code. Please try again or skip multi-factor authentication",
      "expiredTokenHeader": "Session expired",
      "expiredToken": "Your session has expired. Please log in again to continue using this tool."
    },
    "alerts": {
      "compliancePeriod": {
        "message": "The compliance period for 2023 concludes on {endDate}. To avoid potential compliance-related penalties, please consider this deadline when placing your order. Under no circumstances can AFS be held responsible for fines resulting from late deliveries by AFS.",
        "startDate": "11 September 2024",
        "endDate": "30 September 2024"
      }
    }
  },
  "pages": {
    "blog": {
      "routeTitle": "Market Insights",
      "editNewsLetterTitle": "Edit newsletter",
      "contentTypeTitle": "What is the content type?",
      "contentTypeBlog": "Market insight",
      "contentTypeNewsletter": "Newsletter",
      "backButton": "Back to Feed",
      "notPublished": "Not published yet",
      "readMore": "Read more...",
      "informationType": "Information Type",
      "informationTypeBlog": "Market Insight",
      "informationTypeNews": "Newsletters",
      "publishPost": "Publish post",
      "editPost": "Edit post",
      "myPostsPendingPublication": "My pending posts",
      "postsPendingPublication": "Pending Posts ",
      "postsDrafts": "Drafts",
      "postsLatest": "Latest",
      "publicationDate": "Publication Date",
      "latestPosts": "Related posts",
      "groupsTitle": "Groups",
      "searchBlogs": "Search Market Insights",
      "dashboard": {
        "titleForCr": "Market Insights",
        "titleForSAandBS": "Market Insights",
        "viewAllBlogPosts": "View all",
        "continueReading": "Continue reading...",
        "notFoundMessageSA": "There are no posts for this product yet.",
        "notFoundMessage1": "Market insights are coming soon. Stay tuned for content updates.",
        "notFoundMessage2": "In the meantime, feel free to contact your Sustainability Advisor for any support."
      },
      "noPost": "Couldn't find this post",
      "validationMessages": {
        "titleMaxLengthErrorMessage": "The title should not be longer than 90 characters",
        "descriptionMaxLengthErrorMessage": "The description should not be longer than 50 characters",
        "bodyMinLength": "The post body should not be less than 10 characters"
      },
      "emptyState": {
        "titleBlog": "No market insights yet",
        "titleBlogCR": "Market insights are coming soon",
        "messageBlogCR": "Stay tuned for exciting content updates. In the meantime, feel free to contact your Sustainability Advisor for any support.",
        "messageBlog": "There are no posts for the selected products yet.",
        "titleNewsletters": "No newsletters yet",
        "titleNewslettersCR": "Newsletters are coming soon",
        "messageNewslettersCR1": "Keep in touch with recent AFS Client Portal updates!",
        "messageNewslettersCR2": "We'll notify you about recent news from our app",
        "noSharedClients": "This post cannot be shared yet as there are no active Clients with the Market Insights module for this product.",
        "titleBlogNoDrafts": "No draft market insights yet",
        "paragraphBlogNoDrafts": "There are no draft posts for the selected products yet.",
        "titleBlogCRNoSearchResult": "No market insights found",
        "titleBlogNoSearchResult": "No market insights found",
        "titleBlogNoDraftNoSearchResults": "No draft market insights found",
        "noSharedClientsNewsletter": "No clients available to share with"
      },
      "noContent": {
        "title": "Looks like the Market Insights you’re looking for does not exist.",
        "message1": "Please, ensure you’re using the valid link or choose post from the",
        "message2CR": "Feel free to contact your Sustainability Advisor for any support.",
        "message2": "Please contact Support if you think this page was moved or deleted mistakenly.",
        "linkText": "Market insights feed."
      }
    },
    "newsletter": {
      "routeTitle": "Newsletter",
      "createNewsletterTitle": "Create Newsletter",
      "newsletterTitle": "Post title",
      "newsletterTitleDefault": "Enter title",
      "newsletterCover": "Post cover",
      "newsletterCoverRequiredMessage": "Cover is required",
      "newsletterDescription": "Post description",
      "newsletterDescriptionDefault": "Enter short description",
      "newsletterBody": "Post body",
      "newsletterProducts": "Products",
      "createNewsletter": "Create Newsletter",
      "noSharedClients": "This newsletter cannot be shared yet as there are no active Clients with the newsletter module for this product.",
      "editBlogPostTitle": "Edit Post",
      "editNewsLetterTitle": "Edit newsletter",
      "createPost": "Create post",
      "backButton": "Back to Feed",
      "notPublished": "Not published yet",
      "readMore": "Read more...",
      "informationType": "Information Type",
      "informationTypeBlog": "Market insights content",
      "informationTypeNews": "Newsletters",
      "publishPost": "Publish post",
      "editPost": "Edit post",
      "myPostsPendingPublication": "My pending posts",
      "postsPendingPublication": "Pending posts ",
      "postsDrafts": "Drafts",
      "postsLatest": "Latest",
      "latestPosts": "Related posts",
      "groupsTitle": "Groups",
      "dashboard": {
        "titleForCr": "Newsletters",
        "titleForSAandBS": "Newsletters",
        "viewAll": "View all",
        "continueReading": "Continue reading...",
        "notFoundMessageSA": "There are no newsletters yet.",
        "notFoundMessage1": "Keep in touch with recent AFS Client Portal updates!",
        "notFoundMessage2": "We'll notify you about recent news from our app."
      },
      "noContent": {
        "title": "Looks like the news you’re looking for does not exist.",
        "message1": "Please, ensure you’re using the valid link or choose news from the",
        "message2CR": "Feel free to contact your Sustainability Advisor for any support.",
        "message2": "Please contact Support if you think this page was moved or deleted mistakenly.",
        "linkText": "News feed."
      },
      "emptyState": {
        "titleNewsletters": "No newsletters yet",
        "titleNewslettersCR": "Newsletters are coming soon",
        "messageNewslettersCR1": "Keep in touch with recent AFS Client Portal updates!",
        "messageNewslettersCR2": "We'll notify you about recent news from our app"
      }
    },
    "webinars": {
      "routeTitle": "Webinars",
      "createWebinarTitle": "Add Webinar",
      "editWebinarTitle": "Edit Webinar",
      "watchWebinar": "Watch webinar",
      "myPendingWebinars": "My webinars pending publication",
      "pendingPublicationWebinars": "Pending publication",
      "latestWebinars": "Latest",
      "latestWebinarsCR": "Discover latest webinars from AFS Energy",
      "groupsTitle": "Groups",
      "groupPending": "Pending webinars",
      "groupMyPending": "My pending webinars",
      "groupLatest": "Latest",
      "readMore": "Read more...",
      "recentWebinarsTitle": "Latest webinars",
      "addWebinar": "Add webinar",
      "publicationDate": "Publication Date",
      "editWebinar": "Edit webinar",
      "publishWebinar": "Publish webinar",
      "notFoundTitle": "No webinars for this product yet",
      "notFoundMessage": "Thanks for your interest to the Webinar module! New Webinars will appear soon.",
      "searchWebinars": "Search webinars",
      "form": {
        "title": {
          "label": "Webinar title",
          "placeholder": "Add title",
          "maxLengthErrorMessage": "The title should not be longer than 90 characters"
        },
        "linkToWebinar": {
          "label": "Link to Webinar",
          "placeholder": "Enter link",
          "incorrectLinkMessage": "Invalid URL format. Please, use the valid link"
        },
        "cover": {
          "label": "Cover",
          "buttonText": "Attach image",
          "requiredMessage": "Webinar cover is required"
        },
        "description": {
          "label": "Webinar description",
          "placeholder": "Enter short description",
          "maxLengthErrorMessage": "The description should not be longer than 50 characters"
        },
        "rejectionReason": {
          "maxLengthErrorMessage": "The reject reason should not be longer than 280 characters"
        },
        "requiredErrorMessage": "This field is required"
      },
      "dashboard": {
        "titleForCr": "Webinars",
        "titleForSAandBS": "Webinars",
        "viewAllWebinars": "View all",
        "watchWebinar": "Watch webinar...",
        "notFoundMessageSA": "There are no webinars yet.",
        "notFoundMessage1": "Insightful webinars are coming soon. Stay tuned for content updates.",
        "notFoundMessage2": "In the meantime, feel free to contact your Sustainability Advisor for any support."
      },
      "publishMessage": "The webinar was published successfully",
      "sentForApprovalMessage": "The webinar was sent to Manager for review",
      "updatePublishMessage": "The webinar was updated and published successfully!",
      "updateMessage": "The webinar has been updated successfully!",
      "emptyState": {
        "title": "No webinars for this product yet",
        "titleCR": "Insightful webinars are coming soon",
        "messageCR": "Stay tuned for content updates. In the meantime, feel free to contact your Sustainability Advisor for any support.",
        "message1": "There are no webinars for this product yet.",
        "message2": "Be the first to add a webinar.",
        "noSharedClients": "This webinar cannot be shared yet as there are no active Clients with the Webinars module for this product."
      },
      "noContent": {
        "title": "Looks like the webinar you’re looking for does not exist.",
        "message1": "Please, ensure you’re using the valid link or choose webinar from the",
        "message2CR": "Feel free to contact your Sustainability Advisor for any support.",
        "message2": "Please contact Support if you think this page was moved or deleted mistakenly.",
        "linkText": "Webinars feed."
      }
    },
    "dashboard": {
      "routeTitle": "Dashboard",
      "personalClients": "Personal clients",
      "teamClients": "Team clients",
      "teamRFQs": "Team RFQs",
      "productList": {
        "tooltip": "Please, contact your Sustainability Advisor to get access to this product for your client.",
        "unavailableProduct": "unavailable"
      },
      "clientsInvitation": {
        "tableTitle": "Approved Client Representatives pending invite"
      },
      "MyDocuments": {
        "title": "My Documents",
        "clientTitle": "Recent Client Documents",
        "docMyReview": "Documents pending my review",
        "personalClients": "personal Clients",
        "productClients": "product Clients",
        "emptyStateForSBTextOne": "There are no documents uploaded for this product yet.",
        "emptyStateForSBTextTwo": "Be first to download the document for this Product",
        "emptyStateForClientDocuments": "There are no documents uploaded by Client Representatives and pending your review.",
        "emptyStateForCr": "Product-related documents are coming soon. In the meantime, feel free to contact your Sustainability Advisor for any support"
      },
      "clientsDropdown": {
        "title": "Choose Client to view their documents",
        "dropdownPlaceholder": "Search by Client name",
        "emptyFirstText": "This Client doesn’t have any documents.",
        "documents": "Documents",
        "myDocuments": "My Documents",
        "emptyClients1": "There are no Clients assigned to the selected product.",
        "emptyClients2": "Please, choose another product.",
        "notFoundClient": "No matching Clients. Please, try again or switch the Product",
        "emptySecondText": "Choose another Client or be first to upload the document for  this Client."
      },
      "modulesGroups": {
        "COMMUNICATION": "Communication",
        "TRADE_HISTORY": "Trade History",
        "INFORMATION_HUB": "Information Hub",
        "COMMODITY_MARKET": "Commodity Market",
        "MANAGEMENT": "Management",
        "ENERGY_EXCHANGE": "Trading Platform"
      },
      "pendingProductAccess": {
        "actions": "Actions",
        "fullName": "Full name",
        "client": "Client",
        "rejectRequest": "Reject Request",
        "requestedProduct": "Requested Product",
        "submittedAt": "Submitted at",
        "title": "Products requested by Client Representatives",
        "tooltip": "Please review and validate the requests  with Product Directors. Update  Access Settings if request is approved or reject the request.",
        "rejectErrorMessage": "Oops! It seems that a field was missed or invalid information was entered. Please provide the necessary details to proceed.",
        "rejectSuccessMessage": "The access request has been rejected successfully"
      },
      "clientsPendingApproval": {
        "actions": "Actions",
        "name": "Name",
        "client": "Client",
        "email": "Email",
        "products": "Products",
        "submittedBy": "Submitted by",
        "title": "Client Representatives pending approval",
        "newLabel": "New"
      },
      "clientsPendingInvitation": {
        "actions": "Actions",
        "name": "Name",
        "client": "Client",
        "products": "Products",
        "submittedBy": "Submitted by",
        "sendInvite": "Send invite",
        "title": "Approved Client Representatives pending invite"
      },
      "tabsNames": {
        "companyRepresentatives": "Client Representatives",
        "clients": "Clients",
        "sustainabilityAdvisors": "Sustainability Advisors",
        "businessSupport": "Business Support",
        "pendingProductAccess": "Pending Product access",
        "pendingApprovals": "Pending approvals",
        "pendingInvites": "Pending invites"
      }
    },
    "buttons": {
      "routeTitle": "Buttons"
    },
    "auth": {
      "routeTitle": "Welcome, please login.",
      "title": "Welcome, please login.",
      "resetPassword": "Reset password",
      "modalTitle": "Verify Two-Factor Authentication",
      "backgroundText": "Your smart partner in environmental commodities",
      "messages": {
        "passwordRecovery": "Password recovery instructions have been sent to your email"
      }
    },
    "productSwitcher": {
      "routeTitle": "Product Switcher",
      "title": "Product Switcher",
      "h1": "Welcome to AFS Client Portal",
      "h1Popup": "Select Your Product",
      "chat": "chat",
      "chooseProduct": "Select Your Product",
      "tip": "Select the Product to get access to the dashboard",
      "logout": "Log out",
      "requestAccess": "Request Access",
      "requestedAccess": "Access Requested",
      "pendingAccess": "Pending Access",
      "requestAccessNotification": "Your request was sent! Our Sustainability Advisors will review your request shorty"
    },
    "clients": {
      "routeTitle": "Clients",
      "tableTitle": "Clients",
      "personalTableTitle": "My personal clients",
      "teamTableTitle": "Team clients",
      "personalClients": "Personal clients",
      "teamClients": "Team clients"
    },
    "clientDocuments": {
      "routeTitle": "Client Documents"
    },
    "myDocuments": {
      "routeTitle": "My Documents"
    },
    "prices": {
      "routeTitle": "Price charts",
      "PriceFeaturesTitle": "{product} {year} Prices",
      "averagePurchasePrice": "Average Purchase price",
      "averageMarketPrice": "Average market price",
      "averagePurchasePriceHelper": "*based on your previous transactions",
      "averageMarketPriceHelper": "last update - {dateString}",
      "avgMarketPrice": "Avg market price",
      "recentMaxPrice": "Recent maximum price",
      "recentMinPrice": "Recent minimum price",
      "recentAvgMarketPrice": {
        "label": "Recent Average Market price, €",
        "placeholder": "Enter recent price",
        "errorText": "Please, enter the valid price"
      },
      "updatePrice": "Update price",
      "budgetAllocation": {
        "title": "Budget allocation",
        "totalBudget": {
          "label": "Total Budget",
          "placeholder": "Enter your budget"
        },
        "requiredEUAs": {
          "label": "Required EUAs",
          "placeholder": "Enter the number"
        },
        "yearToDayCosts": "Year-to-Day Cost",
        "ytdAveragePrice": "YTD Average Price",
        "remainingBudget": "Remaining budget",
        "pendingEUAsToPurchase": "Pending EUAs to Purchase",
        "calculate": "Calculate",
        "reset": "Reset",
        "saveResult": "Save results",
        "validationError": "Please, fill in all required fields"
      },
      "titleTooltip": "The price chart displays indicative prices of {technology} claimed in the compliance year of {issuanceYears}",
      "disclaimer": "This is an AFS Energy confidential report that contains price-sensitive information. Please refer to the complete disclaimer message for more information",
      "disclaimerLinkText": "here",
      "transactionHistory": {
        "transactionHistory": "Transaction history",
        "yourTransactionHistory": "Your transaction history",
        "switchToClientViewText": "Please, switch to the Client View to see Transactions history and Budget allocation calculator.",
        "tradeN": "Trade #",
        "date": "Date",
        "quantity": "Quantity",
        "price": "Price",
        "totalCost": "Total Cost",
        "notFoundText": "All your EUA trades for this year will be displayed here. Once at least one EUA trade for this year appears, you will be able to calculate your budget allocation.",
        "tradesLinkText": "See all EUA trades"
      },
      "graph": {
        "maximum": "Maximum",
        "minimum": "Minimum",
        "allTime": "All time",
        "fiveYears": "5Y",
        "oneYear": "1Y",
        "yearToDate": "YTD",
        "sixMonths": "6M",
        "oneMonth": "1M",
        "fiveDays": "7D"
      },
      "emptyState": {
        "titleForCR": "Recent {productName} prices will appear soon.",
        "title": "There are no data about market prices.",
        "textForCR": "Feel free to contact your Sustainability Advisor to get the newest price updates.",
        "text": "You can enter market prices for Client Portal in AFSWeb or contact Support in case of any questions or guidance.",
        "noDataCR1": "Prices for the selected Commodity type and year will appear soon.",
        "noDataCR2": "Feel free to contact your Advisor to get the latest prices information for the selected commodity type and year or choose another commodity type.",
        "noData1": "No prices for the selected commodity type and year.",
        "noData2": "Please enter prices for the selected technology and year in AFS Web."
      },
      "exportError": "Something went wrong. Please try again later or contact our support team.",
      "noPeriodDataText": "There are no prices for this time period",
      "viewAll": "Go to the Prices page",
      "technologiesLabel": "Commodity Type",
      "yearsLabelHBE": "Year of Issuance",
      "yearsLabelBIOMETHANE_TRANSPORT": "Year of Production",
      "yearsLabelTHG_Q": "Year of Issuance"
    },
    "resetPassword": {
      "routeTitle": "Reset password",
      "title": "Create a new password"
    },
    "rfqThanks": {
      "routeTitle": "RFQ Thank you page",
      "pageTitle": "Thank you for submitting the form!",
      "pageText": "Your Sustainability Advisor will contact you soon.",
      "viewSubmittedButton": "View submitted RFQs",
      "submitNewButton": "Submit new form"
    },
    "rfqCancelled": {
      "routeTitle": "RFQ form has been cancelled",
      "pageTitle": "The form has been cancelled!",
      "viewSubmittedButton": "View submitted RFQs",
      "submitNewButton": "Submit new form"
    },
    "rfqHistoryPage": {
      "routeTitle": "RFQs history",
      "title": "Submitted RFQs: {productName}",
      "notFoundMessageCR": "All submitted {productName} RFQs will be displayed here.",
      "notFoundMessageSA": "There are no RFQs submitted for this product yet.",
      "backButtonText": "Back to RFQ submission"
    },
    "rfqTable": {
      "title": "Submitted RFQs: {productName}",
      "notFoundMessage": "There are no RFQs submitted for this product yet.",
      "exportToCsv": "Export to CSV",
      "exportToXlsx": "Export to XLSX",
      "company": "Client",
      "submittedAt": "Submitted at",
      "personalClients": "Personal clients",
      "teamClients": "Team RFQs",
      "submittedBy": "Submitted by",
      "actions": "Actions",
      "sellBuy": "Sell/buy",
      "commodity": "Commodity",
      "type": "Type",
      "productionConsumption": "Production/Consumption",
      "period": "Period",
      "volume": "Volume",
      "price": "Price",
      "deliveryDate": "Delivery Date",
      "note": "Note",
      "packagingType": "Packaging type",
      "exportError": "Something went wrong. Please try again later or contact our support team.",
      "searchPlaceholder": "Search by any field",
      "productRelatedText": "Requests for Quote created by Product-related Clients",
      "noSearchResults": "There are no RFQs matching your search request. Please try another search.",
      "noSearchResultsForPersonal": "There are no requests submitted by your Clients for this product and matching your search. Please try another search or check general product RFQs.",
      "noSearchResultsForTeam": "There are no requests submitted for this product and matching your search. Please try another search or check RFQs submitted by your personal Clients."
    },
    "rfq": {
      "routeTitle": "RFQ",
      "formTitle": "RFQ: {productName}",
      "formText": "The Request for Quote (RFQ) does not create any legally binding obligations to a potential trade. It only serves as an inquiry regarding the price of a particular product.",
      "detailsSubmittedBy": "Submitted by {userName}",
      "detailsSubmittedAt": "Submitted at {date}",
      "detailsRouteTitle": "RFQ Details",
      "dashboard": {
        "titleForCr": "My requests for quote",
        "titleForSAandBS": "Latest requests for quote",
        "newRequestButton": "Submit new request",
        "showAll": "Show all",
        "notFoundMessageCR": "All submitted {productName} RFQs will be displayed here.",
        "notFoundMessage": "There are no requests submitted for this product yet",
        "saNotFoundMessage": "There are no requests submitted by your Clients for this product yet",
        "saNotFoundMessagePersonalOneText": "There are no requests submitted by your Clients for this product and matching your search.",
        "saNotFoundMessagePersonalTwoText": "Please try another search or check general product RFQs.",
        "saNotFoundMessageTeamOneText": "There are no requests submitted for this product and matching your search.",
        "saNotFoundMessageTeamTwoText": "Please try another search or check RFQs submitted by your personal Clients.",
        "generalProductRfq": "You can go to the General product RFQs to see RFQs submitted by product Clients.",
        "personalClients": "Personal Clients"
      },
      "historySection": {
        "title": "RFQs history",
        "showAll": "Show all",
        "notFoundMessage": "All submitted {productName} RFQs will be displayed here."
      },
      "historyItem": {
        "viewDetails": "View details",
        "buy": "Buy",
        "sell": "Sell"
      },
      "form": {
        "optionalMessage": "All fields are optional*",
        "buySellSwitcherLabel": "What is your interest?",
        "buyLabel": "Buy",
        "sellLabel": "Sell",
        "commoditiesLabel": "Which commodity interests you?",
        "isConsumptionLabel": "Is this a consumption or production?",
        "interestLabel": "Select your interest:",
        "productionLabel": "Production",
        "consumptionLabel": "Consumption",
        "technologyLabel": "Select your technology type:",
        "technologyTextLabel": "Provide a specific technology type:",
        "technologyPlaceholder": "Please enter the technology here",
        "regionLabel": "Region",
        "continentLabel": "Continent",
        "countryLabel": "Country",
        "yearOfProductionLabel": "Year of production",
        "yearOfConsumptionLabel": "Year of consumption",
        "yearOfIssuanceLabel": "Year of issuance",
        "timeOfProductionDeliveryLabel": "Time of production/delivery",
        "volumeMetricLabel": "Desired volume & metric",
        "targetPriceLabel": "Target price per {metric}",
        "deliveryDateLabel": "What is the desired delivery date?",
        "noteLabel": "Would you like to add more specifications or a note?",
        "notePlaceholder": "Please enter furter specification here.",
        "timeOfProductionLabel": "Time of production",
        "timeOfConsumptionLabel": "Time of consumption",
        "timeOfDeliveryLabel": "Time of delivery",
        "specificTypeLabel": "Do you require a specific type?",
        "quotaTypeLabel": "Select your specific type of quota",
        "certificationLabel": "Certification",
        "startLabel": "Start",
        "endLabel": "End",
        "yearPlaceholder": "Year",
        "quarterPlaceholder": "Quarter",
        "monthPlaceholder": "Month",
        "incotermsLabel": "Incoterms",
        "typeOfPackagingLabel": "Type of packaging",
        "vintageLabel": "Vintage",
        "reportingYearLabel": "Reporting year",
        "phaseLabel": "Phase",
        "any": "Any",
        "phasePlaceholder": "Phase",
        "type": "Type",
        "decimalsErrorMessage": "Maximum number of decimals 2 digits"
      },
      "months": {
        "10": "October",
        "11": "November",
        "12": "December",
        "01": "January",
        "02": "February",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "August",
        "09": "September"
      },
      "noContent": {
        "title": "Looks like the submitted RFQ  you’re looking for does not exist.",
        "message1": "Please, ensure you’re using the valid link or check",
        "message2CR": "Feel free to contact your Sustainability Advisor for any support.",
        "message2": "Please contact Support if you think this page was moved or deleted mistakenly.",
        "linkText": "previously submitted RFQs."
      }
    },
    "calendar": {
      "routeTitle": "Calendar",
      "header": "Events management",
      "subheader": "To manage Calendly meetings, please login to Calendly with your admin account.",
      "currentPeriodLabel": "Today",
      "payment": {
        "title": "Payment #{transId}",
        "content": "Payment deadline for {commodity} delivery on {deliveryDate}\nTrade:",
        "classes": "{invoiceClass}",
        "invoice": "Invoice",
        "completedOrNot": {
          "true": "Completed",
          "false": "Incomplete"
        }
      },
      "filter": {
        "allOptionsLabel": "All events",
        "title": "Filter by period",
        "weekLabel": "Week",
        "monthLabel": "Month"
      }
    },
    "createReminder": {
      "descriptionOne": "Create an event that will remind about important Product deadlines or tasks.",
      "descriptionTwo": "If event doesn’t have end date, leave the field empty.",
      "eventCreated": "The event has been created successfully!",
      "eventUpdated": "The event has been updated successfully!",
      "errorEndDateTime": "TendDateTime: This value should be greater than",
      "emptySharedWith": "This event cannot be shared yet as there are no active Clients with the Calendar module for this product."
    },
    "glossary": {
      "routeTitle": "Glossary"
    },
    "settings": {
      "routeTitle": "Settings",
      "infoTitle": "Personal information",
      "securitySettings": "Security settings",
      "notificationsSettings": "Notifications settings",
      "password": "Password",
      "multifactorAuthentication": "Multi-factor authentication",
      "isProductManager": "Product Manager",
      "linkedClients": "Linked Clients",
      "productsCoverage": "Products Coverage",
      "productManager": "Product Manager",
      "clientMessage": {
        "title": "Incorrect information?",
        "text": "Please contact our support via",
        "linkText": "email!"
      },
      "tabs": {
        "details": "My Profile",
        "security": "Security",
        "notifications": "Notifications"
      },
      "notify": {
        "module": "Module",
        "pushNotify": "Push notifications",
        "emailNotify": "E-mail notifications",
        "successfullyNotify": "Notification preferences have been changed successfully"
      },
      "updateAvatarSuccessMessage": "Profile photo has been updated successfully. You can close the form or continue editing your personal information",
      "updateAvatarSuccessMessageViewForm": "Profile photo has been updated successfully.",
      "deleteAvatarSuccessMessage": "Profile photo has been deleted successfully. You can close the form or continue editing your personal information",
      "deleteAvatarSuccessMessageViewForm": "Profile photo has been deleted successfully.",
      "avatarErrorMessage": "Something went wrong! Please try again later or contact our support team.",
      "bookMeeting": "Book 1-on-1",
      "email": "Email",
      "chat": "Chat",
      "revokeGoogleAccess": {
        "title": "Access to Google Calendar",
        "text": "Please revoke portal’s access to your Google Calendar if you have synced wrong Calendar or no longer want to sync your Calendar.",
        "buttons": {
          "revokeAccess": "Revoke access"
        },
        "revokeGoogleAccessSuccess": "Access to your Google Calendar has been revoked successfully!",
        "revokeGoogleAccessFailed": "Access was not revoked, please try again. Please contact Support if your attempts fail."
      }
    },
    "chat": {
      "routeTitle": "Chat",
      "tryAgain": "Please try another search query.",
      "pleaseTryAgain": "Please try again",
      "noClientsFound": "No Clients found.",
      "unreadMessages": "Unread Messages",
      "noAdvisorChat": "The chosen Advisor does not have any chats yet.",
      "noSustainabilityFound": "No Sustainability Advisors found.",
      "noSustainabilityAdvisorsFound": "You don’t have Clients with an enabled Chat module yet.",
      "participants": "participants",
      "viewChatFiles": "View chat files",
      "chooseChat": "Chat with Advisors",
      "chooseProduct": "Choose a Product chat to start messaging.",
      "searchForAdvisor": "Search for Advisor",
      "searchForClient": "Search for Client",
      "messagePlaceholder": "Type your message here",
      "personal": "Personal",
      "noMessages": "No messages in this chat yet",
      "selectClients": "Select Advisor  to check their chats",
      "welcomeOurChat": "Hello! Welcome to our chat. How can we assist you today?",
      "hasJoinChat": "has joined the chat",
      "noAdvisors": "There are no Advisors yet.",
      "joinChat": "Join Chat",
      "joinChatInfo": "Join this chat room to send messages. You wil be displayed in the chat participants after joining.",
      "uploadingIssue": "There was an issue uploading the file. Please, try another re-uploading file",
      "uploadingISizeError": "File size is bigger than 50 Mb. Please, choose another file",
      "uploadingSupportedError": "You cannot attach this file type. Supported extensions: pdf, docx, svg, png, jpg, xlsx, xls, csv, XML. Please, try uploading the file with the supported extension",
      "chats": "chats",
      "team": "Team",
      "recent": "Recent"
    },
    "notifications": {
      "routeTitle": "Notifications"
    },
    "not_found": {
      "routeTitle": "Page not found",
      "title": "404 Error",
      "content": "We can’t find the page you’re looking for. Please explore other sections of our portal.",
      "tip": "Looks like the requested page does not exist or you do not have access to it.",
      "button": "Go to Dashboard"
    },
    "clientsProfile": {
      "routeTitle": "Client profile",
      "approveAccountSuccessMessage": "The Account was sent for Approval",
      "approveAccountErrorMessage": "Error! Please try again later",
      "updateAvatarSuccessMessage": "The profile photo has been updated successfully. You can close the form or continue editing user information",
      "updateAvatarSuccessMessageViewForm": "The profile photo has been updated successfully.",
      "deleteAvatarSuccessMessage": "The profile photo has been deleted successfully.",
      "avatarErrorMessage": "Something went wrong! Please try again later or contact our support team.",
      "noContent": {
        "title": "Looks like the Client Representative you’re looking for does not exist.",
        "message1": "Please, ensure you’re using the valid link or go to the",
        "message2CR": "Feel free to contact your Sustainability Advisor for any support.",
        "messageSA": "Please, ensure you’re using the valid link or contact Support if you think this page was moved or deleted mistakenly.",
        "linkTextCR": "Client Profile page."
      }
    },
    "clientsNew": {
      "routeTitle": "Create new client",
      "backButton": "Back to Clients",
      "addClientTitle": "Request Client",
      "addClientText": "Here you can add a new Client.  Please note that all fields are mandatory.",
      "addRepresentativeTitle": "Create Client Representative",
      "addRepresentativeText": "Add a representative user to the Client profile and assign products to them.",
      "togglerText": "Create Client Representative",
      "errorMessage": "It looks like information about Representative #{ index } is invalid or you've missed a field. Please provide the necessary details to proceed",
      "successfully": "New Client and Client Representatives have been created successfully",
      "tooltipText": "You cannot remove the Create Representative form as you have more than two Represenatives. Please, delete one of Representatives",
      "providedServices": "Services Provided to the Client",
      "service": "Service",
      "tradingBrokerage": "Trading/Brokerage",
      "advisoryAccountManagement": "Advisory/Account Management",
      "noSALinkedText": "There are no Sustainability Advisors linked to this client yet"
    },
    "status": {
      "draft": "draft",
      "Invited": "invited",
      "active": "active",
      "inactive": "inactive",
      "approved": "approved",
      "awaiting signup": "awaiting signup",
      "Pending invite": "pending invite",
      "Pending approval": "pending approval",
      "awaiting approval": "awaiting approval",
      "awaiting invite": "awaiting invite"
    },
    "clientsCompanyProfile": {
      "routeTitle": "Client profile",
      "details": "{companyName} Details",
      "access": "Access Settings",
      "teamClients": "Team clients",
      "personalClients": "Personal clients",
      "accessClient": "Linked to this client",
      "providedServices": "Services Provided to the Client",
      "SA": "Sustainability Advisors per Product",
      "clientSA": "Sustainability Advisors",
      "submitForApproval": "Submit for Approval",
      "approvalDisabledTooltip": "Client should have at least one representative",
      "approveCompanySuccessMessage": "The Client was sent for approval",
      "approveCompanyErrorMessage": "Error! Please try again later",
      "updateBdSuccessMessage": "Business Details were updated successfully!",
      "updatePsSuccessMessage": "Product Settings were updated successfully!",
      "updateLinkedSuccessMessage": "Linked to this Client were updated successfully!",
      "updateCompanySuccessMessage": "Business Details  has been updated",
      "companyWasApprovedMessage": "Request for this Client was already reviewed by Business Support",
      "updateSubEntitiesSuccessMessage": "Sub-entities  has been updated",
      "deleteSubEntitiesSuccessMessage": "Sub-entity has been deleted successfully",
      "updateSustainabilitySuccessMessage": "Information has been updated",
      "updateSustainabilityErrorMessageForEmail": "This email already exists in the system. Please use another email",
      "updateSustainabilityErrorMessage": "Something went wrong! Please try again.",
      "updateAvatarSuccessMessage": "The profile photo has been updated successfully. You can close the form or continue editing user information",
      "updateAvatarSuccessMessageViewForm": "The profile photo has been updated successfully.",
      "deleteAvatarSuccessMessage": "The profile photo has been deleted successfully.",
      "avatarErrorMessage": "Something went wrong! Please try again later or contact our support team.",
      "updateCompanyErrorMessage": "Something went wrong! Please try again later or contact our support team.",
      "linkedToThisCompany": "Linked to this client",
      "errorMessage": "Something wrong. Please check your internet connection, or write to our support.",
      "companyRepresentativesTitle": "Client Representatives",
      "subEntitesTitle": "Sub-entities",
      "subEntitiesTooltip": "A sub-entity is an independent company that is associated with a broader brand or organization. Usually, a sub-entity is (wholly) owned by the organization.",
      "backButtonText": "Back to Client",
      "backButtonTextCR": "Back to Client overview",
      "Other": "Other",
      "noContent": {
        "title": "Looks like the Company you’re looking for does not exist.",
        "message1": "Please, ensure you’re using the valid link or go to the",
        "message2": "Please contact Support if you think this page was moved or deleted mistakenly.",
        "linkText": "Clients list."
      },
      "tabGroups": {
        "companyDetails": "Client details",
        "users": "Users",
        "productAccess": "Product access",
        "subEntities": "Sub-entities"
      },
      "productsEditNotAllowedforSATooltip": "You can change Products only for draft Companies",
      "deactivationDisabledTooltip": "You cannot deactivate this Company as it has active Representatives. Please deactivate all linked Company Representatives first",
      "reactivationDisabledTooltip": "You cannot reactivate Company Representative linked to the deactivated Company. Please, reactivate the Company first"
    },
    "createSubEntity": {
      "routeTitle": "Create Subentity",
      "tip": "A sub-entity is an independent client that is associated with a broader brand or organisation.",
      "businessIdentifiers": "Business Identifiers",
      "tooltips": {
        "chamberOfCommerceText": {
          "label": "Chamber of Commerce number",
          "tooltip": "The unique code that is given to a person during registering a business in the Chamber of Commerce. It proves that you are officially registered in the trade register, in which legal persons and companies are listed with their data."
        },
        "vatText": {
          "label": "VAT number",
          "tooltip": "A value-added tax identification number is a unique number that identifies a taxable person (business) or non-taxable legal entity that is registered for value-added tax purposes."
        },
        "leiText": {
          "label": "Legal Entity Identifier (if applicable)",
          "tooltip": "A unique 20-character code for identifying entities that trade in financial markets (stocks, bonds, energy certificates, etc.)"
        },
        "legalRepresentativeName": {
          "tooltip": "The authorized person to sign on behalf of the company. "
        }
      }
    },
    "subEntityDetails": {
      "routeTitle": "Subentity details",
      "noContent": {
        "title": "Looks like the Sub-entity you’re looking for does not exist.",
        "message1": "Please, ensure you’re using the valid link or go to the",
        "message2CR": "Feel free to contact your Sustainability Advisor for any support.",
        "messageSA": "Please, ensure you’re using the valid link or contact Support if you think this page was moved or deleted mistakenly.",
        "linkTextCR": "Client Profile page.",
        "linkText": "Clients list."
      }
    },
    "sAdvisors": {
      "routeTitle": "Sustainability advisors",
      "tableTitle": "Sustainability Advisors",
      "fullName": "Full name",
      "status": "Status",
      "resendInviteSuccessMessage": "Invite was sent succesfully"
    },
    "sAdvisorsNew": {
      "routeTitle": "Create sustainability advisor",
      "addAdvisorTitle": "Create Sustainability Advisor",
      "addAdvisorText": "An AFS Employee that manages information and trades for clients on the platform"
    },
    "sAdvisorProfile": {
      "routeTitle": "Sustainability advisor profile",
      "noContent": {
        "title": "Looks like Sustainability Advisor you’re looking for does not exist.",
        "titleForBS": "Looks like Business Support you’re looking for does not exist.",
        "message1": "Please, ensure you’re using the valid link or go to the",
        "message2CR": "Feel free to contact your Sustainability Advisor for any support.",
        "messageSA": "Please, ensure you’re using the valid link or contact Support if you think this page was moved or deleted mistakenly.",
        "linkTextCR": "Client Profile page.",
        "linkText": "Sustainability Advisors.",
        "linkTextBS": "Business Supports."
      }
    },
    "bSupps": {
      "routeTitle": "Business support",
      "tableTitle": "Business Supports"
    },
    "bSuppProfile": {
      "routeTitle": "Business support profile"
    },
    "userCompany": {
      "routeTitle": "Client"
    },
    "recycleBin": {
      "routeTitle": "Recycle bin",
      "empty": "There are no documents deleted in the last 30 days"
    },
    "approveClient": {
      "routeTitle": "Approve client",
      "footerText": "Approval/Rejection will automatically Approve/Reject both Client and Client Representatives",
      "footerApprovedClientText": "Approve/Reject Client Representatives",
      "companyInfo": {
        "title": "Approve Client",
        "description": "Check the contact information and product coverage of client representative."
      },
      "representativeInfo": {
        "title": "Approve Client Representative",
        "description": "Check Client Representative’s contact information and Product coverage."
      },
      "form": {
        "requestedBy": "Requested by {user}"
      },
      "noSALinkedText": "There are no Sustainability Advisors linked to this client yet",
      "rejectMessage": "Client and Client Representative have been rejected successfully",
      "rejectMessageCR": "Client Representative has been rejected successfully!",
      "rejectModalCR": "Reject Client Representative",
      "rejectModalCRandCompany": "Reject Client and Client Representative",
      "approveMessage": "Client and Client Representatives have been approved successfully!",
      "approveMessageCR": "Client Representative has been approved successfully!",
      "submittedBy": "Submitted by",
      "representativeNav": "Representative {current} out of {total}",
      "previous": "Previous",
      "next": "Next",
      "representativeValidationErrorMessage": "It looks like information about Representative #{listIndex} is invalid or you've missed a field. Please provide the necessary details to proceed."
    },
    "invite": {
      "routeTitle": "Welcome",
      "welcomeTitle": "Hello",
      "welcomeTo": "Welcome to",
      "thePortal": "The Portal",
      "welcomeSubtitle": "Your Guide Towards",
      "netZero": "Net Zero",
      "getStarted": "Let’s get started",
      "skipSignup": "Sign up now",
      "title": "Welcome to your exclusive AFS Client Portal, {userName}! Before you start, please set up your account and verify your account information.",
      "widgets": {
        "Blog": "Market insights",
        "Webinars": "Webinars",
        "Chat": "Chat",
        "DocLib": "Document Library",
        "Trades": "Trades",
        "MyDoc": "Client documents",
        "RFQ": "RFQ",
        "Calendar": "Calendar"
      },
      "steps": {
        "1": {
          "name": "Create Password",
          "title": "Create a strong password and agree to our terms & conditions",
          "text": "Please create a secure password for your AFS Client Portal account.",
          "text1": "We would like to ensure you have read and agree with our Terms and Conditions."
        },
        "2": {
          "name": "Enable Two-Factor Authentication",
          "title": "Set up Two-factor Authentication",
          "text": "Your data protection is our number one priority. To add an extra layer of security to your account, please set up the two-factor authentication."
        },
        "3": {
          "name": "Verify information",
          "businessInfoTitle": "Business Information",
          "businessInfoText": "Please examine  your business information and fill in the required details.",
          "businessInfoText2": "This section has been confirmed for you by another client representative. You may examine the business information and update the details, if necessary.",
          "personalInfoTitle": "Personal Information",
          "PersonalInfoText": "Please add your contact details to stay informed about your ongoing trades and receive the latest market insights.",
          "tooltip": "We've selected this name for you to retrieve your trades in our trading administration. Please contact your Sustainability Advisor if this is incorrect and we will correct it accordingly",
          "tooltipCR": "We use this email and full name to identify you in the system. Please, contact your Sustainability Advisor if you would like to change them."
        }
      },
      "tfaNotice": "First, please download the Google Authenticator to your smartphone and scan this QR code",
      "codeLabel": "After that, please enter the passcode that was generated in the app",
      "footer": "Step {currentStep} of {stepsNumber}",
      "tfaguid": {
        "1": "Download Google Authenticator app on the app store.",
        "2": "Add an account in the app.",
        "3": "Choose \"Scan barcode\" or \"Enter key\".",
        "4": "Scan QR code or type key.",
        "5": "Enter code to verify authentication.",
        "6": "Save backup codes.",
        "7": "Done! Use Google Authenticator for login."
      },
      "tfaplaceholder": "Enter code"
    },
    "welcome": {
      "steps": {
        "1": {
          "subtitle": "Trade history",
          "title": "One-hub for all trades and documents",
          "benefit1": "Streamlined access to your portfolio",
          "benefit2": "Real-time status of your trades and deliveries",
          "benefit3": "Enhanced overview of team tasks to complete trades"
        },
        "2": {
          "subtitle": "Sustainable communication",
          "title": "Timely guidance for your net zero journey",
          "benefit1": "Obtain convenient and clear pricing information",
          "benefit2": "Have real-time communication with Advisors",
          "benefit3": "Receive automatic reminders and notifications for your trades"
        },
        "3": {
          "subtitle": "Sustainability Information Hub",
          "title": "Market insights to empower your daily decisions",
          "benefit1": "Stay up-to-date with our daily newsletters and gain valuable insights into market developments",
          "benefit2": "Use our modules to your advantage and save time and effort",
          "benefit3": "Accelerate your business growth with  informed purchasing decisions"
        },
        "4": {
          "title": "Ready for Net Zero?",
          "btnText": "Start my journey"
        }
      }
    },
    "doclib": {
      "productDocumentTitle": "Product-specific documents",
      "uploadDoc": "Upload document",
      "documents": "documents",
      "uploadDocForProduct": "Or drag-and-drop a document here.",
      "clientDocumentTitle": "documents",
      "clientDocumentTitleCR": "My documents",
      "noCategories": "There are no documents in this Category yet.",
      "noProductCategoriesCR": "Product-related documents for this Category are coming soon.",
      "noTradeCategoriesCR": "Your trades-related documents for this Category will appear here.",
      "recycleBin": "Recycle bin",
      "deletedAt": "Deleted at:",
      "chooseClient": "Please, choose Client first",
      "dragOrDrop": "or drag and drop file",
      "myClientDocumentsPRTitle": "My client docs pending review",
      "teamClientDocumentsPRTitle": "Team client docs pending review",
      "clientDocumentsPRTitle": "Uploaded by Client Representatives and pending review",
      "productDocumentsPRTitle": "Product-specific documents Pending publication",
      "SAproductDocumentsPRTitle": "My documents Pending review",
      "CRdocumentsPRTitle": "Pending documents",
      "tabs": {
        "clientTabName": "Client-specific pending review",
        "productTabName": "Product-specific pending publication",
        "ownTabName": "Documents pending review"
      },
      "documentPublished": "Document is published successfully",
      "documentPublishedError": "Publishing was failed. Please try again later",
      "documentApprove": "Document is approved successfully",
      "documentApproveError": "Approving was failed. Please try again later",
      "deletedCategorySuccess": "The Category was deleted successfully",
      "renameSuccessfully": "The Category was renamed successfully",
      "areYouSure": "Are you sure? Deleted document won’t be shown in the Document Library.",
      "deleteSuccess": "The file was deleted successfully",
      "deleteCategorySuccess": "The category was deleted successfully",
      "restoredSuccess": "Document was restored successfully",
      "deletedText": "You can contact Business Support to restore deleted documents within 30 days .",
      "deleteCategory": "Are you sure you want to delete this category?",
      "backButtonText": "Back to the Document Library",
      "documentLibraryForCr": "My product documents",
      "documentLibraryForSAandBS": "Recent product documents",
      "emptyDocumentFirst": "There are no documents uploaded for this product yet.",
      "emptyDocumentSecond": "Please, contact your Sustainability Advisor for Product updates.",
      "emptyStateForSB": "Be the first to upload the document.",
      "viewAllDocuments": "View all documents",
      "backButtonTextForMyDoc": "Back to the client documents",
      "backButtonTextForClientDoc": "Back to the client documents",
      "emptyCategory": "There are no documents in this category yet.",
      "docMyReview": "Documents pending review",
      "emptyTitle": "No documents yet",
      "emptyTitleCR": "Product-related documents are coming soon",
      "emptyText": "There are no documents for this product yet. Be the first to upload documents for this product.",
      "emptyTextCR": "In the meantime, feel free to contact your Sustainability Advisor for any support.",
      "emptyClientDocTitle": "This Client doesn’t have any documents.",
      "emptyClientDocTitleCR": "Your trades-related documents will appear here.",
      "emptyClientDocText": "Choose another Client or be the first to upload the document for this Client.",
      "emptyClientDocTextCR": "You can upload your first document to keep them all organized on our platform.",
      "noContent": {
        "title": "Looks like the Category you’re looking for does not exist.",
        "message1": "Please, ensure you’re using the valid link or choose another category from the list.",
        "message2CR": "Feel free to contact your Sustainability Advisor for any support.",
        "message2": "Please contact Support if you think this page was moved or deleted mistakenly."
      }
    },
    "events": {
      "upcomingMeetings": "Upcoming Meetings",
      "upcomingEvents": "Upcoming Events",
      "pastMeetings": "Past Meetings",
      "pastEvents": "Past Events",
      "createdBy": "Created by:",
      "personalEvent": "Personal event",
      "sharedWith": "Shared with:",
      "join": "Join",
      "showDetails": "Show details",
      "deleteSuccess": "The event has been deleted!",
      "deleteError": "You are not authorized to delete this event",
      "hideDetails": "Hide details",
      "areYouSure": "Are you sure?",
      "deletedSharedWith": "This event will be deleted from your calendar and all users that this event is shared with.",
      "pastEventsEmpty": "Past in-app events will be displayed here.",
      "pastMeetingsEmpty": "Your past events with AFS Energy will appear here.",
      "upcomingEventsEmptyEvents": "Upcoming in-app events will be displayed here.",
      "upcomingMeetingsEmptyMeetings": "Your upcoming events with AFS Energy will appear here.",
      "upcomingEventsEmpty": "You can create your own event to remind you about important deadlines or milsetones.",
      "upcomingMeetingsEmpty": "If you have any questions or would like to talk to your Advisor, feel free to schedule a call via AFS Client Portal.",
      "bsuppMessage": "Please, go to Client view and select the Product to see upcoming meetings with Clients.",
      "allowAccess": "Please, allow access to your Google Calendar to see upcoming meetings.",
      "synchronizeCalendar": "Synchronize my Google Calendar",
      "failedSynchronizeCalendar": "Failed to synchronize calendar.",
      "sharedWithEverybody": "Shared with all",
      "dashboard": {
        "titleForCr": "My events",
        "titleForSAandBS": "Latest events",
        "showAllButton": "Show all events",
        "today": "Today",
        "todayLoading": "Your events scheduled for today will appear here.",
        "todayEmpty": "There are no events scheduled for today.",
        "tomorrow": "Tomorrow",
        "tomorrowLoading": "Your events scheduled for tomorrow will appear here.",
        "tomorrowEmpty": "There are no events scheduled for tomorrow.",
        "scheduleButton": "Schedule a call with my Advisor"
      },
      "types": {
        "Event": "Event",
        "DeliveryPaymentDate": "Payment date",
        "ImportedMeetings": "Imported Meeting"
      }
    },
    "docSection": {
      "title": "Category"
    },
    "transactions": {
      "searchTitle": "Search for a specific trade",
      "statusLabel": "Status",
      "searchPlaceholder": "Search for Trade number, commodity, technology,  country, etc",
      "title": "All Trades",
      "exportError": "Something went wrong. Please try again later or contact our support team.",
      "noResults": "There are no trades matching your search request. Please try another search.",
      "noTransactionsTitle": "All your trades will be displayed here",
      "noTransactionsTitleSA": "No trades yet",
      "noTransactionsTextSA": "You don’t have any trades related to this product yet.",
      "noTransactionsText": "If you are interested to receive advice on your sustainability transition or would like to procure or sell one of the environmental commodities, feel free to contact your Sustainability Advisor.",
      "noTransactionsTextRFQ": "If you are interested to receive advice on your sustainability transition or would like to procure or sell one of the environmental commodities, feel free to contact your Sustainability Advisor or use our {link} form.",
      "requestQuatation": "Request for Quotation",
      "statuses": {
        "ALL_STATUSES": "All statuses",
        "DEAL_TRADE_CONFIRMATION_TO_BE_SIGNED": "Trade Confirmation to be signed",
        "DEAL_CONTRACT_TO_BE_UPLOADED": "Contract to be uploaded",
        "DEAL_CONTRACT_TO_BE_SIGNED": "Contract to be signed",
        "DEAL_WAITING_FOR_DELIVERIES": "Waiting for deliveries",
        "DEAL_DELIVERIES_COMPLETED": "Deliveries completed",
        "DEAL_COMPLETED": "Trade finalized",
        "DEAL_CANCELED": "Trade canceled",
        "DEAL_HAS_PENDING_TASKS": "Trade with pending tasks",
        "CONTRACT_TO_BE_UPLOADED": "Contract to be uploaded",
        "CONTRACT_TO_BE_SIGNED": "Contract to be signed",
        "CONTRACT_SIGNED": "Contract signed",
        "TC_NOT_UPLOADED": "TC to be uploaded",
        "TC_UPLOADED": "TC uploaded",
        "TC_SIGNED": "TC signed",
        "TC_COMPLETED": "Trade completed",
        "TC_CANCELED": "Trade cancelled",
        "TRADE_TO_BE_COMPLETED": "Trade to be completed",
        "MASTER_AGREEMENT_SIGNED": "Master agreement signed"
      },
      "pendingTasks": "Pending tasks",
      "noPendingTasks": "No pending tasks",
      "noPendingTasksCR": "All tasks have been completed.",
      "show": "Show",
      "trade": "Trade",
      "viewAll": "View all trades",
      "widget": {
        "title": "Latest trades",
        "titleCR": "My trades",
        "pendingOneTrade": "trade",
        "pendingMultipleTrades": "trades",
        "pendingText": "require your attention.",
        "pendingLink": " View pending tasks",
        "pendingEmpty": "You don’t have pending tasks",
        "pendingEmptyCR": "All tasks have been completed. If you have any questions about your transactions, feel free to reach out to your Sustainability Advisor.",
        "empty": "There are no trades for this product yet.",
        "emptyCR1": "All your trades will be displayed here.",
        "emptyCR2": "If you have any questions about your transactions, feel free to reach out to your Sustainability Advisor"
      }
    },
    "transactionDetails": {
      "statusTitle": "Trade  status",
      "title": "Trade Confirmation: {transactionId}",
      "sideTitle": "Sustainability Advisors",
      "sideTitleSingle": "Sustainability Advisor",
      "maTitle": "Master agreement",
      "maText": "No additional contract necessary",
      "backButtonText": "Back to all Transactions",
      "deliveries": {
        "latestDeliveryDate": "Latest Delivery Date",
        "actualDeliveryDate": "Actual Delivery Date",
        "invoiceDate": "Invoice Date",
        "paymentTerm": "Payment Term",
        "paymentDate": "Payment Date",
        "paymentStatus": "Payment status",
        "paid": "Paid",
        "notPaid": "Not paid",
        "volume": "Volume",
        "minVolume": "Minimum Volume",
        "estVolume": "Estimated Volume",
        "actualDeliveredVolume": "Actual delivered volume",
        "price": "Price",
        "production": "Production",
        "registryBuyer": "Registry Buyer",
        "registrySeller": "Registry Seller",
        "remarks": "Remarks"
      },
      "invoice": "Invoice",
      "productSubtitle": "Product Info",
      "deliveriesSubtitle": "Deliveries",
      "sellerSubtitle": "Seller",
      "buyerSubtitle": "Buyer",
      "product": {
        "productName": "Product name",
        "technologyType": "Technology Type",
        "country": "Country",
        "deliveryPeriod": "Production Period",
        "deliveryType": "Delivery Type",
        "totalDealPrice": "Total Deal Price"
      },
      "info": {
        "companyName": "Client Name",
        "sustainabilityAdvisor": "Sustainaibility Advisor",
        "companyRepresentative": "Client Representative"
      },
      "noContent": {
        "title": "Looks like the Trade you’re looking for does not exist.",
        "message1": "Please, ensure you’re using the valid link or",
        "message2CR": "Feel free to contact your Sustainability Advisor for any support.",
        "message2": "Please contact Support if you think this page was moved or deleted mistakenly.",
        "linkText": "check other Trades."
      },
      "documents": {
        "title": "Trade documents",
        "masterAgreement": "Master Agreement",
        "signedContract": "Signed contract",
        "tradeConfirmation": "Trade Confirmation",
        "noContent": "Documents related to this Trade will appear here"
      }
    },
    "pushNotifications": {
      "unread": "unread",
      "markAsRead": "Mark all as read",
      "viewOlderNotifications": "View notifications",
      "notFoundMessage": "No notifications yet.",
      "myNotificationsTitle": "My notifications",
      "dateStrings": {
        "year": "year",
        "years": "years",
        "month": "month",
        "months": "months",
        "day": "day",
        "days": "days",
        "hour": "hour",
        "hours": "hours",
        "minute": "minute",
        "minutes": "minutes",
        "second": "second",
        "seconds": "seconds",
        "now": "now"
      },
      "accountSignUp": {
        "title": "Sustainability Advisor created",
        "message": "New user {accountName} was signed up.",
        "linkText": "Review the user"
      },
      "clientCompanyCreated": {
        "title": "Client created",
        "message": "New Client {companyName} was created by {accountName}.",
        "linkText": "Review the client"
      },
      "subEntityEdited": {
        "title": "Sub-entity information changed",
        "message": "{accountName} has edited sub-entity {subentityName} of the company {companyName}.",
        "linkText": "Please review recent changes"
      },
      "rfqCreated": {
        "title": "New RFQ was created",
        "message": "New RFQ for the {companyName} was created by {accountName}.",
        "linkText": "Review the RFQ"
      },
      "clientCompanySubmittedForApproval": {
        "title": "Client is awaiting approval",
        "message": "New Client {companyName} was sent for your approval.",
        "linkText": "Review the client"
      },
      "accountInvited": {
        "title": "Client Representative invited",
        "message": "Representative {accountName} of {companyName} was invited to the Client Portal.",
        "linkText": "Check Representative's profile"
      },
      "clientUserAdded": {
        "title": "Client Representative created",
        "message": "New user {inviteeName} was created by {saName} of {companyName}.",
        "linkText": "Review the user"
      },
      "clientCompanyEdited": {
        "title": "Business info changed",
        "message": "{companyName} information was edited by {accountName}.",
        "linkText": "Review the client"
      },
      "ioiSubmitted": {
        "title": "New Indication of Interest submitted by {companyName}",
        "message": "Please set the price for the request.",
        "linkText": "See IOI details"
      },
      "offerRejected": {
        "title": "An Indication of Interest for {product} from {companyName} has been rejected.",
        "message": "Please take the next steps and facilitate this trade.",
        "linkText": "Check IOI details"
      },
      "offerAccepted": {
        "title": "An Indication of Interest for {product} from {companyName} has been accepted.",
        "message": "Please take the next steps and facilitate this trade.",
        "linkText": "Check IOI details"
      },
      "priceAcceptedMarketOrder": {
        "title": "{type} Order Accepted",
        "message": "A {type} order {orderNumber} for {product} from {companyName} by {companyRepresentative} has been successfully placed.",
        "messageSa": "The {type} order {orderNumber} for {product} from {companyName} by {companyRepresentative} has been accepted.",
        "linkText": "Order confirmations",
        "popupMessage": "Your order has been filled successfully.",
        "popupMessageLimit": "Your limit order has been created successfully."
      },
      "priceAcceptedLimitOrder": {
        "title": "Successfully Placed Limit Order",
        "message": "A {type} order {orderNumber} for {product} from {companyName} by {companyRepresentative} has been successfully placed.",
        "messageSa": "The {type} order {orderNumber} for {product} from {companyName} by {companyRepresentative} has been accepted.",
        "linkText": "Open Orders",
        "popupMessage": "Your limit order has been placed successfully."
      },
      "orderRequestedToCancel": {
        "title": "Requested a Limit Order Cancellation",
        "message": "{companyRepresentative} from {companyName} has requested a cancellation for limit order {orderNumber} for {product}"
      },
      "orderCanceled": {
        "title": "Limit order cancelled",
        "message": "The limit order {orderNumber} has been cancelled.",
        "messageSa": "The limit order {orderNumber} by {companyName} has been cancelled."
      },
      "orderFilled": {
        "title": "Limit order filled",
        "message": "The limit order {orderNumber} has been filled.",
        "messageSa": "The limit order {orderNumber} by {companyName} has been filled."
      },
      "priceRejected": {
        "title": "{type} Order Rejected",
        "message": "Your {type} order {orderNumber} for {product} from {companyName} could not be placed succesfully.",
        "messageSa": "The {type} order {orderNumber} for {product} from {companyName} by {companyRepresentative} has been rejected",
        "linkText": "Contact your advisor to facilitate the order.",
        "popupMessage": "Your order has not been filled"
      },
      "userOpenedExchange": {
        "messageSa": "The user {companyRepresentative} from {companyName} has opened the EUA Trading page.",
        "title": "User opened EUA Trading page",
        "linkText": "Order confirmations"
      }
    },
    "commodityVolumes": {
      "routeTitle": "Commodity volumes",
      "titleCR": "{activeProduct} Volumes",
      "title": "{activeProduct} Volumes - {client}",
      "calculateVolumesTitle": "Calculate Client's {activeProduct} Volumes",
      "calculateVolumesTitleBiomethaneTransport": "Calculate Client's Green Gas Production",
      "totalProduced": "Total {activeProduct} volumes",
      "totalSold": "Total sold",
      "toBeSold": "To be sold",
      "toBeSoldTip": "Based on current market price",
      "savingLimit": "Saving limit",
      "savingLimitTooltip": "Volumes above {value} should be transferred before {date}",
      "totalExpected": "Total expected",
      "savingLimitTip": "valid until {date}",
      "viewAll": "View historical data",
      "graph": {
        "soldBioTrans": "Sold this month",
        "openBioTrans": "Open this month",
        "expectedBioTrans": "Expected per month",
        "totalProduced": "Total volume",
        "totalSold": "Total sold",
        "sold": "Sold",
        "avgSalePrice": "Avg sale price: {value} €/HBE",
        "avgMarketPrice": "Avg market price: {value} €/HBE",
        "totalOpen": "Total open",
        "producedPerMonth": "{month} volume",
        "soldPerMonth": "Sold {month} volume",
        "openPerMonth": "Open {month} volume",
        "open": "Open",
        "expectedVolume": "Expected volume",
        "expiryDate": "Expiry date",
        "categories": {
          "1": "Jan",
          "2": "Feb",
          "3": "Mar",
          "4": "Apr",
          "5": "May",
          "6": "Jun",
          "7": "Jul",
          "8": "Aug",
          "9": "Sep",
          "10": "Oct",
          "11": "Nov",
          "12": "Dec"
        },
        "months": {
          "1": "January",
          "2": "February",
          "3": "March",
          "4": "April",
          "5": "May",
          "6": "June",
          "7": "July",
          "8": "August",
          "9": "September",
          "10": "October",
          "11": "November",
          "12": "December"
        }
      },
      "pieChart": {
        "soldSpot": "Sold spot",
        "forwardSales": "Forward sales",
        "toBeSold": "To be sold",
        "volumeToBeSold": "Volume to be sold",
        "expectedTotalToBeSold": "Expected total to be sold",
        "priceNote": "based on current market price"
      },
      "InputCommodityYear": {
        "updateYear": "Update",
        "savingLimitPlaceholder": "Enter saving limit",
        "totalExpectedPlaceholder": "Enter expected volume",
        "createYear": "Create year",
        "cancelYearUpdate": "Cancel",
        "successCreatingYear": "Created year succesfully",
        "errorUpdatingYear": "Error updating year",
        "successUpdatingYear": "The year has successfully been updated.",
        "errorCreatingYear": "Error creating year"
      },
      "InputCommodityMonths": {
        "sendNotificationButton": "Send notification to users",
        "closeButton": "Ready",
        "successNotifyingClient": "The client has been notified."
      },
      "CalculateCommodity": {
        "month": "Month",
        "open": "Open",
        "produced": "Produced",
        "totalProduced": "Total produced",
        "sold": "Sold",
        "inputVolumesButton": "Enter volumes",
        "inputVolumesModalTitle": "Enter volumes",
        "enterYearFirst": "Create a year before you can enter the volumes"
      },
      "CommodityMonthsInputField": {
        "producedPlaceholderMonthInput": "Enter produced"
      },
      "emptyState": {
        "titleCR": "Your personalized Volumes statistics will appear soon.",
        "textCR": "Feel free to contact your Sustainability Advisor for any support.",
        "titleRest": "No data yet",
        "textRest": "Please enter customers volumes data to see the graph"
      },
      "greenGasProduction": "Green Gas Production",
      "yearTrades": "Year Trades",
      "spotTrades": "Spot Trades",
      "yearMetrics": "Year Metrics"
    },
    "indicationOfInterest": {
      "routeTitle": "Indication of Interests",
      "logs": {
        "title": "Change history",
        "submit": "Submitted at {date} by {name}",
        "setPrice": "Priced {price} at {date} by {name}",
        "acceptPrice": "Accepted at {date}. Deal price: {price}.",
        "rejectPrice": "Rejected at {date}",
        "expired": "Expired at {date}"
      }
    },
    "apiProfile": {
      "routeTitle": "API Profile"
    },
    "energyExchange": {
      "routeTitle": "EUA Trading",
      "graph": {
        "title": "EU ETS Spot",
        "tabs": {
          "intraday": "Intraday",
          "1week": "1 week",
          "1month": "1 month",
          "3months": "3 months",
          "6months": "6 months",
          "1year": "1 year",
          "3years": "3 years",
          "5years": "5 years"
        },
        "price": "Price"
      },
      "EeLiveQuotesDisabled": {
        "title": "EU ETS AFS Quotes",
        "message": "Trading disabled",
        "button": "Chat your advisor for more information"
      },
      "EeLiveQuotesOutsideHours": {
        "title": "EU ETS AFS Quotes",
        "message": "Trading is only available between 9 am - 5:30 pm",
        "timezone": "(Europe/Amsterdam)"
      },
      "clientLanding": {
        "heroTitle": "Get ready to enter the EU ETS",
        "heroSubtitle": "Our whitepaper answers all your questions on carbon risk management and the EU ETS.",
        "ctaBtn": "Request Access",
        "ctaBtnRequested": "Access Requested",
        "ctaBtnGranted": "Access Granted",
        "heroVimeoVideoUrl": "https://player.vimeo.com/video/992800180",
        "heroVideoMp4Url": "https://assets-global.website-files.com/641ac4efb582664475039acd/6540f2e0c4e70f6b57b3d395_64354d0dd1b566e96d140c3b_iStock-918314666-transcode-transcode.mp4",
        "heroVideoWebmUrl": "https://assets-global.website-files.com/641ac4efb582664475039acd/6540f2e0c4e70f6b57b3d395_64354d0dd1b566e96d140c3b_iStock-918314666-transcode-transcode.webm",
        "heroVideoPoster": "https://assets-global.website-files.com/641ac4efb582664475039acd/6540f2e0c4e70f6b57b3d395_64354d0dd1b566e96d140c3b_iStock-918314666-transcode-poster-00001.jpg",
        "features": {
          "feature1": {
            "title": "Direct Market Access",
            "text": "Gain direct access to the largest and most liquid EU ETS carbon market, ensuring optimal trading opportunities.",
            "icon": "chart",
            "image": ""
          },
          "feature2": {
            "title": "Full Market Transparency",
            "text": "Enjoy full transparency on EU ETS markets, empowering informed decision-making.",
            "icon": "analyze",
            "image": ""
          },
          "feature3": {
            "title": "Live Quotations",
            "text": "Access live quotations on both spot and forward trades, keeping you up-to-date with real-time market movements.",
            "icon": "faster-innovation-with-partners",
            "image": ""
          }
        }
      }
    }
  },
  "products": {
    "doclib": {
      "name": "Document library"
    },
    "doclibCategories": {
      "name": "Document lib categories"
    },
    "tradeDocuments": {
      "name": "Client Documents"
    },
    "blog": {
      "name": "Market Insights"
    },
    "createBlog": {
      "name": "Create market insight"
    },
    "viewBlog": {
      "name": "Market insight"
    },
    "viewNewsletter": {
      "name": "Newsletter"
    },
    "editNewsletter": {
      "name": "Edit Newsletter"
    },
    "newsletter": {
      "name": "Newsletter"
    },
    "editBlog": {
      "name": "Edit market insight"
    },
    "recycleBin": {
      "name": "RecycleBin"
    },
    "webinarsList": {
      "name": "Webinars"
    },
    "viewWebinar": {
      "name": "Webinar"
    },
    "createWebinar": {
      "name": "Create webinar"
    },
    "editWebinar": {
      "name": "Edit webinar"
    },
    "transactions": {
      "name": "Trades"
    },
    "ppa": {
      "name": "Ppa"
    },
    "addwindfarm": {
      "name": "AddWindFarm"
    },
    "addsolarfarm": {
      "name": "Add Solar Farm"
    },
    "marketdata": {
      "name": "Market Data"
    },
    "fgas": {
      "name": "Fgas"
    },
    "go": {
      "name": "Go"
    },
    "ggc": {
      "name": "GGC"
    },
    "gpa": {
      "name": "Gpa"
    },
    "EU_ETS": {
      "name": "EU_ETS"
    },
    "vcm": {
      "name": "VCM"
    },
    "hbe": {
      "name": "HBE"
    },
    "thg_q": {
      "name": "THG_G"
    },
    "rtfc": {
      "name": "RTFC"
    },
    "biofuels": {
      "name": "BIOFUELS"
    },
    "f_gas": {
      "name": "F_GAS"
    },
    "cfc": {
      "name": "CFC"
    },
    "i_rec": {
      "name": "I_REC"
    },
    "rtc": {
      "name": "RTC"
    },
    "recs": {
      "name": "RECs"
    },
    "product": {
      "allProducts": "All Products",
      "All Products": "All Products",
      "selectAllProducts": "All Products",
      "GO": "GO",
      "BIOMETHANE_HEATING": "Biomethane Heating",
      "BIOMETHANE_TRANSPORT": "Biomethane Transport",
      "EU_ETS": "EU ETS",
      "VCM": "VCM",
      "HBE": "HBE",
      "THG_Q": "THG-Q",
      "RTFC": "RTFC",
      "BIOFUELS": "Biofuels",
      "F_GAS": "F-Gas Quota",
      "PPA": "PPA",
      "CFC": "CFC",
      "I_REC": "I-REC",
      "REFRIGERANT_GAS": "Refrigerant Gas",
      "RTC": "RTC",
      "RECS": "RECs",
      "MANAGEMENT": "Management"
    }
  },
  "ioi": {
    "profile": {
      "changePassword": "Change Password",
      "APIAccess": {
        "title": "API Access",
        "text": "Generate API key to be able to authorize and use AFS Energy RFQ API. Ensure to include this key when making requests to our API. API key is valid for 1 year after generation.",
        "textWithKey": "You have an active key generated at {createdAt} and expires at {expiresAt}. You can generate new API key. Please note that old API key will be disabled if you generate new key.",
        "button": "Generate API key",
        "buttonRegenerate": "Generate new API key"
      }
    }
  },
  "indicationOfInterest": {
    "status": {
      "new": "New",
      "accepted": "Accepted",
      "rejected": "Rejected",
      "in_progress": "In progress",
      "expired": "Expired"
    }
  },
  "IndicationOfInterestDetail": {
    "routeTitle": "Indication of Interest",
    "title": "Indication of Interest",
    "submittedBy": "Submitted By",
    "submittedAt": "Submitted At",
    "expiresAt": "Expires at {expiresAt}"
  },
  "IndicationOfInterestForm": {
    "interest": "What is your interest?",
    "buy": "Buy",
    "sell": "Sell",
    "buyOrSell": "Buy/Sell",
    "commodity": "Selected Commodity",
    "volume": "Desired volume & metric",
    "price": "Price per tCO2",
    "notionalPrice": "Notional price per tCO2"
  },
  "IndicationOfInterestTable": {
    "title": "Indications of Interest",
    "actions": "Actions",
    "legalName": "Company",
    "status": "Status",
    "sellOrBuy": "Buy/Sell",
    "commodity": "Commodity",
    "volume": "Volume",
    "price": "Price per tCO2",
    "notionalPrice": "Notional price",
    "noIoisFound": "No IOIs have been submitted for this product.",
    "filterByStatus": "Filter by status",
    "allStatuses": "All statuses"
  },
  "SetPriceForm": {
    "title": "Set price",
    "price": {
      "label": "Price per tCO2",
      "errors": {
        "required": "Price is required",
        "positiveOnly": "The price should be positive",
        "correctNumberFormat": "Please, enter a valid price"
      }
    },
    "currencyLabel": "Currency",
    "buttonText": "Set price",
    "createSuccessMessage": "The price has been send successfully",
    "updateSuccessMessage": "The price has been updated successfully",
    "priceCanNotBeUpdatedMessage": "You cannot update price for this IOI as its status has already changed."
  },
  "EeLiveQuotesTable": {
    "paymentTerm": "Term",
    "paymentTermTooltip": "Payment term indicates the number of days after trading until payment/delivery. For example T+2: T = Trading date, 2 = days until payment/delivery.",
    "bidPrice": "Sell",
    "askPrice": "Buy",
    "action": "Buy / Sell ",
    "title": "EU ETS AFS Live Quotes",
    "outOfHoursMessage": "Our service is currently unavailable outside of our working hours. Reach us between 09:00 and 17:30 (CET) from Monday to Friday.",
    "buy": "Buy",
    "sell": "Sell",
    "tooltip": "Buy or sell EUAs on your preferred payment terms. Place order for instant execution. All fees included.",
    "nextButtonTooltip": "Please select a quote",
    "noMarginAvailable": "No margins available."
  },
  "EeConfirmOrder": {
    "buyTitle": "Buy",
    "sellTitle": "Sell",
    "subTitle": "EUA",
    "subTitleTwo": "AFS Execution",
    "bid": "Bid (€)",
    "ask": "Ask (€)",
    "paymentTerm": "Payment term",
    "deliveryTerm": "Delivery term",
    "orderType": "Order type",
    "deliveryDate": "Delivery date",
    "paymentDate": "Payment date",
    "volume": "Volume in tCO2 *",
    "totalAmount": "Total amount",
    "confirmOrder": "Place order",
    "reviewOrder": "Review order",
    "poweredByAfs": "Powered by: AFS Execution",
    "toggleBuy": "Buy",
    "toggleSell": "Sell",
    "marketOrder": "Market Order",
    "priceTooltip": "The difference is calculated by comparing yesterday's latest price with the average of the current bid and ask prices.",
    "verifyAccountBeforeTrading": "Verify your account before trading",
    "volumeFieldEmpty": "Volume is required.",
    "kycApprovedLimit": "Orders are limited to {limit} units per client until they are settled.",
    "kycApprovedAmount": "You can't place orders over {amount} until previous orders have been settled.",
    "kycApprovedExeeded": "You have reached your {limit} unit limit on orders. Contact your advisor to trade more.",
    "noKycLimit": "Orders are limited to {limit} for non verified clients.",
    "noKycAmount": "You can't place orders over {amount} until your account is verified.",
    "noKycExeeded": "You have reached your {limit} limit on orders. Verify your account to keep trading.",
    "awaitingVerification": "Verify your account now",
    "kycVerificationSendSuccess": "Thank you! We will contact you within 24 hours for further steps.",
    "orderCreationFailed": "Failed to place order. Please contact your advisor!",
    "tooltipNotCompleteForm": "Fill in all required fields"
  },
  "TOption": {
    "t0": "T+0",
    "t2": "T+2",
    "t4": "T+4",
    "t6": "T+6",
    "t8": "T+8",
    "t10": "T+10",
    "writtenOutTermPayment": "Payment in {days} business days.",
    "writtenOutTermDelivery": "Delivery in {days} business days."
  },
  "TradingAvailability": {
    "title": "Trading Availability",
    "marketOpen": "Market Open",
    "marketClosed": "Market Closed",
    "tradingDisabledStatus": "Manually Disabled",
    "modalTitle": "{status} trading",
    "modalDescription": "Are you sure you want to {status} trading on the Client Portal?",
    "tooltipContent": "Market is open during 9 am – 5:30 pm (Europe/Amsterdam)\n\nMarket is closed during 5:31 pm – 8:59 pm (Europe/Amsterdam)\n\nDisabling trading will prevent all Client Representatives from trading.",
    "toggleContent": "Disable Trading",
    "status": "Status",
    "turnOff": "Turn OFF",
    "turnOn": "Turn ON",
    "cancel": "Cancel"
  },
  "MarginAdjustment": {
    "title": "Margin Adjustment",
    "commodityType": "Commodity Type *",
    "commodityValue": "EU ETS",
    "companyMark": "Powered by: AFS Execution",
    "status": "Status",
    "paymentTerm": "Payment term",
    "marginForBidPrice": "Margin for Bid price",
    "marginForAskPrice": "Margin for Ask price",
    "finalBidAskSpread": "Final bid-ask spread",
    "saveMargins": "Save Margins",
    "saveMarginsSuccess": "Margins have been saved successfully.",
    "saveMarginsError": "Margins could not be saved. Please contact Business Support."
  },
  "infoMessages": {
    "first": {
      "enabled": false,
      "id": "123",
      "products": [],
      "title": "First EUA Trading Platform in Europe Released",
      "list": [
        "Real-time EUA prices and direct market access",
        "Effortless trading and order confirmation",
        "Personalized support from our expert EUA team"
      ],
      "buttonLink": "/trading-platform",
      "buttonText": "Learn more today",
      "roles": [
        "ROLE_COMPANY_REPRESENTATIVE"
      ]
    },
    "second": {
      "enabled": true,
      "id": "2",
      "products": ["EU_ETS"],
      "title": "Place Limit Orders for EUAs – Available Now!",
      "list": [
        "Trade EUAs at the price you want.",
        "Effortless setup with real-time price tracking.",
        "Reach out to our expert team for personalized support."
      ],
      "buttonLink": "eu_ets/trading-platform/trade",
      "buttonText": "Learn more today",
      "roles": [
        "ROLE_BUSINESS_SUPPORT",
        "ROLE_SUSTAINABILITY_ADVISOR",
        "ROLE_COMPANY_REPRESENTATIVE",
        "ROLE_COPYWRITER"
      ]
    }
  },
  "EeOrderStatus": {
    "canceled": "Cancelled",
    "placed": "Accepted",
    "pending": "Pending",
    "open": "Open",
    "expired": "Expired",
    "limit cancellation requested": "Cancelled",
    "limit canceled": "Cancelled"
  },
  "KycStatusIndicator": {
    "kycStatus": "KYC Status",
    "Notrequested": "Not Requested",
    "Pre-KYCapproved": "Pre-KYC Approved",
    "Documentrequested": "Document Requested",
    "Moreinfoneeded": "More Info Needed",
    "KYCapproved": "KYC Approved",
    "KYCnotapproved": "KYC Not Approved"
  },
  "EeOrderConfirmation": {
    "clientCompany": "Client",
    "title": "Order confirmation",
    "orderNumber": "Order #",
    "buySell": "Buy/Sell",
    "buy": "Buy",
    "sell": "Sell",
    "totalAmountCertificates": "Total amount of certificates",
    "certificatePrice": "Price per certificate",
    "totalNotional": "Total notional amount",
    "paymentDate": "Payment date",
    "deliveryDate": "Delivery date",
    "status": "Status",
    "emptyTable": "No orders yet",
    "term": "Term",
    "settled": "Settled",
    "allStatuses": "All statuses",
    "filterByStatus": "Filter by status",
    "modal": {
      "confirmationQuestion": "Are you sure you want to {markOption} on the Client Portal?",
      "markSettled": "mark this order as settled",
      "markUnsettled": "mark this order as unsettled",
      "confirmationTip": "This will {action} the clients’ order volume amount.",
      "increase": "increase",
      "reduce": "reduce"
    },
    "tooltip": "See your EUA orders' status, payment date, and invoicing date.",
    "createdAt": "Created at",
    "createdBy": "Created by",
    "deleteColumn": "Delete",
    "executionType": "Execution type",
    "market": "Market Order",
    "limit": "Limit Order"
  },
  "InterActionNeeded": {
    "title": "Click somewhere to activate notification sounds",
    "button": "Activate"
  },
  "ProductSelector": {
    "title": "Select products",
    "selectDefaultProducts": "Reset to my subscribed products"
  },
  "ProductRequester": {
    "title": "Get access to these products"
  },
  "EeDeleteOrder": {
    "orderDeleted": "Order deleted succesfully",
    "delete": "Delete",
    "deleteOrderModalTitle": "Delete order",
    "deleteOrderModalSubtitle": "Are you sure you want to delete this order?"
  },
  "EeOrderDetails": {
    "routeTitle": "Order Details",
    "title": "Order Details",
    "order": "Order Number",
    "type": "Buy/Sell",
    "status": "Status",
    "term": "Term",
    "registry": "Delivery Registry",
    "volume": "Certificates",
    "price": "Price",
    "totalAmount": "Total",
    "orderedBy": "Ordered By",
    "expiresAt": "Good until date",
    "clientCompany": "Client Company",
    "paymentDate": "Payment Date",
    "deliveryDate": "Delivery Date",
    "createdAt": "Created At",
    "createdBy": "Created By",
    "isPaid": "Payment Status",
    "settled": "Settled",
    "unsettled": "Unsettled",
    "orderType": "Order Type",
    "asset": "Asset",
    "buy": "Buy",
    "sell": "Sell",
    "limit": "Limit Order",
    "market": "Market Order",
    "overview": "Overview",
    "delivery": "Delivery",
    "cancelOrder": "Cancel Order"
  },
  "BaseModal": {
    "declineBtn": "Cancel",
    "acceptBtn": "Confirm"
  },
  "BaseListOverview": {
    "orderType": "Order type",
    "commodity": "Commodity",
    "registry": "Registry",
    "term": "Term",
    "paymentDate": "Payment date",
    "deliveryDate": "Delivery date",
    "volume": "Volume",
    "price": "Price",
    "total": "Total",
    "expiry": "Good until date",
    "limitPrice": "Limit price",
    "totalLimitOrder": "Total limit order"
  },
  "EeReviewOrderMarket": {
    "title": "Review market order"
  },
  "EeOrderContainer": {
    "selectOrderType": "Order type",
    "limitOrder": "Limit order",
    "marketOrder": "Market order",
    "orderType": "orderType",
    "commodity": "commodity",
    "registry": "registry",
    "term": "term",
    "paymentDate": "paymentDate",
    "deliveryDate": "deliveryDate",
    "volume": "volume",
    "price": "price",
    "total": "total",
    "quotes": "Quotes",
    "details": "Details",
    "review": "Review"
  },
  "EeOrderBook": {
    "title": "Order Book",
    "actions": "Actions",
    "orderNumber": "Order #",
    "client": "Client",
    "datetime": "Datetime",
    "placed": "Placed",
    "term": "Term",
    "stat": "Stat",
    "exp": "Exp",
    "vol": "Vol",
    "bid": "Bid",
    "ask": "Ask",
    "roll": "Roll",
    "dec": "Dec",
    "buyOrders": "Buy Orders",
    "sellOrders": "Sell Orders",
    "emptyTable": "No orders available",
    "gap": "Gap"
  },
  "EeOpenOrders": {
    "title": "Open Orders",
    "orderNumber": "Order #",
    "status": "Status",
    "buySell": "Buy/Sell",
    "buy": "Buy",
    "sell": "Sell",
    "term": "Term",
    "clientCompany": "Client",
    "totalAmountCertificates": "Total amount of certificates",
    "certificatePrice": "Limit price",
    "totalNotional": "Total notional amount",
    "paymentDate": "Payment date",
    "deliveryDate": "Delivery date",
    "expiresAt": "Good until date",
    "createdAt": "Created at",
    "createdBy": "Created by",
    "emptyTable": "No orders yet",
    "tooltip": "See open orders.",
    "cancelColumn": "Cancel"
  },
  "RollInputs": {
    "title": "Roll Inputs",
    "saveInputs": "Save Inputs",
    "saveSuccessNotification": "Roll inputs have been saved succesfully!"
  },
  "DecExpiration": {
    "title": "Dec Expiration",
    "expirationDate": "Expiration Date",
    "daysLeft": "{days} days until Dec Expiration"
  },
  "YearlyInterest": {
    "title": "Yearly Interest"
  },
  "EeCreateLimitOrder": {
    "buyLimitTitle": "Buy limit order",
    "sellLimitTitle": "Sell limit order",
    "limitPrice": "Limit price",
    "expiresAt": "Good until date",
    "totalAmount": "Total Amount",
    "reviewOrder": "Review order",
    "volume": "Volume",
    "tooltipNotCompleteForm": "Fill in all required fields",
    "invalidFields": "To create a Limit order you should fill in all fields correctly."
  },
  "EeReviewLimitOrder": {
    "placeLimitOrder": "Place order",
    "buyLimitTitle": "Buy limit order",
    "sellLimitTitle": "Sell limit order",
    "failedOrder": "Creating limit order failed",
    "doneOrder": "Succesfully created limit order",
    "invalidLimitPriceIsBuy": "Your limit order is above market price. Change your limit price or place a market order.",
    "invalidLimitPriceIsSell": "Your limit order is below market price. Change your limit price or place a market order."
  },
  "EeFillOrder": {
    "fillOrderModalTitle": "Fill order",
    "fillOrderModalSubtitle": "Are you sure you want to fill this order?",
    "fill": "Fill",
    "orderFilled": "Order has been filled successfully"
  },
  "EeCancelOrder": {
    "requestCancelOrderModalTitle": "Cancel order",
    "requestCancelOrderModalSubtitle": "Are you sure you want to cancel this order?",
    "confirmCancelOrderModalTitle": "Confirm cancel order",
    "confirmCancelOrderModalSubtitle": "Are you sure you want confirm the cancellation of this order?",
    "requestCancel": "Cancel",
    "remove": "Remove",
    "orderCancellationRequested": "Order has been cancelled successfully",
    "orderCancelled": "Order has been cancelled successfully"
  },
  "ManualTrades": {
    "title": "Trades",
    "emptyTable": "No trades available",
    "edit": "Edit",
    "validationErrors": "There are errors in the form",
    "createSuccess": "successfully created trade",
    "updateSuccess": "Successfully edited trade",
    "spot": "Spot",
    "forward": "Forward",
    "date": "Deal date",
    "type": "Type",
    "counterparty": "Counter party",
    "quantity": "Quantity",
    "price": "Price",
    "deliveryDate": "Delivery date",
    "editTrade": "Edit trade",
    "addTrade": "Add trade",
    "dealDate": "Deal date",
    "amount": "Amount",
    "update": "Update",
    "submit": "Submit",
    "actions": "Actions"
  },
  "DeleteManualTrade": {
    "delete": "Delete",
    "deleteOrderModalTitle": "Delete trade",
    "deleteOrderModalSubtitle": "Are you sure you want to delete this trade?",
    "orderDeleted": "Trade has been deleted"
  }
}

import { NotificationsItemModel } from '@/types/notification';
import { getTranslationTerms } from './localeHelper';
import { MAIN_ROUTES, USER_ROLE_ROUTES } from '@/config/constants/routes';
import { getProfileLink } from '@/utils/composable/accountLink';
import { useUserStore } from '@/store';
import { UserRole } from '@/types/user';
import { capitalizeFirstLetter } from '@/utils/composable/globalHelpers';
import { t } from '@/utils/composable/localeHelper';

const getNotificationsTerms = getTranslationTerms.bind(null, 'pages', 'pushNotifications');

export const getNotificationDatestring = (dateString?: string): string => {
  if (!dateString) return '';
  const seconds = Math.floor((+new Date() - +new Date(dateString)) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return `${Math.floor(interval)} ${
      interval < 2
        ? getNotificationsTerms('dateStrings.year')
        : getNotificationsTerms('dateStrings.years')
    }`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)} ${
      interval < 2
        ? getNotificationsTerms('dateStrings.month')
        : getNotificationsTerms('dateStrings.months')
    }`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)} ${
      interval < 2
        ? getNotificationsTerms('dateStrings.day')
        : getNotificationsTerms('dateStrings.days')
    }`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `${Math.floor(interval)} ${
      interval < 2
        ? getNotificationsTerms('dateStrings.hour')
        : getNotificationsTerms('dateStrings.hours')
    }`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)} ${
      interval < 2
        ? getNotificationsTerms('dateStrings.minute')
        : getNotificationsTerms('dateStrings.minutes')
    }`;
  }
  return `${Math.floor(seconds)} ${getNotificationsTerms('dateStrings.seconds')}`;
};

// Many different payloads and BE does not provide any types so any required
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const getFormattedNotificationItem = (notification?: any): NotificationsItemModel | null => {
  const date = notification?.occurred_at
    ? getNotificationDatestring(notification.occurred_at)
    : getNotificationsTerms('dateStrings.now');
  const isReaded = !!notification?.accountNotifications?.edges?.[0]?.node?.read;
  if (notification?.notificationType === 'ACCOUNT_SIGN_UP') {
    const accountName = notification.processedPayload?.account?.name || '';
    const accountId = notification.processedPayload?.account?.id || '';
    const message = getNotificationsTerms('accountSignUp.message', { accountName });
    return {
      id: notification.id,
      title: getNotificationsTerms('accountSignUp.title'),
      shortMessage: message.length > 45 ? `${message.slice(0, 42)}...` : message,
      message,
      isLink: true,
      linkText: getNotificationsTerms('accountSignUp.linkText'),
      link: getProfileLink(
        accountId,
        '/api/accounts/',
        `${USER_ROLE_ROUTES.SUSTAINABILITY_ADVISOR_PROFILE.path}/`,
      ),
      date,
      isReaded,
      noClientView: true,
      occurred_at: notification.occurred_at,
    };
  }
  if (notification?.notificationType === 'CLIENT_COMPANY_CREATED') {
    const accountName = notification.processedPayload?.creator?.name || '';
    const companyName = notification.processedPayload?.company?.name || '';
    const companyId = notification.processedPayload?.company?.id || '';
    const message = getNotificationsTerms('clientCompanyCreated.message', {
      companyName,
      accountName,
    });

    return {
      id: notification.id,
      title: getNotificationsTerms('clientCompanyCreated.title'),
      shortMessage: message.length > 45 ? `${message.slice(0, 42)}...` : message,
      message,
      isLink: true,
      linkText: getNotificationsTerms('clientCompanyCreated.linkText'),
      link: getProfileLink(companyId, '/api/client', USER_ROLE_ROUTES.CLIENT_COMPANY_PROFILE.path),
      date,
      isReaded,
      noClientView: true,
      occurred_at: notification.occurred_at,
    };
  }

  if (notification?.notificationType === 'SUB_ENTITY_EDITED') {
    const accountName = notification.processedPayload?.account?.name || '';
    const companyName = notification.processedPayload?.company?.name || '';
    const subentityName = notification.processedPayload?.subEntity?.name || '';
    const subentityId = notification.processedPayload?.subEntity?.id || '';
    const message = getNotificationsTerms('subEntityEdited.message', {
      accountName,
      subentityName,
      companyName,
    });

    return {
      id: notification.id,
      title: getNotificationsTerms('subEntityEdited.title'),
      shortMessage: message.length > 45 ? `${message.slice(0, 42)}...` : message,
      message,
      isLink: true,
      linkText: getNotificationsTerms('subEntityEdited.linkText'),
      link: getProfileLink(
        subentityId,
        '/api/sub_entities/',
        `${USER_ROLE_ROUTES.CLIENTS_SUBENTITY_DETAILS.path}/`,
      ),
      date,
      isReaded,
      noClientView: true,
      occurred_at: notification.occurred_at,
    };
  }
  if (notification?.notificationType === 'RFQ_CREATED') {
    const accountName = notification.processedPayload?.creator?.name || '';
    const companyName = notification.processedPayload?.company?.name || '';
    const productAbbr = notification.processedPayload?.product?.abbreviation || '';
    const rfqId = notification.processedPayload?.rfq?.id.split('/').splice(-1).toString() || '';
    const message = getNotificationsTerms('rfqCreated.message', { accountName, companyName });
    return {
      id: notification.id,
      title: getNotificationsTerms('rfqCreated.title'),
      shortMessage: message.length > 45 ? `${message.slice(0, 42)}...` : message,
      message,
      isLink: true,
      linkText: getNotificationsTerms('rfqCreated.linkText'),
      link: MAIN_ROUTES.RFQ_DETAILS.path
        .replace(':productSlug', productAbbr.toLocaleLowerCase())
        .replace(':id', rfqId),
      date,
      isReaded,
      noClientView: true,
      occurred_at: notification.occurred_at,
    };
  }
  if (notification?.notificationType === 'CLIENT_COMPANY_SUBMITTED_FOR_APPROVAL') {
    const companyName = notification.processedPayload?.company?.name || '';
    const companyId =
      notification.processedPayload?.company?.id.split('/').splice(-1).toString() || '';
    const message = getNotificationsTerms('clientCompanySubmittedForApproval.message', {
      companyName,
    });

    return {
      type: 'awatingApproval',
      id: notification.id,
      title: getNotificationsTerms('clientCompanySubmittedForApproval.title'),
      shortMessage: message.length > 45 ? `${message.slice(0, 42)}...` : message,
      message,
      isLink: true,
      linkText: getNotificationsTerms('clientCompanySubmittedForApproval.linkText'),
      link: USER_ROLE_ROUTES.APPROVE_CLIENT.path.replace(':companyId', companyId),
      date,
      isReaded,
      noClientView: true,
      occurred_at: notification.occurred_at,
      companyId,
    };
  }
  if (notification?.notificationType === 'ACCOUNT_INVITED') {
    const accountName = notification.processedPayload?.account?.name || '';
    const companyName = notification.processedPayload?.clientCompany?.name || '';
    const message = getNotificationsTerms('accountInvited.message', { accountName, companyName });
    const invitedId = notification?.processedPayload?.account.id || '';

    return {
      id: notification.id,
      title: getNotificationsTerms('accountInvited.title'),
      shortMessage: message.length > 45 ? `${message.slice(0, 42)}...` : message,
      message,
      isLink: true,
      linkText: getNotificationsTerms('accountInvited.linkText'),
      link: getProfileLink(invitedId, '/api/accounts', USER_ROLE_ROUTES.CLIENT_PROFILE.path),
      date,
      isReaded,
      noClientView: true,
      occurred_at: notification.occurred_at,
    };
  }
  if (notification?.notificationType === 'CLIENT_USER_ADDED') {
    const inviteeName = notification.processedPayload?.invitee?.name || '';
    const saName = notification.processedPayload?.sustainabilityAdvisor?.name || '';
    const inivteeId = notification.processedPayload?.invitee?.id || '';
    const companyName = notification.processedPayload?.company?.name || '';
    const message = getNotificationsTerms('clientUserAdded.message', {
      saName,
      companyName,
      inviteeName,
    });

    return {
      id: notification.id,
      title: getNotificationsTerms('clientUserAdded.title'),
      shortMessage: message.length > 45 ? `${message.slice(0, 42)}...` : message,
      message,
      isLink: true,
      linkText: getNotificationsTerms('clientUserAdded.linkText'),
      link: getProfileLink(inivteeId, '/api/accounts', USER_ROLE_ROUTES.CLIENT_PROFILE.path),
      date,
      isReaded,
      noClientView: true,
      occurred_at: notification.occurred_at,
    };
  }

  if (notification?.notificationType === 'CLIENT_COMPANY_EDITED') {
    const accountName = notification.processedPayload?.account?.name || '';
    const companyId = notification.processedPayload?.clientCompany?.id || '';
    const companyName = notification.processedPayload?.clientCompany?.name || '';
    const message = getNotificationsTerms('clientCompanyEdited.message', {
      companyName,
      accountName,
    });

    return {
      id: notification.id,
      title: getNotificationsTerms('clientCompanyEdited.title'),
      shortMessage: message.length > 45 ? `${message.slice(0, 42)}...` : message,
      message,
      isLink: true,
      linkText: getNotificationsTerms('clientCompanyEdited.linkText'),
      link: getProfileLink(companyId, '/api/client', USER_ROLE_ROUTES.CLIENT_COMPANY_PROFILE.path),
      date,
      isReaded,
      noClientView: true,
      occurred_at: notification.occurred_at,
    };
  }

  if (notification?.notificationType === 'IOI_SUBMITTED') {
    const companyName = notification?.processedPayload?.clientCompany?.name || '';
    const message = getNotificationsTerms('ioiSubmitted.message');

    return {
      id: notification.id,
      title: getNotificationsTerms('ioiSubmitted.title', {
        companyName,
      }),
      shortMessage: message.length > 45 ? `${message.slice(0, 42)}...` : message,
      message,
      isLink: true,
      linkText: getNotificationsTerms('ioiSubmitted.linkText'),
      link: `/eu_ets/trading-platform/indication-of-interest/${notification.processedPayload.indicationOfInterest.id.replace('/api/iois/', '')}`,
      date,
      isReaded,
      noClientView: true,
      occurred_at: notification.occurred_at,
    };
  }

  if (notification?.notificationType === 'OFFER_REJECTED') {
    const companyName = notification?.processedPayload?.clientCompany?.name || '';
    const product = notification?.processedPayload?.product?.name || '';
    const message = getNotificationsTerms('offerRejected.message');

    return {
      id: notification.id,
      title: getNotificationsTerms('offerRejected.title', {
        companyName,
        product,
      }),
      shortMessage: message.length > 45 ? `${message.slice(0, 42)}...` : message,
      message,
      isLink: true,
      linkText: getNotificationsTerms('offerRejected.linkText'),
      link: `/eu_ets/trading-platform/indication-of-interest/${notification.processedPayload.indicationOfInterest.id.replace('/api/iois/', '')}`,
      date,
      isReaded,
      noClientView: true,
      occurred_at: notification.occurred_at,
    };
  }

  if (notification?.notificationType === 'OFFER_ACCEPTED') {
    const companyName = notification?.processedPayload?.clientCompany?.name || '';
    const product = notification?.processedPayload?.product?.name || '';
    const message = getNotificationsTerms('offerAccepted.message');

    return {
      id: notification.id,
      title: getNotificationsTerms('offerAccepted.title', {
        companyName,
        product,
      }),
      shortMessage: message.length > 45 ? `${message.slice(0, 42)}...` : message,
      message,
      isLink: true,
      chatLink: true,
      linkText: getNotificationsTerms('offerAccepted.linkText'),
      link: `/eu_ets/trading-platform/indication-of-interest/${notification.processedPayload.indicationOfInterest.id.replace('/api/iois/', '')}`,
      date,
      isReaded,
      noClientView: true,
      occurred_at: notification.occurred_at,
    };
  }

  if (notification?.notificationType === 'PRICE_REJECTED') {
    const userStore = useUserStore();
    const companyName = notification?.processedPayload?.clientCompany?.name || '';
    const product = notification?.processedPayload?.product?.name || '';

    if (userStore.getUserRole === UserRole.ROLE_COMPANY_REPRESENTATIVE) {
      const message = getNotificationsTerms('priceRejected.message', {
        orderNumber: notification.processedPayload.order.number,
        product,
        companyName,
        type: notification.processedPayload.order.type,
      });
      return {
        id: notification.id,
        title: getNotificationsTerms('priceRejected.title', {
          type: capitalizeFirstLetter(notification.processedPayload.order.type),
        }),
        shortMessage: message.length > 45 ? `${message.slice(0, 42)}...` : message,
        message,
        isLink: true,
        chatLink: true,
        linkText: getNotificationsTerms('priceRejected.linkText'),
        link: `/`,
        date,
        isReaded,
        noClientView: true,
        occurred_at: notification.occurred_at,
      };
    }

    // if SA
    const messageSa = getNotificationsTerms('priceRejected.messageSa', {
      orderNumber: notification.processedPayload.order.number,
      product,
      companyName,
      companyRepresentative: notification.processedPayload.account.name,
      type: notification.processedPayload.order.type,
    });
    return {
      id: notification.id,
      title: getNotificationsTerms('priceRejected.title', {
        type: capitalizeFirstLetter(notification.processedPayload.order.type),
      }),
      shortMessage: messageSa.length > 45 ? `${messageSa.slice(0, 42)}...` : messageSa,
      message: messageSa,
      isLink: false,
      linkText: '',
      link: `/`,
      date,
      isReaded,
      noClientView: true,
      occurred_at: notification.occurred_at,
    };
  }

  if (notification?.notificationType === 'PRICE_ACCEPTED') {
    const userStore = useUserStore();
    const companyName = notification?.processedPayload?.clientCompany?.name || '';
    const product = notification?.processedPayload?.product?.name || '';
    const orderType = notification.processedPayload.order.type;
    const orderNumber = notification.processedPayload.order.number;
    const companyRepresentative = notification.processedPayload.account.name;
    const { executionType } = notification.processedPayload.order;

    const messageKey = (() => {
      if (executionType === 'market') {
        return userStore.isClientRole
          ? 'priceAcceptedMarketOrder.message'
          : 'priceAcceptedMarketOrder.messageSa';
      }
      return userStore.isClientRole
        ? 'priceAcceptedLimitOrder.message'
        : 'priceAcceptedLimitOrder.messageSa';
    })();

    const titleKey =
      executionType === 'market'
        ? 'priceAcceptedMarketOrder.title'
        : 'priceAcceptedLimitOrder.title';

    const linkTextKey =
      executionType === 'market'
        ? 'priceAcceptedMarketOrder.linkText'
        : 'priceAcceptedLimitOrder.linkText';

    const link = userStore.isClientRole
      ? ''
      : MAIN_ROUTES.ENERGY_EXCHANGE_MANAGEMENT.path.replace(':productSlug', 'eu_ets');

    const message = t(`pages.pushNotifications.${messageKey}`, {
      orderNumber,
      product,
      companyName,
      companyRepresentative,
      type: orderType,
    });

    return {
      id: notification.id,
      title: t(`pages.pushNotifications.${titleKey}`, {
        type: capitalizeFirstLetter(orderType),
      }),
      shortMessage: message.length > 45 ? `${message.slice(0, 42)}...` : message,
      message,
      isLink: !userStore.isClientRole,
      linkText: t(`pages.pushNotifications.${linkTextKey}`),
      link,
      date,
      isReaded,
      noClientView: true,
      occurred_at: notification.occurred_at,
    };
  }

  if (notification?.notificationType === 'ORDER_REQUESTED_TO_CANCEL') {
    const companyName = notification?.processedPayload?.clientCompany?.name || '';
    const orderNumber = notification.processedPayload.order.number;
    const product = notification?.processedPayload?.product?.name || '';
    const companyRepresentative = notification.processedPayload.account.name;

    const message = t('pages.pushNotifications.orderRequestedToCancel.message', {
      companyRepresentative,
      companyName,
      orderNumber,
      product,
    });

    return {
      id: notification.id,
      title: t('pages.pushNotifications.orderRequestedToCancel.title'),
      shortMessage: message.length > 45 ? `${message.slice(0, 42)}...` : message,
      message,
      isLink: false,
      linkText: '',
      link: '',
      date,
      isReaded,
      noClientView: true,
      occurred_at: notification.occurred_at,
    };
  }

  if (notification?.notificationType === 'ORDER_CANCELED') {
    const userStore = useUserStore();
    const companyName = notification?.processedPayload?.clientCompany?.name || '';
    const orderNumber = notification.processedPayload.order.number;

    const message = userStore.isClientRole
      ? t('pages.pushNotifications.orderCanceled.message', {
          orderNumber,
        })
      : t('pages.pushNotifications.orderCanceled.messageSa', {
          orderNumber,
          companyName,
        });

    return {
      id: notification.id,
      title: t('pages.pushNotifications.orderCanceled.title'),
      shortMessage: message.length > 45 ? `${message.slice(0, 42)}...` : message,
      message,
      isLink: false,
      linkText: '',
      link: '',
      date,
      isReaded,
      noClientView: true,
      occurred_at: notification.occurred_at,
    };
  }

  if (notification?.notificationType === 'ORDER_FILLED') {
    const userStore = useUserStore();
    const companyName = notification?.processedPayload?.clientCompany?.name || '';
    const orderNumber = notification.processedPayload.order.number;

    const message = userStore.isClientRole
      ? t('pages.pushNotifications.orderFilled.message', {
          orderNumber,
        })
      : t('pages.pushNotifications.orderFilled.messageSa', {
          orderNumber,
          companyName,
        });

    return {
      id: notification.id,
      title: t('pages.pushNotifications.orderFilled.title'),
      shortMessage: message.length > 45 ? `${message.slice(0, 42)}...` : message,
      message,
      isLink: false,
      linkText: '',
      link: '',
      date,
      isReaded,
      noClientView: true,
      occurred_at: notification.occurred_at,
    };
  }

  if (notification?.notificationType === 'USER_OPENED_EXCHANGE') {
    const messageSa = t('pages.pushNotifications.userOpenedExchange.messageSa', {
      companyRepresentative: notification.processedPayload.account.name,
      companyName: notification.processedPayload.company.name,
    });

    return {
      id: notification.id,
      title: t('pages.pushNotifications.userOpenedExchange.title'),
      shortMessage: messageSa.length > 45 ? `${messageSa.slice(0, 42)}...` : messageSa,
      message: messageSa,
      isLink: true,
      linkText: t('pages.pushNotifications.userOpenedExchange.linkText'),
      link: MAIN_ROUTES.ENERGY_EXCHANGE_MANAGEMENT.path.replace(':productSlug', 'eu_ets'),
      date,
      isReaded,
      noClientView: true,
      occurred_at: notification.occurred_at,
    };
  }
  return null;
};

export const isAllowedNotificationType = (type: string): boolean => {
  const userStore = useUserStore();
  const allowedNotifications = [
    'CLIENT_COMPANY_EDITED',
    'CLIENT_USER_ADDED',
    'ACCOUNT_INVITED',
    'CLIENT_COMPANY_SUBMITTED_FOR_APPROVAL',
    'RFQ_CREATED',
    'SUB_ENTITY_EDITED',
    'CLIENT_COMPANY_CREATED',
    'ACCOUNT_SIGN_UP',
    'IOI_SUBMITTED',
    'OFFER_REJECTED',
    'OFFER_ACCEPTED',
    'PRICE_ACCEPTED',
    'PRICE_REJECTED',
    'USER_OPENED_EXCHANGE',
    'ORDER_FILLED',
  ];

  if (userStore.isSARole) {
    allowedNotifications.push('ORDER_REQUESTED_TO_CANCEL', 'ORDER_CANCELED');
  }

  return allowedNotifications.includes(type);
};

import { gql } from '@apollo/client/core';
import { INDICATION_OF_INTEREST_FIELDS } from '@/api/fragments';

export const GET_SINGLE_IOI = gql`
  ${INDICATION_OF_INTEREST_FIELDS}
  query getSingleIOI($id: ID!) @api(name: "authEndPoint") {
    indicationOfInterest(id: $id) {
      ...indicationOfInterestFields
    }
  }
`;

export const GET_IOIS = gql`
  ${INDICATION_OF_INTEREST_FIELDS}
  query getIOIs(
    $page: Int
    $status: String
    $status_list: [String]
    $expiresAt: [IndicationOfInterestFilter_expiresAt]
    $isExpired: Boolean
  ) @api(name: "authEndPoint") {
    indicationOfInterests(
      page: $page
      status: $status
      status_list: $status_list
      expiresAt: $expiresAt
      isExpired: $isExpired
    ) {
      collection {
        ...indicationOfInterestFields
      }
      paginationInfo {
        itemsPerPage
        lastPage
        totalCount
      }
    }
  }
`;

export const CREATE_API_KEY = gql`
  mutation createApiKey($input: createApiKeyInput!) @api(name: "authEndPoint") {
    createApiKey(input: $input) {
      apiKey {
        apiKey
        createdAt
        expiresAt
        id
        requestsPerSecond
        startsWith
      }
    }
  }
`;

export const CREATE_OFFER = gql`
  mutation CreateOffer($input: createOfferInput!) @api(name: "authEndPoint") {
    createOffer(input: $input) {
      offer {
        id
        price
      }
    }
  }
`;

export const GET_API_KEYS = gql`
  query getApiKeys @api(name: "authEndPoint") {
    apiKeys {
      edges {
        node {
          createdAt
          expiresAt
          id
        }
      }
    }
  }
`;

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { logErrorMessages } from '@vue/apollo-util';
import { useRouter } from 'vue-router';
import { getTranslationTerms } from '@/utils/composable/localeHelper';
import ClickButton from '@/components/base/ClickButton.vue';
import ButtonLoader from '@/components/base/ButtonLoader.vue';
import { MAIN_ROUTES } from '@/config/constants/routes';
import { useMarkAsReadNotificationMutation } from '@/types/graphql';
import NotificationsItem from '@/views/notificationsModule/NotificationsItem.vue';
import { usePushNotificationsStore, useUserStore } from '@/store';
import {
  notificationWindowOpen,
  toggleNotificationWindow,
} from '@/utils/composable/topNavigationBar';
import { ViewType } from '@/types/user';

export default defineComponent({
  name: 'NotificationsDropdown',
  components: { ClickButton, NotificationsItem, ButtonLoader },
  emits: ['on-unreaded-check'],
  setup() {
    const getNotificationsTerms = getTranslationTerms.bind(null, 'pages', 'pushNotifications');
    const pushNotificationStore = usePushNotificationsStore();
    const router = useRouter();
    const userStore = useUserStore();
    const markIsLoading = ref(false);

    const notificationsList = computed(() => pushNotificationStore.getNotifications);
    const notificationsResultLength = computed(() => pushNotificationStore.getNotifications.length);
    const unreadCount = computed(() => pushNotificationStore.getUnreadMessagesCounts);

    const markAsRead = async (id: string) => {
      const { mutate, onDone, onError } = useMarkAsReadNotificationMutation({
        variables: {
          input: {
            id,
          },
        },
      });

      onDone(() => {
        pushNotificationStore.fetchAndSetNotifications(1);
      });

      onError(async (error) => {
        logErrorMessages(error);
        markIsLoading.value = false;
      });

      await mutate();
    };

    const markAsReadAll = () => {
      const notReadedNotifications = notificationsList.value.filter((item) => !item?.isReaded);
      if (notReadedNotifications.length) {
        notReadedNotifications.forEach((item) => {
          if (item?.id) {
            markIsLoading.value = true;
            markAsRead(item.id);
          }
        });
      }
    };

    const closeDropdown = () => {
      toggleNotificationWindow();
    };

    const closeDropdownOutside = (element: HTMLElement) => {
      if (document.querySelector('#notifications-bell-button')?.contains(element)) {
        return;
      }
      toggleNotificationWindow();
    };

    const onLinkClick = (event: { id: string; link: string; noClientView: boolean }) => {
      markAsRead(event.id);
      closeDropdown();

      if (event.noClientView && userStore.getClientCompanyId) {
        userStore.removeClientUserRole();
        userStore.setViewType(ViewType.PERSONAL);
        userStore.setViewCompany(null);
      }

      router.push(event.link);
    };

    return {
      getNotificationsTerms,
      notificationsList,
      notificationsResultLength,
      unreadCount,
      MAIN_ROUTES,
      markAsRead,
      markAsReadAll,
      closeDropdown,
      markIsLoading,
      onLinkClick,
      router,
      notificationWindowOpen,
      closeDropdownOutside,
    };
  },
});
</script>

<template>
  <div class="notification-dropdown" data-unit-test="notification-dropdown">
    <div v-click-outside="closeDropdownOutside" class="notification-dropdown__container">
      <div v-if="notificationsList.length" class="notification-dropdown__header">
        <p class="notification-dropdown__unread-count">
          {{ unreadCount }} {{ getNotificationsTerms('unread') }}
        </p>
        <ButtonLoader v-if="markIsLoading" class="notification-dropdown__read-loader" />
        <p v-else class="notification-dropdown__read-action" @click="markAsReadAll">
          {{ getNotificationsTerms('markAsRead') }}
        </p>
      </div>
      <div class="notification-dropdown__body nice-scroll">
        <NotificationsItem
          v-for="notification in notificationsList"
          :key="notification?.id"
          :show-short-message="false"
          :in-dropdown="true"
          class="notification-dropdown__item"
          :notification-data="notification"
          @on-link-click="onLinkClick"
        />
        <div v-if="!notificationsList.length" class="notification-dropdown__not-found">
          {{ getNotificationsTerms('notFoundMessage') }}
        </div>
      </div>
      <div v-if="notificationsResultLength > 14" class="notification-dropdown__button-box">
        <ClickButton
          status="outlined-primary"
          :router-link="MAIN_ROUTES.NOTIFICATIONS.path"
          data-cy="ViewOlderNotifications"
          @click="closeDropdown"
        >
          {{ getNotificationsTerms('viewOlderNotifications') }}
        </ClickButton>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.notification-dropdown {
  &__container {
    width: remCalc(320);
    position: absolute;
    top: calc(var(--top-nav-height) + remCalc(10));
    right: 1rem;
    padding: var(--space-xs);
    background-color: var(--color-shade-8);
    border-radius: var(--border-radius-big);
    bottom: 60px;
    height: fit-content;
    z-index: 1000;
    box-shadow: 0px 4px 4px rgba(78, 23, 7, 0.3);
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--space-5xs);
  }

  &__body {
    max-height: remCalc(400);
    overflow-y: auto;
  }
  &__unread-count {
    @extend %font-form-body;
  }
  &__read-action {
    @extend %font-input;
    cursor: pointer;
    text-decoration: underline;
  }
  &__read-loader {
    position: relative;
  }
  :deep(.loader-svg) {
    stroke: var(--color-font);
  }
  &__item {
    margin-top: var(--space-3xs);
  }
  &__not-found {
    @extend %font-alert-title;
    text-align: center;
    color: var(--color-light-grey);
  }
  &__button-box {
    display: flex;
    justify-content: center;
    padding-top: var(--space-3xs);
    margin-top: var(--space-3xs);
  }
}
</style>

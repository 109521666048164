<script lang="ts">
import { computed, defineComponent, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';
import { getTranslationTerms } from '@/utils/composable/localeHelper';
import { ViewType } from '@/types/user';
import { MAIN_ROUTES } from '@/config/constants/routes';
import { useGlobalStore, useUserStore } from '@/store';
import BaseModal from '@/components/base/BaseModal.vue';
import ClientsList from '@/components/navigation/ClientsList.vue';
import { ClientCompanyClientView } from '@/types/navigation';

export default defineComponent({
  name: 'ViewSwitcher',
  components: { ClientsList, BaseModal },
  props: {
    collapsed: {
      type: Boolean,
      default: true,
    },
    dataCy: { type: String, required: true },
  },
  emits: ['reselect-client'],
  setup(props, ctx) {
    const userState = useUserStore();
    const globalStore = useGlobalStore();
    const clientViewIsChecked = computed(() => userState.getViewType === ViewType.CLIENT);
    const cypressDataAttribute = `${getCurrentInstance()?.type.name}-${props?.dataCy}`;
    const getComponentTerms = getTranslationTerms.bind(null, 'base', 'viewSwitcher');
    const { setViewType, setViewCompany, replaceDefaultDashboard } = userState;
    const { push } = useRouter();

    const updateViewType = async (event: InputEvent) => {
      const viewType = (event.target as HTMLInputElement).checked
        ? ViewType.CLIENT
        : ViewType.PERSONAL;
      setViewType(viewType);

      if (viewType === ViewType.PERSONAL) {
        setViewCompany(null);
        await push({ name: MAIN_ROUTES.DASHBOARD.name });
      } else {
        globalStore.toggleClientViewSelectionModal();
      }
    };

    const onClientSelect = async (
      company: ClientCompanyClientView,
      defaultDashboardGroup: string,
    ) => {
      setViewCompany(company);
      replaceDefaultDashboard(defaultDashboardGroup);
      globalStore.toggleClientViewSelectionModal();
      await push({ name: MAIN_ROUTES.DASHBOARD.name });
    };

    const reselectClient = () => {
      ctx.emit('reselect-client');
    };

    const handleCloseModal = () => {
      globalStore.toggleClientViewSelectionModal();

      if (!userState.getViewCompany) {
        setViewType(ViewType.PERSONAL);
      }
    };

    return {
      userState,
      globalStore,
      cypressDataAttribute,
      reselectClient,
      clientViewIsChecked,
      getComponentTerms,
      updateViewType,
      onClientSelect,
      handleCloseModal,
    };
  },
});
</script>

<template>
  <div class="view-switcher">
    <label class="view-switcher__container" :data-cy="cypressDataAttribute">
      <input
        class="view-switcher__input"
        :data-cy="`${cypressDataAttribute}Input`"
        type="checkbox"
        :checked="clientViewIsChecked"
        @change="updateViewType"
      />
      <span
        v-if="collapsed"
        :data-cy="`${cypressDataAttribute}Collapsed`"
        class="view-switcher__switch-base"
      >
        <span class="view-switcher__switch-wrap">
          <span class="view-switcher__switch">
            {{
              clientViewIsChecked
                ? getComponentTerms('client')[0]
                : getComponentTerms('personalAbbr')[0]
            }}
          </span>
        </span>
      </span>
      <div v-else :data-cy="`${cypressDataAttribute}Wide`" class="view-switcher__switch-text">
        <span class="view-switcher__switch-option">{{ getComponentTerms('personal') }}</span>
        <span class="view-switcher__switch-option">
          {{ getComponentTerms('client') }}
        </span>
      </div>
    </label>
  </div>

  <BaseModal
    modal-style="full-size"
    :is-open="globalStore.isClientViewSelectionModalOpen"
    :modal-title="`${$t(`products.product.${userState.getSelectedProduct?.abbr}`)} ${$t(
      'base.clientsList.title',
    )}`"
    :show-buttons="false"
    data-cy="viewswitcherModal"
    @handle-close="handleCloseModal"
  >
    <ClientsList
      :hide-title="true"
      :company="userState.getViewCompany || undefined"
      :filtered-by-status="['approved', 'active']"
      data-cy="viewswitcherModalClientsList"
      @on-client-select="onClientSelect"
    />
  </BaseModal>
</template>

<style lang="scss" scoped>
.clients-list-view-switcher {
  max-height: calc(100% - 4rem);
}

.view-switcher {
  width: 100%;
  margin-bottom: var(--space-3xs);

  &__container {
    position: relative;
    cursor: pointer;
  }

  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  &__input:checked {
    + .view-switcher__switch-base {
      .view-switcher__switch-wrap {
        transform: translateX(100%);
      }
      .view-switcher__switch {
        transform: translate(calc(-100% + 2px), -50%);
      }
    }
    + .view-switcher__switch-text {
      .view-switcher__switch-option {
        &:first-child {
          background-color: transparent;
          color: var(--color-grey);
        }
        &:last-child {
          background-color: var(--color-primary-hover);
          color: var(--color-light);
        }
      }
    }
  }

  &__label {
    margin-left: 8px;
    @extend %font-label;
    color: var(--color-grey);
  }

  &__error {
    margin-top: -20px;
    color: var(--color-error);
  }

  &__switch-base {
    display: flex;
    align-items: center;
    position: relative;
    height: remCalc(14);
    border-radius: 20px;
    background-color: var(--color-shade-6);
    transition: background-color 0.25s ease-in-out;
    overflow: hidden;
  }

  &__switch-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.375s ease-in-out;
  }

  &__switch {
    position: absolute;
    @extend %font-label-title;
    padding: remCalc(2) remCalc(10);
    border-radius: 1rem;
    top: 50%;
    transform: translate(-2px, -50%);
    background-color: var(--color-primary-hover);
    color: var(--color-bg);
    box-shadow: 4px 0 4px rgba(0, 0, 0, 0.25);
    transition: transform 0.375s ease-in-out;
  }

  &__switch-text {
    display: flex;
    border-radius: 16px;
    flex-shrink: 0;
    background-color: var(--color-shade-6);
    animation: slide-animation 0.4s ease-out;

    @keyframes slide-animation {
      0% {
        overflow: hidden;
        max-width: remCalc(60);
      }

      99% {
        overflow: visible;
        max-width: remCalc(220);
      }
      100% {
        overflow: visible;
        width: auto;
      }
    }
  }
  &__switch-option {
    @extend %font-label;
    border-radius: 16px;
    min-width: remCalc(75);
    padding: remCalc(8);
    transition:
      background-color 0.25s ease-in-out,
      color 0.25s ease-in-out;
    text-align: center;
    flex: 1 1 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -1px;

    &:first-child {
      background-color: var(--color-primary-hover);
      color: var(--color-light);
    }
    &:last-child {
      background-color: transparent;
    }
  }

  &__icon {
    margin-left: remCalc(10);
  }
}
</style>

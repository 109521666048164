export const MODULES_LIST = {
  dashboard: 'DASHBOARD',
  docLib: 'DOCUMENT_LIBRARY',
  blog: 'BLOG',
  newsletters: 'NEWSLETTER',
  chat: 'CHAT',
  rfq: 'RFQ',
  calendar: 'CALENDAR',
  transactions: 'TRADES',
  clientDoc: 'CLIENT_DOCUMENTS',
  price: 'PRICE_CHARTS',
  webinars: 'WEBINARS',
  management: 'MANAGEMENT',
  commodityVolumes: 'COMMODITY_VOLUMES',
  energyExchange: 'ENERGY_EXCHANGE',
  energyExchangeClient: 'ENERGY_EXCHANGE_CLIENT',
  energyExchangeManagement: 'ENERGY_EXCHANGE_MANAGEMENT',
  indicationOfInterests: 'INDICATION_OF_INTERESTS',
};

export const MODULES_GROUPS: string[] = [
  'INFORMATION_HUB',
  'TRADE_HISTORY',
  'COMMUNICATION',
  'COMMODITY_MARKET',
  'ENERGY_EXCHANGE',
];

export const MODULES_NAMES = {
  tradeHistory: 'TRADE_HISTORY',
  informationHub: 'INFORMATION_HUB',
  communication: 'COMMUNICATION',
  commodityPrices: 'COMMODITY_MARKET',
  energyExchange: 'ENERGY_EXCHANGE',
};

export const MODULES_NAMES_LIST = {
  dashboard: 'Dashboard',
  docLib: 'Document Library',
  blog: 'Blog',
  newsletters: 'Newsletters',
  chat: 'Chat',
  rfq: 'RFQ',
  calendar: 'Calendar',
  transactions: 'Trades',
  clientDoc: 'Client Documents',
  price: 'Price charts',
  webinars: 'Webinars',
  management: 'Management',
  commodityVolumes: 'Commodity Volumes',
  energyExchange: 'Energy Exchange',
  energyExchangeManagement: 'Energy Exchange Management',
  indicationOfInterests: 'Indication of Interests',
};

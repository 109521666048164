<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Dashboard',
  props: {},
});
</script>

<template>
  <div class="dashboard">
    <router-view />
  </div>
</template>

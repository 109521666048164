<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseAvatar',
  props: {
    avatarUrl: {
      type: String,
      default: '',
    },
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const userInitials = computed(() => {
      const firstChar = props.firstName.charAt(0);
      const lastChar = props.lastName.charAt(0);
      return `${firstChar}${lastChar}`;
    });

    return {
      userInitials,
    };
  },
});
</script>

<template>
  <div class="b-avatar" data-name="avatar">
    <img v-if="avatarUrl" :src="avatarUrl" class="avatar__img" />
    <div v-else class="avatar__text">
      {{ userInitials }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.avatar {
  &__img {
    display: block;
    width: var(--avatar-size, remCalc(32));
    height: var(--avatar-size, remCalc(32));
    border-radius: 50%;
    pointer-events: none;
    user-select: none;
  }
  &__text {
    @extend %font-body;
    flex: 0 0 auto;
    width: var(--avatar-size, remCalc(32));
    height: var(--avatar-size, remCalc(32));
    display: flex;
    border-radius: 50%;
    background: var(--avatar-background-color, var(--color-shade-6));
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    user-select: none;
    color: var(--avatar-text-color);
  }
}
</style>

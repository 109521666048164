import { RouteLocation, RouteLocationRaw } from 'vue-router';
import { NetworkError } from '@apollo/client/errors';
import { storeToRefs } from 'pinia';
import { SelectedProductType, UserRole, UserState, ViewType } from '@/types/user';
import { MAIN_ROUTES } from '@/config/constants/routes';
import { useUserStore } from '@/store';
import { useGetAccountLazyQuery, useProductsLazyQuery } from '@/types/graphql';
import { graphQLErrorHandler, networkErrorHandler } from '@/utils/apiErrorHandler';
import router from '@/router';

export const getAccount = (): Promise<SelectedProductType[] | undefined> =>
  new Promise((resolve) => {
    const userStore = useUserStore();
    const { load, onError, onResult } = useGetAccountLazyQuery(
      {
        id: userStore.userProfile.id as string,
      },
      { fetchPolicy: 'cache-first' },
    );
    onResult((result) => {
      if (!result.loading && result.data?.account) {
        if (userStore.getUserRole === UserRole.ROLE_SUSTAINABILITY_ADVISOR) {
          resolve(
            result.data?.account?.sustainabilityAdvisorProducts?.edges?.map((item) => ({
              id: item?.node?.product?.id ?? '',
              abbr: item?.node?.product?.abbreviation ?? '',
              name: item?.node?.product?.name ?? '',
              isProductManager: item?.node?.isProductManager,
            })),
          );
        }
        if (userStore.getUserRole === UserRole.ROLE_COMPANY_REPRESENTATIVE) {
          const allowedModules: { [key: string]: string[] } = {};
          result.data?.account?.clientCompany?.products?.edges?.forEach((product) => {
            if (product?.node?.product?.id) {
              allowedModules[product.node.product.id] = product.node.allowedModules?.edges
                ? product.node.allowedModules.edges.map((module) => module?.node?.slug ?? '')
                : [];
            }
          });
          resolve(
            result.data?.account?.companyRepresentativeProducts?.edges?.map((item) => ({
              id: item?.node?.id ?? '',
              abbr: item?.node?.abbreviation ?? '',
              name: item?.node?.name ?? '',
              isProductManager: false,
              allowedModules: allowedModules[item?.node?.id ?? ''] ?? [],
            })),
          );
        }
      }
    });

    onError(async (error) => {
      if (error && (error?.networkError as NetworkError)) {
        networkErrorHandler();
      }
      graphQLErrorHandler(error?.graphQLErrors);
      resolve([]);
    });
    load();
  });

export const getProducts = (): Promise<SelectedProductType[] | undefined> =>
  new Promise((resolve) => {
    const { onResult, onError, load } = useProductsLazyQuery({
      fetchPolicy: 'cache-and-network',
    });
    onResult((result) => {
      if (!result.loading) {
        if (result.data.products?.edges) {
          resolve(
            result.data.products?.edges.map((item) => ({
              id: item?.node?.id ?? '',
              abbr: item?.node?.abbreviation ?? '',
              name: item?.node?.name ?? '',
            })),
          );
        }
        resolve([]);
      }
    });

    onError(async (error) => {
      if (error && (error?.networkError as NetworkError)) {
        networkErrorHandler();
      }
      graphQLErrorHandler(error?.graphQLErrors);
      resolve([]);
    });
    load();
  });

export function extractSlugFromUrl(url: string): string | null {
  const parts = url.split('/');
  const dashboardIndex = parts.indexOf('dashboard');

  if (dashboardIndex !== -1 && dashboardIndex + 1 < parts.length) {
    return parts[dashboardIndex + 1];
  }

  return null;
}

export const productSwitcherFromString = async (
  productSlug: string,
): Promise<boolean | RouteLocationRaw> => {
  const userStore = useUserStore();
  if (productSlug && productSlug !== userStore.selectedProduct?.abbr.toLocaleLowerCase()) {
    if (userStore.getUserRole !== UserRole.ROLE_BUSINESS_SUPPORT) {
      const products = await getAccount();
      const newSelectedProduct = products?.filter(
        (item) => item.abbr.toLowerCase() === productSlug,
      )[0];
      if (newSelectedProduct) {
        userStore.setSelectedProduct(newSelectedProduct);
        return true;
      }
    } else {
      const products = await getProducts();
      const newSelectedProduct = products?.filter(
        (item) => item.abbr.toLowerCase() === productSlug,
      )[0];
      if (newSelectedProduct) {
        userStore.setSelectedProduct(newSelectedProduct);
        return true;
      }
    }
    return { name: MAIN_ROUTES.NOT_FOUND.name };
  }
  return true;
};

export const productSwitcher = async (to: RouteLocation): Promise<boolean | RouteLocationRaw> => {
  const productSlug = to.params?.productSlug?.toString().toLowerCase() || '';
  return productSwitcherFromString(productSlug);
};

export const routeToSavedUrl = async (): Promise<void> => {
  const userStore = useUserStore();
  const { savedUrl } = storeToRefs(userStore);
  const searchParams = new URLSearchParams(savedUrl.value.search.substring(1));
  const queryObject = Object.fromEntries(searchParams.entries());

  await router.push({
    path: savedUrl.value.pathname,
    query: queryObject,
    hash: savedUrl.value.hash,
  });
};
// we are sending different auth objects to this function
// I will check this with the auth objects more detailed later
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const prepareLoginData = (authData: any): UserState | null => {
  const userStore = useUserStore();

  if (!authData?.account && !authData?.accessToken) return null;

  const { account, accessToken, mercureToken } = authData;
  if (!account?.id && !account?.roles) return null;

  return {
    accessToken,
    mercureToken,
    id: account.id,
    clientCompanyId: account.clientCompany?.id || null,
    clientCompanyName: account.clientCompany?.legalName || null,
    selectedProduct: null,
    viewCompany: null,
    roles: account.roles,
    createdAt: account.createdAt,
    firstName: account.firstName,
    middleName: account.middleName,
    lastName: account.lastName,
    email: account.email,
    viewType: ViewType.PERSONAL,
    avatar: account.avatar?.contentUrl || null,
    savedUrl: userStore.savedUrl,
  };
};

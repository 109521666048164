import { ref } from 'vue';

export const userHasInteracted = ref(false);

export const trackInteraction = () => {
  if (!userHasInteracted.value) {
    userHasInteracted.value = true;
    document.removeEventListener('click', trackInteraction);
    document.removeEventListener('keydown', trackInteraction);
  }
};

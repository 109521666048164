import { UserRole } from '@/types/user';
import { useUserStore } from '@/store';

export const getClassByRole = (userRole: UserRole): string => {
  const { getSelectedProduct } = useUserStore();
  if (userRole === UserRole.ROLE_BUSINESS_SUPPORT && getSelectedProduct?.id) {
    return 'advisor';
  }
  if (userRole === UserRole.ROLE_BUSINESS_SUPPORT || userRole === UserRole.ROLE_ADMIN) {
    return 'bsupp';
  }
  if (userRole === UserRole.ROLE_SUSTAINABILITY_ADVISOR) {
    return 'advisor';
  }

  return 'default';
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

import { acceptHMRUpdate, defineStore } from 'pinia';
import { Message } from 'vue-advanced-chat';
import { MessageStoreModel, UnreadMessagesCountsStoreModel } from '@/types/message';

export const useMessageStore = defineStore('message', {
  state: (): MessageStoreModel => ({
    newMessages: {},
    selectedChatId: '',
    unreadMessagesCounts: {},
  }),

  getters: {
    getMessages(state: MessageStoreModel): { [key: string]: Message[] } {
      return state.newMessages;
    },
    getTotalUnreadMessageCount(state: MessageStoreModel): number {
      return Object.values(state.unreadMessagesCounts).reduce(
        (acc: number, count: number) => acc + count,
        0,
      );
    },
    getUnreadMessagesCounts(state: MessageStoreModel): UnreadMessagesCountsStoreModel {
      return { ...state.unreadMessagesCounts };
    },
    getSelectedIdNotification(state: MessageStoreModel): string {
      return state?.selectedChatId;
    },
  },
  actions: {
    addMessage(message: Message, id: string) {
      const updatedMessages = {
        ...this.$state.newMessages,
        [id]: this.$state.newMessages[id] ? [...this.$state.newMessages[id], message] : [message],
      };
      this.$patch({ newMessages: updatedMessages });
    },

    updatedUnreadMessagesCount(id: string, count: number) {
      this.$patch({ unreadMessagesCounts: { ...this.$state.unreadMessagesCounts, [id]: count } });
    },

    addUnreadMessagesCount(id: string) {
      this.$patch({
        unreadMessagesCounts: {
          ...this.$state.unreadMessagesCounts,
          [id]: this.$state.unreadMessagesCounts[id] + 1,
        },
      });
    },

    removeUnreadMessagesCount(id: string) {
      this.$patch({ unreadMessagesCounts: { ...this.$state.unreadMessagesCounts, [id]: 0 } });
    },

    resetMessages() {
      this.newMessages = {};
    },

    setSelectedIdNotification(id: string) {
      this.$patch({
        selectedChatId: id,
      });
    },

    clearUnreadMessagesCount() {
      this.unreadMessagesCounts = {};
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMessageStore, import.meta.hot));
}

export const createDebounce = () => {
  let timeout: ReturnType<typeof setTimeout>;
  return (fn: () => void, delay: number = 0) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      fn();
    }, delay);
  };
};

export const debounceWithArgs = (callback: (...args: never[]) => void, delay: number) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return (...args: []) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => callback(...args), delay);
  };
};

import { ref, watchEffect } from 'vue';
import { DataLayerObject } from '@gtm-support/vue-gtm';
import { useUserStore } from '@/store';
import { UserRole } from '@/types/user';
import { getIdFromApiPath } from '@/utils/composable/idHelpers';

export function useReactiveDataLayer() {
  const userStore = useUserStore();
  const dataLayer = ref<DataLayerObject[]>(window.dataLayer ?? []);

  watchEffect(() => {
    let userRole = userStore.getUserRole;

    if (userStore.getAllUserRoles?.includes('ROLE_BUSINESS_SUPPORT')) {
      userRole = UserRole.ROLE_BUSINESS_SUPPORT;
    } else if (userStore.getAllUserRoles?.includes('ROLE_SUSTAINABILITY_ADVISOR')) {
      userRole = UserRole.ROLE_SUSTAINABILITY_ADVISOR;
    }

    const userId = userStore.userProfile?.id ? getIdFromApiPath(userStore.userProfile.id) : null;

    const newDataLayerObject: DataLayerObject = {
      afs_user_id: userId,
      user_type: userRole || 'guest',
      view_type: userStore.getViewType || 'personalView',
      product_id: userStore.getSelectedProduct?.id || null,
      product_name: userStore.getSelectedProduct?.abbr || null,
    };

    dataLayer.value = [newDataLayerObject];

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ...newDataLayerObject });
  });

  return { dataLayer };
}

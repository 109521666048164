<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import BaseIcon from '@/components/base/BaseIcon.vue';
import {
  notificationWindowOpen,
  toggleNotificationWindow,
} from '@/utils/composable/topNavigationBar';
import { usePushNotificationsStore } from '@/store';

export default defineComponent({
  name: 'NotificationsBellButton',
  components: {
    BaseIcon,
  },
  setup() {
    const PushNotificationsStore = usePushNotificationsStore();
    const { getUnreadMessagesCounts } = storeToRefs(PushNotificationsStore);

    onMounted(async () => {
      await PushNotificationsStore.fetchAndSetNotifications(1);
    });

    return {
      getUnreadMessagesCounts,
      toggleNotificationWindow,
      notificationWindowOpen,
    };
  },
});
</script>
<template>
  <button
    id="notifications-bell-button"
    class="notifications-bell-button nav-mb__icon-wrapper"
    :class="{
      'notifications-bell-button--with-mark': getUnreadMessagesCounts > 0,
      'notifications-bell-button--open': notificationWindowOpen,
    }"
    @click="toggleNotificationWindow"
  >
    <BaseIcon data-cy="NavbarNotifications" icon-name="notification" />
  </button>
</template>

<style lang="scss" scoped>
.notifications-bell-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: remCalc(32);
  height: remCalc(32);
  border-radius: 100%;
  cursor: pointer;
  transition: background 0.25s ease-out;
  margin-left: var(--space-4xs);

  @media screen and (min-width: $mobile-plus) {
    margin-left: 0;
  }

  &:hover {
    background: var(--color-primary-hover);

    .nav-mb__icon {
      color: var(--color-shade-8);
    }
  }

  &--with-mark {
    position: relative;

    &:before {
      content: ' ';
      display: block;
      background: var(--color-highlight-alt);
      width: 8px;
      height: 8px;
      position: absolute;
      border-radius: 50%;
      right: 2px;
      top: 2px;
    }
  }

  &--open {
    background: var(--color-primary-active);
    .nav-mb__icon {
      color: var(--color-shade-8);
    }
  }
}
</style>

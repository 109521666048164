<script lang="ts">
import { computed, defineComponent, getCurrentInstance, ref } from 'vue';
import { useRouter } from 'vue-router';
import { logErrorMessages } from '@vue/apollo-util';
import { storeToRefs } from 'pinia';
import { useGlobalStore, useUserStore, useNotificationStore } from '@/store';
import { NotificationType } from '@/types/notification';
import { SelectedProductType, UserRole } from '@/types/user';
import {
  useCreateProductAccessRequestMutation,
  useGetAccountQuery,
  useProductsQuery,
  ProductsQuery,
  useGetAccountAppointedSustainabilityQuery,
} from '@/types/graphql';
import { Step, StepsType } from '@/types/base-types';
import ClickButton from '@/components/base/ClickButton.vue';
import BaseSkeletonLoader from '@/components/base/BaseSkeletonLoader.vue';
import { MAIN_ROUTES } from '@/config/constants/routes';
import Calendly from '@/components/calendar/Calendly.vue';
import { routeToSavedUrl } from '@/utils/routeAndlogin';
import ProductCard from '@/components/products/ProductCard.vue';
import { t } from '@/utils/composable/localeHelper';

export default defineComponent({
  name: 'ProductSwitcher',
  components: {
    ProductCard,
    ClickButton,
    Calendly,
    BaseSkeletonLoader,
  },
  props: {
    calendarMode: {
      type: Boolean,
      default: false,
    },
    popupMode: {
      type: Boolean,
      default: false,
    },
    dataCy: {
      type: String,
      required: true,
    },
  },
  emits: ['change-step'],
  setup(props, ctx) {
    const calendlyId = ref('');
    const calendlyName = ref('');
    const calendlyEmail = ref('');
    const loadingRequests = ref<{ [productId: string]: boolean }>({});
    const { addNotification } = useNotificationStore();
    const { push, currentRoute } = useRouter();
    const cypressDataAttribute = `${getCurrentInstance()?.type.name}`;
    const userStore = useUserStore();
    const { toggleProductSwitcherPopup } = useGlobalStore();
    const { getUserRole, userProfile, getSelectedProduct, getViewCompany, savedUrl } =
      storeToRefs(userStore);
    const { setSelectedProduct, logout } = useUserStore();

    const {
      result: productsResult,
      loading: loadingProducts,
      refetch: refetchProducts,
    } = useProductsQuery({ fetchPolicy: 'cache-and-network' });

    const {
      result: accountResult,
      loading: loadingAccount,
      refetch: refetchAccount,
    } = useGetAccountQuery(
      {
        id: userProfile.value.id as string,
      },
      { fetchPolicy: 'cache-first' },
    );

    const requestProductAccess = async (productId: string) => {
      loadingRequests.value[productId] = true;
      const {
        mutate: requestAccess,
        onDone,
        onError,
      } = useCreateProductAccessRequestMutation({
        variables: {
          input: {
            product: productId,
          },
        },
      });

      onDone(async () => {
        addNotification({
          message: t('pages.productSwitcher.requestAccessNotification'),
          type: NotificationType.SUCCESS,
          showIcon: true,
        });
        loadingRequests.value[productId] = false;
        refetchAccount();
        refetchProducts();
      });

      onError(async (error) => {
        logErrorMessages(error);
        loadingRequests.value[productId] = false;
      });

      await requestAccess();
    };

    const isClientRole = computed(() => getUserRole.value === UserRole.ROLE_COMPANY_REPRESENTATIVE);
    const isBSuppRole = computed(() => getUserRole.value === UserRole.ROLE_BUSINESS_SUPPORT);
    const selectedProduct = computed(() => getSelectedProduct.value);
    const isSARole = computed(() => getUserRole.value === UserRole.ROLE_SUSTAINABILITY_ADVISOR);
    const steps = ref<StepsType>({
      step1: true,
      step2: false,
      error: false,
    });

    const availableProducts = computed(() => {
      if (isSARole.value) {
        return accountResult.value?.account?.sustainabilityAdvisorProducts?.edges?.map((item) => ({
          id: item?.node?.product?.id,
          abbr: item?.node?.product?.abbreviation,
          name: item?.node?.product?.name,
          isProductManager: item?.node?.isProductManager,
        }));
      }
      if (isClientRole.value) {
        const products = accountResult.value?.account?.companyRepresentativeProducts?.edges
          ?.map((item) => ({
            id: item?.node?.id,
            abbr: item?.node?.abbreviation,
            name: item?.node?.name,
            isProductManager: false,
          }))
          .filter(
            (availableProduct) =>
              !userStore.getViewCompany?.products ||
              userStore.getViewCompany.products.includes(availableProduct.id || ''),
          );
        if (products) userStore.setAppointedProducts(products as SelectedProductType[]);
        return products;
      }
      return [];
    });

    const availableProductIds = computed(
      () => new Set(availableProducts?.value?.map((p) => p.id) || []),
    );

    const pendingProducts = computed(() => {
      if (isClientRole.value) {
        return accountResult.value?.account?.productAccessRequests?.collection?.map((item) => ({
          id: item?.product?.id,
          abbreviation: item?.product?.abbreviation,
          name: item?.product?.name,
          isProductManager: false,
        }));
      }
      return [];
    });

    const pendingProductIds = computed(
      () => new Set(pendingProducts?.value?.map((p) => p.id) || []),
    );

    const compareProducts = (
      a: NonNullable<NonNullable<ProductsQuery['products']>['edges']>[0],
      b: NonNullable<NonNullable<ProductsQuery['products']>['edges']>[0],
    ) => {
      if (
        availableProductIds.value.has(a?.node?.id) &&
        availableProductIds.value.has(b?.node?.id)
      ) {
        return 0;
      }
      if (availableProductIds.value.has(b?.node?.id)) {
        return 1;
      }
      return -1;
    };

    const { result: appointedSa, loading: appointedLoading } =
      useGetAccountAppointedSustainabilityQuery(
        {
          id: userProfile.value.id as string,
        },
        () => ({
          enabled: Boolean(props.calendarMode),
        }),
      );

    const appointedSAs = computed(() => {
      if (appointedSa.value?.account?.id) {
        const clientCompanyProducts = appointedSa?.value?.account?.clientCompany?.products?.edges;
        return clientCompanyProducts
          ?.map((product) => {
            const appointedSustainabilityAdvisors =
              product?.node?.appointedSustainabilityAdvisors?.collection;
            const filteredASAs = appointedSustainabilityAdvisors?.filter((item) => item?.id) || [];
            if (filteredASAs?.length > 0) {
              return {
                productNode: product?.node,
                aSAs: filteredASAs,
              };
            }
            return null;
          })
          .filter((item) => item !== null);
      }
      return [];
    });

    const SAPerProduct = computed(
      () =>
        appointedSAs.value?.find(
          (item) => item?.productNode?.product?.id === selectedProduct.value?.id,
        )?.aSAs,
    );

    const productsQueryResult = computed(() => {
      if (productsResult.value) {
        if (isClientRole.value && productsResult.value?.products?.edges) {
          let products: NonNullable<NonNullable<ProductsQuery['products']>['edges']> = [
            ...productsResult.value.products.edges,
          ];
          if (props.calendarMode) {
            products = products
              .filter((item) =>
                appointedSAs.value
                  ?.map((p) => p?.productNode?.product?.id)
                  .includes(item?.node?.id),
              )
              ?.map((product) => ({
                node: {
                  ...product?.node,
                  available: availableProductIds.value.has(product?.node?.id),
                },
              }))
              .filter((item) => item.node.available) as NonNullable<
              NonNullable<ProductsQuery['products']>['edges']
            >;
          }
          return products?.sort(compareProducts);
        }
        return productsResult.value?.products?.edges;
      }
      return [];
    });

    const allowedModules = computed(() => {
      const modules: Record<string, string[]> = {};
      if (isClientRole.value) {
        accountResult?.value?.account?.clientCompany?.products?.edges?.forEach((product) => {
          if (product?.node?.product?.id) {
            modules[product.node.product.id] = product?.node?.allowedModules?.edges
              ? product.node.allowedModules.edges.map((module) => module?.node?.slug || '')
              : [];
          }
        });
      }
      return modules;
    });

    const defaultDashboardGroups = computed(() => {
      const defaultDashboard: Record<string, string> = {};
      if (isClientRole.value) {
        accountResult?.value?.account?.clientCompany?.products?.edges?.forEach((product) => {
          if (product?.node?.product?.id) {
            defaultDashboard[product.node.product.id] = product?.node?.defaultDashboardGroup
              ? product?.node?.defaultDashboardGroup?.slug
              : '';
          }
        });
      }
      return defaultDashboard;
    });

    const productClasses = computed(() => {
      const classes: Record<string, string> = {};
      productsQueryResult.value?.forEach((product) => {
        if (product?.node?.abbreviation) {
          classes[product.node.abbreviation] = `product-card-${product.node.abbreviation}`;
        }
      });
      return classes;
    });

    const userName = computed(
      () => `${userProfile.value.firstName} ${userProfile.value?.lastName}`,
    );

    const changeStep = (step: Step) => {
      ctx.emit('change-step', step);
      Object.keys(steps.value).forEach((key: string) => {
        steps.value[key] = key === step;
      });
    };

    const selectProduct = async (pd: SelectedProductType) => {
      if (!pd.available) {
        return;
      }

      setSelectedProduct(pd);

      if (props.calendarMode) {
        changeStep(Step.Two);
      } else if (props.popupMode && !props.calendarMode) {
        toggleProductSwitcherPopup(false);
        if (currentRoute.value.fullPath !== `/${MAIN_ROUTES.DASHBOARD.name}`) {
          await push({ name: MAIN_ROUTES.DASHBOARD.name });
        }
      } else if (savedUrl.value.pathname) {
        await routeToSavedUrl();
      } else {
        await push({ name: MAIN_ROUTES.DASHBOARD.name });
      }
    };

    const clientHasOneProduct = computed(() => {
      if (productsQueryResult.value?.length === 1) {
        selectProduct(productsQueryResult.value[0]?.node as SelectedProductType);
        return true;
      }
      return false;
    });

    const getCalendlyId = async (
      id: string,
      firstName: string,
      middleName: string,
      lastName: string,
      email: string,
    ) => {
      if (id) {
        calendlyId.value = id;
        calendlyEmail.value = email.toLowerCase();
        calendlyName.value = `${firstName}%20${middleName ? `${middleName}%20` : ''}${lastName}`;
        changeStep(Step.Two);
      } else {
        changeStep(Step.Error);
      }
    };

    const logoutFromProductSwitcher = async () => {
      logout();
      await push({ name: MAIN_ROUTES.AUTH.name });
    };

    const columnWidth = computed(() => {
      switch (
        isSARole.value ? availableProducts.value?.length : productsQueryResult.value?.length
      ) {
        case 1:
          return '100%';
        case 2:
          return '49%';
        case 3:
          return '32%';
        case 4:
          return '23%';
        default:
          return '19%';
      }
    });

    const resetSelectedProduct = async () => {
      setSelectedProduct(null);
      if (props.popupMode) {
        toggleProductSwitcherPopup(false);
      }
      await push({ name: MAIN_ROUTES.DASHBOARD.name });
    };

    return {
      selectProduct,
      logoutFromProductSwitcher,
      changeStep,
      productsQueryResult,
      availableProducts,
      availableProductIds,
      userName,
      selectedProduct,
      loadingAccount,
      loadingProducts,
      isSARole,
      isClientRole,
      isBSuppRole,
      clientHasOneProduct,
      allowedModules,
      defaultDashboardGroups,
      productClasses,
      steps,
      columnWidth,
      appointedSAs,
      resetSelectedProduct,
      cypressDataAttribute,
      SAPerProduct,
      calendlyId,
      calendlyName,
      calendlyEmail,
      getCalendlyId,
      Step,
      requestProductAccess,
      pendingProducts,
      loadingRequests,
      getViewCompany,
      getUserRole,
      accountResult,
      appointedLoading,
      pendingProductIds,
    };
  },
});
</script>

<template>
  <div class="main-wrapper">
    <div v-if="!popupMode" class="header">
      <div class="text-info">
        <h1>
          {{ $t('pages.productSwitcher.h1') }}
        </h1>
        <h2>{{ $t('pages.productSwitcher.chooseProduct') }}</h2>
      </div>
      <ClickButton
        :data-cy="`${cypressDataAttribute}-${dataCy}LogoutFromProductSwitcher`"
        icon="logout"
        status="outlined-primary icon-right-side logout"
        @click="logoutFromProductSwitcher"
      >
        {{ $t('pages.productSwitcher.logout') }}
      </ClickButton>
    </div>
    <template v-if="calendarMode && (loadingAccount || loadingProducts)">
      <div class="user-buttons">
        <div v-for="n in 3" :key="n" class="choose-user-button">
          <BaseSkeletonLoader :is-rounded="true" max-width="3.4rem" min-height="3.4rem" />
          <BaseSkeletonLoader max-width="8rem" max-height="1.5rem" />
        </div>
      </div>
      <BaseSkeletonLoader
        max-height="1.5rem"
        max-width="80%"
        :margin-array="['1rem', '0', '0.5rem', '0']"
      />
    </template>
    <template v-else-if="loadingAccount || loadingProducts">
      <div class="product-cards">
        <BaseSkeletonLoader v-for="n in 14" :key="n" :radius="'0.5rem'" />
      </div>
    </template>
    <template v-else>
      <div v-if="availableProducts && isSARole" class="product-cards">
        <ProductCard
          v-for="product in availableProducts"
          :key="product?.id"
          :product="{
            id: product?.id ?? '',
            abbr: product?.abbr ?? '',
            name: product?.name ?? '',
            isProductManager: accountResult?.account?.sustainabilityAdvisorProducts?.edges?.find(
              (item) => item?.node?.product?.id === product?.id,
            )?.node?.isProductManager,
            available: true,
          }"
          :is-active="selectedProduct?.id === product?.id"
          :is-client-role="isClientRole"
          :is-client-view="!!getViewCompany"
          :is-pending="false"
          :is-loading="loadingRequests[product?.id ?? '']"
          :data-cy="`ChooseProductSaRole${product.abbr}`"
          @select="selectProduct"
          @request-access="requestProductAccess"
        />
      </div>
      <template v-if="calendarMode && steps.step1">
        <base-loader v-if="appointedLoading" />
        <div v-else class="user-buttons">
          <div
            v-for="SA in SAPerProduct"
            :key="SA?.id"
            :data-cy="`${cypressDataAttribute}-${dataCy}ChooseSAButton`"
            class="choose-user-button"
            @click="
              getCalendlyId(
                SA?.calendlyId ?? '',
                SA?.firstName ?? '',
                SA?.middleName ?? '',
                SA?.lastName ?? '',
                SA?.email ?? '',
              )
            "
          >
            <img
              v-if="SA?.avatar?.contentUrl"
              alt="avatar"
              :src="SA?.avatar?.contentUrl"
              class="user__avatar"
            />
            <div v-else class="user__avatar--unavailable">
              {{ `${SA?.firstName?.[0]} ${SA?.lastName?.[0]}` }}
            </div>
            <div class="user__avatar__text">
              {{
                `${SA?.firstName}
                ${SA?.lastName}`
              }}
            </div>
          </div>
        </div>
      </template>
      <template v-if="calendarMode && steps.step2">
        <p
          v-if="!clientHasOneProduct"
          class="steps clickable"
          data-cy="ReturnToStep1"
          @click="changeStep(Step.One)"
        >
          {{ $t('base.modals.scheduleAppointment.step2') }}
        </p>
        <p
          v-else
          class="steps clickable"
          data-cy="ReturnToStep1WhenOneProduct"
          @click="changeStep(Step.One)"
        >
          {{ $t('base.modals.scheduleAppointment.step2hasOneProduct') }}
        </p>
        <Calendly
          :email="calendlyEmail"
          :link="`https://calendly.com/${calendlyId}`"
          :name="calendlyName"
          :is-full-size="true"
        />
      </template>
      <template v-if="calendarMode && steps.error">
        <p class="steps clickable" data-cy="ScheduleAppointmentStep2" @click="changeStep(Step.One)">
          {{ $t('base.modals.scheduleAppointment.step2') }}
        </p>
        <div class="no-account-text">
          <p>
            {{ $t('base.modals.scheduleAppointment.error') }}
          </p>
        </div>
      </template>
      <div
        v-else-if="!calendarMode && productsQueryResult?.length && steps.step1 && !isSARole"
        class="product-cards"
      >
        <ProductCard
          v-if="isBSuppRole"
          :product="{
            id: '',
            abbr: 'MANAGEMENT',
            name: $t('products.product.MANAGEMENT'),
            isProductManager: false,
            available: true,
          }"
          :is-active="!selectedProduct"
          data-cy="RetSelectedProduct"
          @select="resetSelectedProduct"
        />
        <ProductCard
          v-for="product in productsQueryResult"
          :key="product?.node?.id"
          :product="{
            id: product?.node?.id ?? '',
            abbr: product?.node?.abbreviation ?? '',
            name: product?.node?.name ?? '',
            allowedModules: allowedModules[product?.node?.id ?? ''],
            defaultDashboardGroup: defaultDashboardGroups[product?.node?.id ?? ''],
            isProductManager: accountResult?.account?.sustainabilityAdvisorProducts?.edges?.find(
              (item) => item?.node?.product?.id === product?.node?.id,
            )?.node?.isProductManager,
            available: availableProductIds.has(product?.node?.id) || isBSuppRole,
          }"
          :is-active="selectedProduct?.id === product?.node?.id"
          :is-client-role="isClientRole"
          :is-client-view="!!getViewCompany"
          :is-pending="pendingProductIds.has(product?.node?.id)"
          :is-loading="loadingRequests[product?.node?.id ?? '']"
          :data-cy="`ChooseProductCrBsRole${product?.node?.abbreviation}`"
          @select="selectProduct"
          @request-access="requestProductAccess"
        />
      </div>
      <p v-if="calendarMode && steps.step1" class="tip">
        {{ $t('base.modals.scheduleAppointment.tip1') }}
      </p>
      <p v-if="calendarMode && steps.step2" class="tip">
        {{ $t('base.modals.scheduleAppointment.tip2') }}
      </p>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/shared/productCards.scss';

.header {
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  flex-direction: column;

  @media screen and (min-width: $mobile-plus) {
    flex-direction: row;
  }

  p {
    font-weight: 300;
    font-size: remCalc(26);
    margin-top: remCalc(42);
    color: var(--color-main);
  }
}

.no-account-text {
  margin-top: remCalc(16);
  font-size: remCalc(16);
  @extend %fw-400;
  color: var(--color-light-grey);
  text-align: center;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  overflow: visible;
  padding: var(--container-padding);

  @media screen and (min-width: $mobile-plus) {
    padding: remCalc(41) remCalc(67) remCalc(46);
    height: 100vh;
  }

  .tip {
    margin-top: remCalc(15);
  }

  .bg-img {
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.logout {
  height: max-content;
  width: fit-content;

  @media screen and (min-width: $mobile-plus) {
    width: auto;
  }

  :deep(svg) {
    fill: var(--color-primary);
  }
}

.user-buttons {
  display: flex;
  flex-wrap: wrap;
  margin: remCalc(24) 0;
  padding: var(--space-3xs);
  gap: var(--space-xs);

  .choose-user-button {
    display: flex;
    align-items: center;
    column-gap: var(--space-3xs);
    cursor: pointer;
    color: var(--color-secondary);
    min-width: remCalc(260);
  }

  .btn + .btn {
    margin: 0;
  }
}

.user__avatar {
  width: remCalc(55);
  height: remCalc(55);
  border-radius: 50%;

  &--unavailable {
    @extend %fw-300;

    display: flex;
    flex: 0 0 remCalc(55);
    height: remCalc(55);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-secondary);
    font-size: var(--space-sm);
  }

  &__text {
    @extend %fw-700;
    color: var(--color-secondary);
    font-size: var(--text-xl);
    line-height: var(--line-xxl);
  }
}

.text-info {
  h1,
  h2 {
    font-size: remCalc(40);
    line-height: 1;
    color: var(--color-primary);
    font-weight: 600;
  }

  h1 {
    margin-bottom: var(--space-3xs);

    @media screen and (min-width: $mobile-plus) {
      margin-bottom: 0;
    }
  }

  h2 {
    font-size: remCalc(28);
    line-height: 1;
    font-weight: 500;
    margin-bottom: var(--space-xs);

    @media screen and (min-width: $mobile-plus) {
      margin-bottom: 0;
    }
  }
}

.steps {
  &.clickable {
    cursor: pointer;

    &:before {
      content: url('data:image/svg+xml;charset=utf-8,%3Csvg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"%3E%3Cpath d="M8 1.91L6.47773 0.5L0 6.5L6.47773 12.5L8 11.09L3.05533 6.5L8 1.91Z" fill="%23061137"%3E%3C/path%3E%3C/svg%3E');
      position: relative;
      margin-right: remCalc(10);
    }
  }

  margin: remCalc(20) 0 0 remCalc(10);
}

.product-cards {
  grid-template-columns: repeat(2, 1fr);
  padding-top: var(--space-md);

  @media screen and (min-width: $mobile-plus) {
    display: grid;
    grid-template-columns: repeat(auto-fit, v-bind(columnWidth));
    grid-row-gap: 24px;
    height: 100%;
  }
}

.main-wrapper {
  padding-top: 3rem;
}
</style>

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { GET_IOIS, GET_SINGLE_IOI } from '@/api/ioi';
import { apolloClient } from '@/services/apollo';
import {
  IndicationOfInterestFieldsFragment,
  IndicationOfInterestOfferFieldsFragment,
} from '@/types/graphql';
import { INDICATION_OF_INTEREST_FIELDS } from '@/api/fragments';
import { NotificationPayloadData } from '@/types/ioi';
import { usePaginationVariables, usePaginationVariablesComputed } from '@/utils/ioi';

const cache = apolloClient;
dayjs.extend(utc);
dayjs.extend(timezone);

const extractDate = (date: string | { date: string } | null): string | null => {
  if (date === null) return null;
  const dateString = typeof date === 'string' ? date : date.date;

  return dayjs.utc(dateString).format('YYYY-MM-DDTHH:mm:ssZ');
};

const createIoIObject = ({
  payload,
}: {
  payload: NotificationPayloadData['payload'];
}): IndicationOfInterestFieldsFragment => ({
  ...payload.indicationOfInterest,
  __typename: 'IndicationOfInterest',
  offers: { collection: [] },
  createdBy: {
    firstName: payload.account.firstName,
    middleName: payload.account.middleName,
    lastName: payload.account.lastName,
  },
  clientCompany: { legalName: payload.clientCompany.name },
  currencyCode: payload.indicationOfInterest.currency.code,
  createdAt: extractDate(payload.indicationOfInterest.createdAt)!,
  expiresAt: extractDate(payload.indicationOfInterest.expiresAt),
});

const createOfferObject = ({
  payload,
}: {
  payload: NotificationPayloadData['payload'];
}): IndicationOfInterestOfferFieldsFragment => ({
  ...payload.offer,
  __typename: 'Offer',
  createdBy: {
    firstName: payload.account.firstName,
    middleName: payload.account.middleName,
    lastName: payload.account.lastName,
  },
  createdAt: dayjs.utc(payload.offer.createdAt.date).format('YYYY-MM-DDTHH:mm:ssZ'),
  indicationOfInterest: {
    __typename: 'IndicationOfInterest',
    id: payload.indicationOfInterest.id,
  },
  answeredAt: payload.offer?.answeredAt?.date || null,
});

const handleSubmittedIndicationOfInterest = (
  ioiId: string,
  localNewIoI: IndicationOfInterestFieldsFragment,
) => {
  const singleIOIVariables = { id: ioiId };
  const paginationVariables = usePaginationVariables();
  const paginationVariablesComputed = usePaginationVariablesComputed();
  // Update cache for GET_SINGLE_IOI
  cache.writeQuery({
    query: GET_SINGLE_IOI,
    variables: singleIOIVariables,
    data: { indicationOfInterest: localNewIoI },
  });

  // Try to read the existing list from the cache
  const existingList = cache.readQuery({
    query: GET_IOIS,
    variables: {
      page: paginationVariables.currentPage.value,
      ...(paginationVariables.currentFilter.value && {
        ...(paginationVariables.currentFilter.value !== 'expired'
          ? {
              status: paginationVariables.currentFilter.value,
            }
          : {}),
        expiresAt: paginationVariablesComputed.expiresAt.value,
        isExpired: paginationVariablesComputed.isExpired.value,
      }),
    },
  });

  if (existingList) {
    // Check if the localNewIoI is already in the existing collection
    let updatedCollection = existingList.indicationOfInterests.collection;
    const index = updatedCollection.findIndex(
      (item: IndicationOfInterestFieldsFragment) => item.id === ioiId,
    );

    if (index > -1) {
      // Replace the existing item if found
      updatedCollection[index] = localNewIoI;
    } else {
      updatedCollection = [localNewIoI, ...updatedCollection];
    }

    // Write the updated list to the cache
    cache.writeQuery({
      query: GET_IOIS,
      variables: {
        page: paginationVariables.currentPage.value,
        ...(paginationVariables.currentFilter.value && {
          ...(paginationVariables.currentFilter.value !== 'expired'
            ? {
                status: paginationVariables.currentFilter.value,
              }
            : {}),
          expiresAt: paginationVariablesComputed.expiresAt.value,
          isExpired: paginationVariablesComputed.isExpired.value,
        }),
      },
      data: {
        indicationOfInterests: {
          ...existingList.indicationOfInterests,
          collection: updatedCollection,
        },
      },
    });
  }
};

const handleCreatedOffer = (
  ioiId: string,
  localNewIoI: IndicationOfInterestFieldsFragment,
  newOffer: IndicationOfInterestOfferFieldsFragment,
) => {
  // Query to read the specific IOI from the cache
  const ioiData = apolloClient.readFragment<IndicationOfInterestFieldsFragment>({
    id: apolloClient.cache.identify({ __typename: 'IndicationOfInterest', id: ioiId }),
    fragment: INDICATION_OF_INTEREST_FIELDS,
    fragmentName: 'indicationOfInterestFields',
  });

  if (ioiData && ioiData.offers?.collection) {
    const existingOffers = [...ioiData.offers.collection];

    if (existingOffers) {
      existingOffers.push(newOffer);
    }

    // Write the updated ioi/offers back to the cache
    apolloClient.writeFragment({
      id: apolloClient.cache.identify({ __typename: 'IndicationOfInterest', id: ioiId }),
      fragment: INDICATION_OF_INTEREST_FIELDS,
      data: {
        ...localNewIoI,
        createdBy: ioiData.createdBy,
        offers: { collection: existingOffers },
      },
    });
  }
};

const handleAcceptedRejectedOffer = (
  ioiId: string,
  localNewIoI: IndicationOfInterestFieldsFragment,
  newOffer: IndicationOfInterestOfferFieldsFragment,
) => {
  // Query to read the specific IOI from the cache
  const ioiData = apolloClient.readFragment<IndicationOfInterestFieldsFragment>({
    id: apolloClient.cache.identify({ __typename: 'IndicationOfInterest', id: ioiId }),
    fragment: INDICATION_OF_INTEREST_FIELDS,
  });
  if (ioiData && ioiData.offers) {
    const existingOffers = [...(ioiData?.offers?.collection || [])];
    const index = existingOffers?.findIndex((offer) => offer?.id === newOffer.id);
    if (existingOffers.length && typeof index === 'number') {
      existingOffers[index] = newOffer;
    } else {
      existingOffers.push(newOffer);
    }

    // Write the updated ioi/offers back to the cache
    apolloClient.writeFragment({
      id: apolloClient.cache.identify({ __typename: 'IndicationOfInterest', id: ioiId }),
      fragment: INDICATION_OF_INTEREST_FIELDS,
      data: {
        ...localNewIoI,
        createdBy: ioiData.createdBy,
        offers: { collection: existingOffers },
      },
    });
  }
};

export const indicationOfInterestNotificationHandler = (message: NotificationPayloadData) => {
  if (message.type === 'IOI_SUBMITTED') {
    const ioiId: string = message.payload.indicationOfInterest.id; // assuming `id` is the identifier for IOI
    const localNewIoI = createIoIObject(message);

    handleSubmittedIndicationOfInterest(ioiId, localNewIoI);
  }
  if (message.type === 'OFFER_CREATED') {
    const ioiId: string = message.payload.indicationOfInterest.id; // The parent IOI ID
    const localNewIoI = createIoIObject(message);
    const newOffer: IndicationOfInterestOfferFieldsFragment = createOfferObject(message);

    handleCreatedOffer(ioiId, localNewIoI, newOffer);
  }
  if (message.type === 'OFFER_ACCEPTED' || message.type === 'OFFER_REJECTED') {
    const ioiId: string = message.payload.indicationOfInterest.id; // The parent IOI ID
    const localNewIoI = createIoIObject(message);
    const newOffer: IndicationOfInterestOfferFieldsFragment = createOfferObject(message);
    handleAcceptedRejectedOffer(ioiId, localNewIoI, newOffer);
  }
};

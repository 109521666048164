<script lang="ts">
import { defineComponent, onUnmounted } from 'vue';

export default defineComponent({
  name: 'Calendly',
  props: {
    link: { type: String, required: true },
    name: { type: String, default: '' },
    email: { type: String, default: '' },
    isFullSize: { type: Boolean, default: false },
  },
  setup() {
    const loadScript = () => {
      const script = document.createElement('script');
      script.async = true;
      script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
      document.head.appendChild(script);
    };

    const removeScript = () => {
      const script = document.querySelector(
        'script[src="https://assets.calendly.com/assets/external/widget.js"]',
      );
      script?.parentNode?.removeChild(script);
    };

    loadScript();

    onUnmounted(() => {
      removeScript();
    });

    return {};
  },
});
</script>

<template>
  <div
    :data-url="`${link}${name ? `?name=${name}` : ''}${email ? `&email=${email}` : ''}`"
    :class="['calendly-inline-widget', { 'calendly-inline-widget--full-size': isFullSize }]"
  />
</template>
<style lang="scss">
.calendly-inline-widget {
  overflow-y: hidden !important;
  width: 674px;
  height: 550px;
  margin: auto;

  &--full-size {
    height: 100%;
    width: 100%;
  }

  > iframe {
    border-radius: var(--border-radius-big);
  }

  .calendly-spinner {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    text-align: center;
    z-index: -1;
  }

  .calendly-spinner > div {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #e1e1e1;
    border-radius: 50%;
    vertical-align: middle;
    animation: calendly-bouncedelay 1.4s infinite ease-in-out;
    animation-fill-mode: both;
  }

  .calendly-spinner .calendly-bounce1 {
    animation-delay: -0.32s;
  }

  .calendly-spinner .calendly-bounce2 {
    animation-delay: -0.16s;
  }

  @keyframes calendly-bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }

    40% {
      transform: scale(1);
    }
  }
}
</style>

import gql from 'graphql-tag';
import {
  ENERGY_EXCHANGE_ORDER_FIELDS,
  ENERGY_EXCHANGE_ORDER_FIELDS_WITH_ROLL_AND_DEC,
} from '@/api/fragments';

export const CREATE_ORDER = gql`
  ${ENERGY_EXCHANGE_ORDER_FIELDS}
  mutation CreateOrder($input: createOrderInput!) @api(name: "authEndPoint") {
    createOrder(input: $input) {
      order {
        ...energyExchangeOrderFields
      }
    }
  }
`;

export const GET_ORDERS = gql`
  ${ENERGY_EXCHANGE_ORDER_FIELDS}
  query GetOrders(
    $page: Int
    $status: String
    $status_list: [String]
    $order: [OrderFilter_order]
    $executionType: String
  ) @api(name: "authEndPoint") {
    orders(
      page: $page
      status: $status
      status_list: $status_list
      order: $order
      executionType: $executionType
    ) {
      collection {
        ...energyExchangeOrderFields
      }
      paginationInfo {
        itemsPerPage
        lastPage
        totalCount
      }
    }
  }
`;

export const GET_ORDERS_WITH_ROLL_AND_DEC = gql`
  ${ENERGY_EXCHANGE_ORDER_FIELDS_WITH_ROLL_AND_DEC}
  query GetOrdersWithRollAndDec(
    $page: Int
    $status: String
    $status_list: [String]
    $order: [OrderFilter_order]
    $executionType: String
    $type: String
  ) @api(name: "authEndPoint") {
    orders(
      page: $page
      status: $status
      status_list: $status_list
      order: $order
      executionType: $executionType
      type: $type
    ) {
      collection {
        ...energyExchangeOrderFieldsWithRollAndDec
      }
      paginationInfo {
        itemsPerPage
        lastPage
        totalCount
      }
    }
  }
`;

export const GET_CLIENT_COMPANY_ZOHO_STATUS = gql`
  query GetClientCompanyZohoStatus($id: ID!) @api(name: "authEndPoint") {
    clientCompany(id: $id) {
      kycRequested
      kycRequestedAt
      primarySubEntity {
        zohoAccount {
          id
          kycStatus
          status
        }
      }
    }
  }
`;

export const GET_EXCHANGE_PLUG_STATUS = gql`
  query GetExchangePlugStatus($id: ID!) @api(name: "authEndPoint") {
    exchangePlugStatus(id: $id) {
      _id
      createdAt
      id
      setBy {
        id
      }
      status
    }
  }
`;

export const GET_EXCHANGE_PLUG_STATUSES = gql`
  query GetExchangePlugStatuses($page: Int) @api(name: "authEndPoint") {
    exchangePlugStatuses(page: $page) {
      collection {
        _id
        createdAt
        id
        setBy {
          id
        }
        status
      }
      paginationInfo {
        itemsPerPage
        lastPage
        totalCount
      }
    }
  }
`;

export const UPDATE_EXCHANGE_PLUG_STATUS = gql`
  mutation UpdateExchangePlugStatus($input: updateExchangePlugStatusInput!)
  @api(name: "authEndPoint") {
    updateExchangePlugStatus(input: $input) {
      exchangePlugStatus {
        _id
        createdAt
        id
        setBy {
          id
        }
        status
      }
    }
  }
`;
/* ID field was added to this query as under client view companyRepresentativeProducts
always empty array without id field in query */
export const GET_REPRESENTATIVE_PRODUCTS = gql`
  query getRepresentativeProducts($id: ID!) @api(name: "authEndPoint") {
    account(id: $id) {
      id
      companyRepresentativeProducts {
        edges {
          node {
            id
            name
            abbreviation
          }
        }
      }
    }
  }
`;

export const GET_LANDING_PAGE_PRODUCTS = gql`
  query getLandingPageProducts($id: ID!, $productStatus: String = "pending")
  @api(name: "authEndPoint") {
    account(id: $id) {
      id
      email
      status
      roles
      companyRepresentativeProducts {
        edges {
          node {
            id
            name
            abbreviation
          }
        }
      }
      productAccessRequests(status: $productStatus) {
        collection {
          id
          product {
            id
            abbreviation
            name
          }
          status
        }
      }
    }
  }
`;

export const GET_ALL_PRODUCTS = gql`
  query getAllProducts @api(name: "authEndPoint") {
    products {
      edges {
        node {
          id
          name
          abbreviation
        }
      }
    }
  }
`;

export const UPDATE_PAYMENT_STATUS_ORDER = gql`
  mutation updatePaymentStatusOrder($input: updatePaymentStatusOrderInput!)
  @api(name: "authEndPoint") {
    updatePaymentStatusOrder(input: $input) {
      clientMutationId
      order {
        ...energyExchangeOrderFields
      }
    }
  }
`;

export const GET_PRODUCT_COMMODITIES = gql`
  query getProductCommodities($id: String) @api(name: "authEndPoint") {
    commodities(product_id: $id) {
      id
      name
    }
  }
`;

export const VERIFY_CLIENT_COMPANY = gql`
  mutation verifyClientCompany($input: verifyClientCompanyInput!) @api(name: "authEndPoint") {
    verifyClientCompany(input: $input) {
      clientCompany {
        id
        kycRequested
        kycRequestedAt
        primarySubEntity {
          zohoAccount {
            id
            kycStatus
            status
          }
        }
      }
    }
  }
`;

export const DELETE_ORDER = gql`
  mutation deleteOrder($input: deleteOrderInput!) @api(name: "authEndPoint") {
    deleteOrder(input: $input) {
      clientMutationId
      order {
        id
      }
    }
  }
`;

export const CREATE_INTERNAL_ORDER = gql`
  ${ENERGY_EXCHANGE_ORDER_FIELDS}
  mutation CreateInternalOrder($input: createInternalOrderInput!) @api(name: "authEndPoint") {
    createInternalOrder(input: $input) {
      order {
        ...energyExchangeOrderFields
      }
    }
  }
`;

export const CREATE_LIMIT_ORDER = gql`
  ${ENERGY_EXCHANGE_ORDER_FIELDS}
  mutation CreateLimitOrder($input: createLimitOrderInput!) @api(name: "authEndPoint") {
    createLimitOrder(input: $input) {
      order {
        ...energyExchangeOrderFields
      }
    }
  }
`;

export const GET_ORDER = gql`
  query getOrder($id: ID!) @api(name: "authEndPoint") {
    order(id: $id) {
      ...energyExchangeOrderFields
    }
  }
`;

export const GET_LATEST_ROLL_INPUT = gql`
  query GetLatestRollInput($commodity: ID!) @api(name: "authEndPoint") {
    latestExchangeRollInput(commodity: $commodity) {
      id
      yearlyInterest
      decExpiration
      commodity {
        id
        name
      }
    }
  }
`;

export const CREATE_ROLL_INPUT = gql`
  mutation CreateRollInput($input: createExchangeRollInputInput!) @api(name: "authEndPoint") {
    createExchangeRollInput(input: $input) {
      exchangeRollInput {
        id
        yearlyInterest
        decExpiration
        commodity {
          id
          name
        }
      }
    }
  }
`;

export const REQUEST_TO_CANCEL_ORDER = gql`
  mutation RequestToCancelOrder($input: requestToCancelOrderInput!) @api(name: "authEndPoint") {
    requestToCancelOrder(input: $input) {
      order {
        id
        status
      }
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation CancelOrder($input: cancelOrderInput!) @api(name: "authEndPoint") {
    cancelOrder(input: $input) {
      order {
        id
        status
      }
    }
  }
`;

export const FILL_ORDER = gql`
  mutation FillOrder($input: fillOrderInput!) @api(name: "authEndPoint") {
    fillOrder(input: $input) {
      order {
        id
        status
      }
    }
  }
`;

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'BaseSkeletonLoader',
  props: {
    isRounded: { type: Boolean, required: false, default: false },
    radius: { type: String, required: false, default: '' },
    maxWidth: { type: String, required: false, default: '100%' },
    maxHeight: { type: String, required: false, default: '100%' },
    minWidth: { type: String, required: false, default: '1rem' },
    minHeight: { type: String, required: false, default: '1rem' },
    marginArray: {
      type: Array as PropType<(string | number)[]>,
      required: false,
      default: () => ['0', '0', '0', '0'],
    },
    primaryHoverColor: { type: Boolean, default: false },
    lines: { type: Number, default: 1 },
  },
  emits: ['handleClose'],
  setup() {
    return {};
  },
});
</script>

<template>
  <div
    v-for="line in lines"
    :key="line"
    :class="[
      'skeleton',
      { 'skeleton-circle': isRounded },
      { 'skeleton-primary-hover': primaryHoverColor },
    ]"
    :style="[
      {
        'min-height': minHeight,
        'min-width': minWidth,
        'max-width': maxWidth,
        'max-height': maxHeight,
        'margin-top': marginArray[0],
        'margin-right': marginArray[1],
        'margin-bottom': marginArray[2],
        'margin-left': marginArray[3],
      },
      lines > 1 ? `width: ${Math.floor(Math.random() * 31) + 70}%` : '',
      radius ? `border-radius: ${radius}` : '',
    ]"
  />
</template>

<style lang="scss" scoped>
.skeleton {
  width: 100%;
  height: 100%;
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  color: var(--color-hover-70);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  border-radius: 2px;
  &-circle {
    border-radius: 150rem;
  }
  &-primary-hover {
    background-image: linear-gradient(
      110deg,
      var(--color-primary) 10%,
      var(--color-primary-hover) 20%,
      var(--color-primary) 30%
    );
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
</style>

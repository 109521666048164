<script lang="ts">
import { computed, defineComponent, watch, getCurrentInstance, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useNotificationStore } from '@/store';
import BaseIcon from '@/components/base/BaseIcon.vue';
import { NotificationType } from '@/types/notification';

export default defineComponent({
  name: 'Notification',
  components: { BaseIcon },
  setup() {
    const store = useNotificationStore();
    const notifTimeout = ref();
    const isMessageVisible = ref(false);

    const { message, type, description, showIcon, callBackOnClick = null } = storeToRefs(store);

    const iconName = computed((): string => {
      switch (type?.value) {
        case NotificationType.ERROR:
          return 'warning';
        case NotificationType.ALERT:
          return 'warning--alt';
        case NotificationType.SUCCESS:
          return 'checkmark--outline';
        case NotificationType.NOTIFY:
          return 'notification';
        case NotificationType.MESSAGE:
          return 'chat';
        default:
          return 'notification';
      }
    });
    const cypressDataAttribute = `${getCurrentInstance()?.type.name}-${iconName.value}`;

    const toastHeader = computed((): string => {
      switch (type?.value) {
        case NotificationType.ERROR:
          return 'Error';
        case NotificationType.ALERT:
          return 'Warning';
        case NotificationType.SUCCESS:
          return 'Success';
        case NotificationType.NOTIFY:
          return '';
        default:
          return 'Notification';
      }
    });

    const closeNotification = (): void => {
      clearTimeout(notifTimeout.value);
      isMessageVisible.value = false;
      store.removeNotification();
    };

    watch(message, (newMsg) => {
      if (isMessageVisible.value) {
        isMessageVisible.value = false;
      }
      if (newMsg) {
        isMessageVisible.value = true;
      }
      clearTimeout(notifTimeout.value);
      notifTimeout.value = setTimeout(() => {
        if (document.getElementById('notification-toast')) {
          closeNotification();
        }
      }, 9000);
    });

    const handleNotification = (): void => {
      if (callBackOnClick && callBackOnClick.value) callBackOnClick.value();
      closeNotification();
    };

    return {
      message,
      isMessageVisible,
      type,
      description,
      showIcon,
      toastHeader,
      iconName,
      cypressDataAttribute,
      closeNotification,
      store,
      handleNotification,
    };
  },
});
</script>

<template>
  <Transition name="fade">
    <div
      v-if="isMessageVisible"
      id="notification-toast"
      data-unit-test="notification"
      :data-cy="cypressDataAttribute"
      :class="['notification', `notification-${type}`]"
      @click="handleNotification"
    >
      <BaseIcon
        v-if="showIcon"
        class="notification__icon"
        :data-cy="iconName"
        :icon-name="iconName"
      />
      <div class="notification__content">
        <div class="notification__header">
          {{ toastHeader }}
        </div>
        <p class="notification__message">
          {{ message }}
        </p>
        <p v-if="description" class="notification__description">
          {{ description }}
        </p>
      </div>
      <BaseIcon
        :class="{ centered: !description && message }"
        :data-cy="'NotificationClose'"
        class="notification__close"
        icon-name="close"
        @click="closeNotification"
      />
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.notification {
  position: fixed;
  right: remCalc(15);
  bottom: remCalc(15);
  height: fit-content;
  display: flex;
  align-items: center;
  z-index: 8;
  padding: remCalc(12) remCalc(32) remCalc(12) remCalc(12);
  border-radius: remCalc(4);
  color: var(--color-light);
  z-index: 1001;
  transition: 0.3s;
  width: calc(100% - remCalc(30));
  max-width: remCalc(478);
  animation: slideInDown 4s ease-in-out;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--color-notification-default);
    z-index: -1;
  }

  svg {
    fill: var(--color-light);
  }

  &-error {
    &::before {
      background-color: var(--color-notification-error);
    }
  }

  &-alert {
    &::before {
      background-color: var(--color-notification-alert);
    }
  }

  &-notify {
    &::before {
      background-color: var(--color-bg);
      border-radius: remCalc(8);
    }
    &::after {
      background-color: var(--color-bg);
    }
    svg {
      fill: var(--color-main);
    }
    .notification__description,
    .notification__message {
      color: var(--color-main);
    }
  }

  &-success {
    &::before {
      background-color: var(--color-notification-success);
    }
  }

  &-message {
    &::before {
      background-color: var(--color-notification-default);
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  &__icon {
    margin-right: remCalc(13);
    width: remCalc(23);
    min-width: remCalc(23);
    height: auto;
  }

  &__content {
    min-width: remCalc(400);
  }

  &__header {
    font-weight: 400;
    font-size: remCalc(16);
    line-height: remCalc(16);
    padding-bottom: remCalc(5);
  }

  &__message {
    font-weight: 400;
    font-size: remCalc(14);
    line-height: remCalc(16);
    width: 100%;
  }

  &__description {
    font-weight: 300;
    font-size: remCalc(12);
    line-height: remCalc(16);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }

  &__close {
    width: remCalc(16);
    height: remCalc(16);
    position: absolute;
    right: remCalc(5);
    top: remCalc(5);
    cursor: pointer;
    animation: closeButtonFadeIn 4s ease-in-out;
  }

  &-closed {
    transition: 0.3s;
    opacity: 0;
  }
}

.fade-leave-active {
  animation-duration: 0.3s !important;
}

@keyframes slideInDown {
  0% {
    transform: translateX(200%);
    max-width: remCalc(40);
    visibility: visible;
  }

  15% {
    transform: translateX(0%);
    visibility: visible;
  }

  18% {
    max-width: remCalc(40);
  }

  40% {
    max-width: remCalc(478);
  }
}

@keyframes closeButtonFadeIn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}
</style>

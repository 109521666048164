<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useUserStore } from '@/store';
import BaseAvatar from '@/components/base/BaseAvatar.vue';
import { toggleUserMenu, userMenuOpen } from '@/utils/composable/topNavigationBar';
import BaseIcon from '@/components/base/BaseIcon.vue';

export default defineComponent({
  name: 'UserMenuButton',
  components: {
    BaseIcon,
    BaseAvatar,
  },
  setup() {
    const userStore = useUserStore();
    const userAvatarData = computed(() => ({
      firstName: userStore.userProfile?.firstName,
      lastName: userStore.userProfile?.lastName,
      avatar: userStore.userAvatarUrl || '',
    }));

    return {
      userStore,
      userAvatarData,
      toggleUserMenu,
      userMenuOpen,
    };
  },
});
</script>
<template>
  <div
    id="user-menu-button"
    class="user-menu-button"
    :class="{
      'user-menu-button--open': userMenuOpen,
    }"
    @click="toggleUserMenu"
  >
    <BaseAvatar
      class="user-menu-button__avatar"
      :avatar-url="userAvatarData.avatar"
      :first-name="userAvatarData.firstName"
      :last-name="userAvatarData.lastName"
    />
    <BaseIcon
      class="user-menu-button__icon"
      data-cy="OverflowMenuVerticalIcon"
      icon-name="overflow-menu--vertical"
    />
  </div>
</template>

<style lang="scss" scoped>
.user-menu-button {
  display: flex;
  align-items: center;
  gap: var(--space-5xs);
  padding: var(--space-5xs);
  border-radius: remCalc(80);
  transition: all 0.25s ease-out;
  cursor: pointer;

  &:hover {
    background: var(--color-primary-hover);
    .nav-mb__icon {
      color: #fff;
    }
  }

  &--open {
    background: var(--color-primary-active);
    .nav-mb__icon {
      color: var(--color-shade-8);
    }
  }

  &__svg {
    margin-left: var(--space-3xs);
  }

  &__info {
    flex: 1 1 auto;
    min-width: 0; //needed to make text-overflow: ellipsis works for the child element in flexbox
  }

  &__name,
  &__email {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 1.17;
  }

  &__name {
    @extend %fw-700;
    font-size: var(--text-base);
    line-height: 1.17;
  }

  &__email {
    @extend %font-alert-title-sm;
  }

  .iconbar-expanded & {
    &:hover {
      background: var(--color-primary-hover);
    }

    .user-menu-button__info,
    .user-menu-button__dots {
      display: flex;
      padding-right: remCalc(32);
    }
  }

  &__icon {
    display: none;

    @media screen and (min-width: $mobile-plus) {
      display: block;
    }
  }
}
</style>

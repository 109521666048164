<script lang="ts">
import { defineComponent } from 'vue';
import { useGlobalStore, useUserStore } from '@/store';
import ClickButton from '@/components/base/ClickButton.vue';
import BaseIcon from '@/components/base/BaseIcon.vue';
import { ViewType } from '@/types/user';

export default defineComponent({
  name: 'TopClientViewBar',
  components: {
    BaseIcon,
    ClickButton,
  },
  setup() {
    const globalStore = useGlobalStore();
    const userStore = useUserStore();

    const closeClientView = () => {
      userStore.setViewType(ViewType.PERSONAL);
      userStore.setViewCompany(null);
    };

    return { globalStore, userStore, closeClientView };
  },
});
</script>

<template>
  <div class="client-view-bar-container">
    <header class="client-view-bar">
      <h4 v-if="userStore.getViewCompany?.legalName">{{ userStore.getViewCompany.legalName }}</h4>
      <div class="client-view-bar__buttons">
        <ClickButton
          class="btn--outlined-white"
          data-cy="changeClientBtn"
          @click="globalStore.toggleClientViewSelectionModal()"
          >{{ $t('base.buttons.changeClient') }}</ClickButton
        >
        <button
          class="client-view-bar__close"
          data-cy="button-close-client-nav"
          @click="closeClientView"
        >
          <base-icon data-cy="icon-close-client-nav" icon-name="close" />
        </button>
      </div>
    </header>
  </div>
</template>
<style lang="scss" scoped>
.client-view-bar-container {
  position: sticky;
  z-index: 50;
}
.client-view-bar {
  display: flex;
  justify-content: space-between;
  padding: var(--space-4xs) var(--space-xs);
  background-color: var(--color-notification-alert);
  color: var(--color-shade-8);

  &__buttons {
    display: flex;
    align-items: center;
    gap: var(--space-3xs);
  }

  &__close {
    color: var(--color-shade-8);
    display: flex;
    align-items: center;
    &:hover {
      color: var(--color-shade-0);
      transition: color 0.2s ease-in-out;
    }
  }
}
</style>

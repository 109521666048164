<script lang="ts">
import { defineComponent, getCurrentInstance, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore, useMessageStore } from '@/store';
import { MAIN_ROUTES } from '@/config/constants/routes';
import BaseIcon from '@/components/base/BaseIcon.vue';
import BaseAvatar from '@/components/base/BaseAvatar.vue';
import { toggleUserMenu } from '@/utils/composable/topNavigationBar';
import { iconBarToggle } from '@/utils/composable/navigation';
import { getBtnTerms } from '@/utils/composable/localeHelper';

export default defineComponent({
  name: 'UserMenu',
  components: {
    BaseIcon,
    BaseAvatar,
  },
  setup() {
    const userStore = useUserStore();
    const messagesStore = useMessageStore();
    const cypressDataAttribute = `${getCurrentInstance()?.type.name}`;
    const userAvatarData = computed(() => ({
      firstName: userStore.userProfile?.firstName,
      lastName: userStore.userProfile?.lastName,
      avatar: userStore.userAvatarUrl || '',
    }));

    const { push } = useRouter();

    const linkClicked = () => {
      toggleUserMenu();
    };

    const hideMenu = (element: HTMLElement) => {
      if (document.querySelector('#user-menu-button')?.contains(element)) {
        return;
      }
      toggleUserMenu();
    };

    const handleOnLogout = async () => {
      messagesStore.clearUnreadMessagesCount();
      push({ name: MAIN_ROUTES.AUTH.name });
      toggleUserMenu();
      iconBarToggle(false);
      userStore.logout();
    };

    return {
      handleOnLogout,
      userStore,
      getBtnTerms,
      push,
      MAIN_ROUTES,
      linkClicked,
      cypressDataAttribute,
      toggleUserMenu,
      iconBarToggle,
      hideMenu,
      userAvatarData,
    };
  },
});
</script>
<template>
  <div v-click-outside="hideMenu" class="user-menu">
    <div id="user-menu-button" class="user-menu-button">
      <BaseAvatar
        class="user-menu-button__avatar"
        :avatar-url="userAvatarData.avatar"
        :first-name="userAvatarData.firstName"
        :last-name="userAvatarData.lastName"
      />
      <div class="user-menu-button__info">
        <p :title="userStore.userProfile.fullName" class="user-menu-button__name">
          {{ userStore.userProfile.fullName }}
        </p>
        <p :title="userStore.userProfile.email" class="user-menu-button__email">
          {{ userStore.userProfile.email }}
        </p>
      </div>
    </div>
    <ul class="user-menu__list">
      <li class="user-menu__item">
        <router-link
          v-if="!userStore.isAPIUserRole"
          :to="MAIN_ROUTES.SETTINGS.path"
          class="user-menu__link"
          :data-cy="`${cypressDataAttribute}SettingsLink`"
          @click="linkClicked"
        >
          <BaseIcon
            icon-name="settings"
            :data-cy="`${cypressDataAttribute}SettingsIcon`"
            :size="24"
            class="user-menu__icon"
          />
          <span>
            {{ MAIN_ROUTES.SETTINGS.title }}
          </span>
        </router-link>
      </li>
      <li class="user-menu__item">
        <div
          role="button"
          class="user-menu__link"
          :data-cy="`${cypressDataAttribute}-logout`"
          @click="handleOnLogout"
        >
          <BaseIcon
            icon-name="logout"
            :data-cy="`${cypressDataAttribute}LogoutIcon`"
            :size="24"
            class="user-menu__icon"
          />
          <span>
            {{ getBtnTerms('logout') }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
.user-menu {
  display: flex;
  position: absolute;
  z-index: 5;
  bottom: 0;
  top: calc(var(--top-nav-height) + remCalc(10));
  right: 1rem;
  width: auto;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  padding: var(--space-sm) var(--space-xs) var(--space-xs);
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow-normal);
  text-align: center;
  background-color: var(--color-shade-8);

  &__name {
    @extend %font-navigation;
    padding: var(--space-3xs) 0;
    margin-bottom: var(--space-xs);
  }

  .btn--dropdown-element:hover {
    font-size: var(--text-base);
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding-top: var(--space-5xs);
  }

  &__link {
    @extend %font-navigation-item;
    display: flex;
    width: 100%;
    gap: var(--space-4xs);
    align-items: center;
    padding: var(--space-4xs) var(--space-3xs);
    border-radius: remCalc(80);
    background-color: transparent;
    transition:
      background-color 0.25s ease-in-out,
      color 0.25s ease-in-out;
    cursor: pointer;
    color: var(--color-font);
    &:hover {
      background: var(--color-shade-6);
    }
  }

  &__icon {
    flex: 0 0 auto;
  }

  .user-menu-button {
    display: flex;
    align-items: center;
    gap: var(--space-xxs);
    padding: 0 0 var(--space-xxs) 0;
    transition:
      padding 0.25s ease-out,
      border-color 0.25s ease-out;
    position: relative;
    border-bottom: var(--color-border) 1px solid;

    &__avatar {
      --avatar-size: #{remCalc(42)};
      margin-left: var(--space-5xs);

      @media screen and (min-width: $mobile-plus) {
        margin-left: 0;
      }
    }

    &__info {
      display: none;
      color: var(--color-font);
      flex-direction: column;
      align-items: flex-start;
    }

    &__info {
      display: flex;
      flex: 1 1 auto;
      gap: var(--space-5xs);
      min-width: 0; //needed to make text-overflow: ellipsis works for the child element in flexbox
    }

    &__name,
    &__email {
      line-height: 1;
      text-align: left;
    }

    &__name {
      @extend %fw-700;
      font-size: var(--text-base);
    }

    &__email {
      @extend %font-alert-title-sm;
    }
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseIcon from '@/components/base/BaseIcon.vue';
import { isMobileMenuOpen, toggleMenu } from '@/utils/composable/navigation';

export default defineComponent({
  name: 'NavControlButton',
  components: {
    BaseIcon,
  },
  setup() {
    return {
      isMobileMenuOpen,
      toggleMenu,
    };
  },
});
</script>

<template>
  <button
    v-if="!isMobileMenuOpen"
    class="nav-control-button"
    @click.stop="toggleMenu(!isMobileMenuOpen)"
  >
    <BaseIcon data-cy="NavControlButtonMenu" icon-name="menu" style="color: #000" />
  </button>
  <button v-else class="nav-control-button" @click="toggleMenu(!isMobileMenuOpen)">
    <BaseIcon icon-name="close" data-cy="NavControlButtonClose" style="color: #000" />
  </button>
</template>
<style lang="scss" scoped>
.nav-control-button {
  display: flex;
  align-items: center;
  margin-right: var(--space-3xs);

  @media screen and (min-width: $mobile-plus) {
    display: none;
  }
}
</style>

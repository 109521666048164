import { createDebounce } from '@/utils/debounce';

export const determineIfEndOfScroll = (list: Element, callback: () => void): void => {
  if (
    list.clientHeight !== list.scrollHeight &&
    list.scrollTop + list.clientHeight + 5 >= list.scrollHeight
  ) {
    const debounceFetch = createDebounce();
    debounceFetch(() => callback(), 300);
  }
};

export const addScrollEndListener = (selector: string, callback: () => void): void => {
  const list = document.querySelector(selector);
  list?.addEventListener('scroll', () => determineIfEndOfScroll(list, callback));
};

export const removeScrollEndListener = (selector: string, callback: () => void): void => {
  const list = document.querySelector(selector);
  list?.removeEventListener('scroll', () => determineIfEndOfScroll(list, callback));
};

import { createPinia } from 'pinia';
import { useGlobalStore } from '@/store/modules/global';
import { useUserStore } from '@/store/modules/user';
import { useNotificationStore } from '@/store/modules/notification';
import { useMessageStore } from '@/store/modules/message';
import { usePushNotificationsStore } from '@/store/modules/push-notifications';

const pinia = createPinia();

export {
  pinia,
  useGlobalStore,
  useUserStore,
  useNotificationStore,
  useMessageStore,
  usePushNotificationsStore,
};

import { acceptHMRUpdate, defineStore } from 'pinia';
import { NotificationsItemModel, PushNotificationsStoreModel } from '@/types/notification';
import { useNotificationsQuery } from '@/types/graphql';
import { getFormattedNotificationItem } from '@/utils/composable/notifications';

export const usePushNotificationsStore = defineStore('pushNotifications', {
  state: (): PushNotificationsStoreModel => ({
    notifications: [],
    isLoading: false,
    error: null,
  }),

  getters: {
    getNotifications(state: PushNotificationsStoreModel): (NotificationsItemModel | null)[] {
      return state.notifications;
    },
    getUnreadMessagesCounts(state: PushNotificationsStoreModel): number {
      return state.notifications.filter((item) => !item?.isReaded).length;
    },
  },
  actions: {
    addPushNotification(notification: NotificationsItemModel | null) {
      this.$patch({ notifications: [notification, ...this.$state.notifications] });
    },
    setPushNotificationsList(notifications: (NotificationsItemModel | null)[]) {
      this.$patch({ notifications });
    },
    async fetchAndSetNotifications(page: number) {
      this.$patch({ isLoading: true });

      const { refetch, onResult } = useNotificationsQuery(
        { page },
        { fetchPolicy: 'cache-and-network' },
      );

      onResult((result) => {
        if (!result.loading) {
          this.$patch({ isLoading: false });

          const notificationsList = result.data.notifications?.collection;
          if (notificationsList?.length) {
            const formattedNotificationsList = notificationsList
              .map((item) => getFormattedNotificationItem(item))
              .filter((item) => !!item);

            this.$patch({ notifications: formattedNotificationsList });
          } else {
            this.$patch({ notifications: [] });
          }

          if (result.error) {
            // Unclear error, types are correct
            // @ts-expect-error-next-line
            this.$patch({ error: result.error });
          }
        }
      });

      return refetch;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePushNotificationsStore, import.meta.hot));
}

import { computed } from 'vue';
import { HttpLink } from 'apollo-link-http';
import { split } from 'apollo-link';
import { InMemoryCache } from '@apollo/client/core';
import { getMainDefinition } from 'apollo-utilities';
import { ApolloDefinition } from '@/types/apollo';
import { useUserStore } from '@/store';
import { UserState } from '@/types/user';
import { getProfileLink } from '@/utils/composable/accountLink';

const userStore = useUserStore();

const token = computed(() => (userStore as UserState).accessToken);
export const headers = computed(() => {
  const auth = token.value
    ? {
        Authorization: `Bearer ${token.value}`,
      }
    : {};
  if (userStore.viewCompany?.id) {
    return {
      ...auth,
      'Content-Type': 'application/json',
      'client-view': userStore.viewCompany ? 1 : 0,
      'client-view-company-id': userStore.viewCompany?.id
        ? getProfileLink(userStore.viewCompany.id, '/api/client/', '')
        : '',
      'active-product': userStore.selectedProduct?.id
        ? userStore.selectedProduct.id.replace('/api/products/', '')
        : null,
    };
  }
  return {
    ...auth,
    'Content-Type': 'application/json',
    'active-product': userStore.selectedProduct?.id
      ? userStore.selectedProduct.id.replace('/api/products/', '')
      : null,
  };
});

export const endpoints = {
  authEndPoint:
    import.meta.env.VITE_PORTAL_GRAPHQL_URL || 'https://alpha-portal-api.afsenergy.nl/api',
  ppaEndPoint: import.meta.env.VITE_PPA_GRAPHQL_URL || 'https://alpha-ppa.afsenergy.nl',
  squidexEndPoint: `${import.meta.env.VITE_SQUIDEX_URL}/api/content/${import.meta.env.VITE_SQUIDEX_APPNAME}`,
};

export const httpLink = new HttpLink({
  uri: 'http://localhost:4568',
});

export const links = split(({ query }) => {
  const { kind, operation }: ApolloDefinition = getMainDefinition(query);
  return kind === 'OperationDefinition' && operation === 'subscription';
}, httpLink);

export const cacheConfig = new InMemoryCache({
  // This was commented because not sure we really need it.
  // If cache issues appears for accounts query should be uncommented
  // typePolicies: {
  //   Query: {
  //     fields: {
  //       accounts: {
  //         keyArgs: [],
  //         read(existing: SafeReadonly<any>) {
  //           return existing;
  //         },
  //        // existing parameter was added because initially incoming was actually existing
  //         merge(existing: SafeReadonly<any>, incoming: SafeReadonly<any>) {
  //           return incoming;
  //         },
  //       },
  //     },
  //   },
  // },
});

<script lang="ts">
import { defineComponent, PropType, getCurrentInstance, computed } from 'vue';
import BaseIcon from '@/components/base/BaseIcon.vue';
import { IconPosition } from '@/types/base-types';
import ButtonLoader from '@/components/base/ButtonLoader.vue';

export default defineComponent({
  name: 'ClickButton',
  components: { BaseIcon, ButtonLoader },
  props: {
    icon: { type: String, default: '' },
    routerLink: { type: String, default: '' },
    name: { type: String, default: '' },
    status: { type: String, default: '' },
    dataCy: { type: String, required: true },
    gtmClass: { type: String, default: '' },
    iconPosition: {
      type: String as PropType<IconPosition>,
      default: 'left',
    },
    btnType: {
      type: String as PropType<'button' | 'submit' | 'reset'>,
      default: 'button',
      validator: (value: string): boolean => ['button', 'submit', 'reset'].includes(value),
    },
    showArrow: { type: Boolean },
    isLoading: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
  },
  emits: ['click'],
  setup(props, ctx) {
    const isExternalLink = computed(() => {
      const protocol = /^https?:\/\//i;
      const mailto = /^mailto?:/i;
      return (
        (protocol.test(props.routerLink) || mailto.test(props.routerLink)) &&
        props.routerLink.indexOf(window.location.host) === -1
      );
    });

    const cypressDataAttribute = `${getCurrentInstance()?.type.name}-${props?.dataCy}`;
    const clickButton = (event: Event) => {
      if (props.isLoading || props.isDisabled) return;

      ctx.emit('click', event);
    };
    return {
      isExternalLink,
      clickButton,
      cypressDataAttribute,
    };
  },
});
</script>

<template>
  <button
    v-if="!routerLink"
    :class="[
      'btn',
      `btn--${status}`,
      { 'btn--text-icon': icon && $slots.default, 'btn--reverse': iconPosition === 'right' },
    ]"
    :data-cy="cypressDataAttribute"
    :disabled="status === 'disabled' || isDisabled"
    :type="btnType"
    aria-pressed="true"
    @click.prevent="clickButton"
  >
    <span
      :class="['btn__overlay', { 'hide-content': isLoading }, gtmClass]"
      :data-cy="`${cypressDataAttribute}Btn`"
    />
    <span v-if="$slots.default" :class="['btn__text', { 'hide-content': isLoading }]"
      ><slot
    /></span>
    <BaseIcon
      v-if="icon"
      :key="icon"
      :alt="`${icon}-icon`"
      :data-cy="`${cypressDataAttribute}-${icon}`"
      :class="[`btn__icon ${icon}`, { 'hide-content': isLoading }]"
      :icon-name="icon"
    />
    <svg
      v-if="showArrow"
      :class="['button-arrow', { 'hide-content': isLoading }]"
      fill="none"
      height="12"
      viewBox="0 0 8 12"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.52227 0L0 1.41L4.94467 6L0 10.59L1.52227 12L8 6L1.52227 0Z" />
    </svg>
    <ButtonLoader v-if="isLoading" />
  </button>
  <template v-else>
    <template v-if="isExternalLink">
      <a
        :class="[
          `btn--${status}`,
          'btn link',
          { 'btn--text-icon': icon && $slots.default, 'btn--reverse': iconPosition === 'right' },
        ]"
        :data-cy="cypressDataAttribute"
        :href="routerLink"
        target="_blank"
      >
        <span v-if="$slots.default" class="btn__text" @click="clickButton"><slot /></span>
        <BaseIcon
          v-if="icon"
          :data-cy="`${cypressDataAttribute}-${icon}`"
          :alt="`${icon}-icon`"
          :class="`btn__icon ${icon}`"
          :icon-name="icon"
        />
        <svg
          v-if="showArrow"
          class="button-arrow"
          fill="none"
          height="12"
          viewBox="0 0 8 12"
          width="8"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1.52227 0L0 1.41L4.94467 6L0 10.59L1.52227 12L8 6L1.52227 0Z" />
        </svg>
      </a>
    </template>
    <template v-else>
      <router-link
        :class="[
          `btn--${status}`,
          'btn link',
          { 'btn--text-icon': icon && $slots.default, 'btn--reverse': iconPosition === 'right' },
        ]"
        :data-cy="cypressDataAttribute"
        :to="routerLink"
      >
        <span v-if="$slots.default" class="btn__text" @click="clickButton"><slot /></span>
        <BaseIcon
          v-if="icon"
          :data-cy="`${cypressDataAttribute}-${icon}`"
          :alt="`${icon}-icon`"
          :class="`btn__icon ${icon}`"
          :icon-name="icon"
        />
        <svg
          v-if="showArrow"
          class="button-arrow"
          fill="none"
          height="12"
          viewBox="0 0 8 12"
          width="8"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1.52227 0L0 1.41L4.94467 6L0 10.59L1.52227 12L8 6L1.52227 0Z" />
        </svg>
      </router-link>
    </template>
  </template>
</template>

<style lang="scss" scoped>
.light {
  background: var(--color-shade-9);
  color: var(--color-shade-0);
}

.btn {
  &__text {
    line-height: 1.8;
  }
}

.hide-content {
  visibility: hidden !important;
}

.icon__only {
  .btn__icon {
    margin: remCalc(0);
  }
}
</style>

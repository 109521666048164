/* Depreciated as it was using tailwind with no style guide at the time. New loader is to be made
according to the style guide */
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ButtonLoader',
});
</script>

<template>
  <div class="logo-wrapper">
    <svg class="svg-container" viewBox="0 0 100 100">
      <circle class="loader-svg animate" cx="50" cy="50" r="45" />
    </svg>
  </div>
</template>

<style scoped lang="scss">
.logo-wrapper {
  height: remCalc(24);
  z-index: 10;
}
.svg-container {
  width: remCalc(16);
  overflow: visible;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
.loader-svg {
  fill: none;
  stroke-width: 10px;
  stroke-linecap: round;
  stroke: #fff;
}

.animate {
  stroke-dasharray: 242.6;
  animation: fill-animation 0.75s cubic-bezier(1, 1, 1, 1) 0s infinite;
}

@keyframes fill-animation {
  0% {
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 141.3;
    stroke-dashoffset: 141.3;
  }
  100% {
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: 282.6;
  }
}
</style>

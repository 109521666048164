import { UserRole } from '@/types/user';

export enum Status {
  active = 'active',
  disabled = 'disabled',
  ready = 'ready',
  loading = 'loading',
  failed = 'failed',
  success = 'success',
}

export interface navItem {
  name: string; // For router.push func
  label: string; // Link name
  componentPath: string; // For unit tests
  status: Status; // Product status for client
  icon?: string; // Item icon
  linkType?: string; // ??
  type: 'icon' | 'horizontal' | 'dashboard' | 'popper';
  classes?: string;
  role?: UserRole[] | UserRole;
  path?: string;
  moduleId?: string;
}

export type SideNavItems = [{ [key: string]: navItem[] }] | [];

export type AllSidebarItemsType = {
  [key: string]: navItem[];
};

export type AllDashBoardItemsType = Array<{ [key: string]: navItem[] }>;

export interface ClientCompany {
  id: string;
  legalName: string;
}

export interface ClientCompanyClientView {
  id: string;
  legalName: string;
  allowedModules: string[];
  products?: string[];
}

<script lang="ts">
import {
  defineComponent,
  computed,
  onUpdated,
  ref,
  onMounted,
  onUnmounted,
  nextTick,
  watch,
  getCurrentInstance,
  watchEffect,
} from 'vue';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { useRouter, useRoute } from 'vue-router';
import { register, Message } from 'vue-advanced-chat';
import { directive } from 'vue3-click-away';
import { NetworkError } from '@apollo/client/errors';
import { logErrorMessages } from '@vue/apollo-util';
import { UserRole, SelectedProductType } from '@/types/user';
import { NotificationType } from '@/types/notification';
import { graphQLErrorHandler, networkErrorHandler } from '@/utils/apiErrorHandler';
import { useUserStore, useNotificationStore, useMessageStore, useGlobalStore } from '@/store';
import { getProfileLink } from '@/utils/composable/accountLink';
import BaseIcon from '@/components/base/BaseIcon.vue';
import { getBtnTerms, getTranslationTerms } from '@/utils/composable/localeHelper';
import BaseLoader from '@/components/base/BaseLoader.vue';
import { USER_ROLE_ROUTES, COMMON_SIDE_BAR_ROUTES, MAIN_ROUTES } from '@/config/constants/routes';
import ButtonLoader from '@/components/base/ButtonLoader.vue';
import { createDebounce } from '@/utils/debounce';
import {
  chatMembersType,
  detailsType,
  MessageFile,
  FileData,
  RoomProduct,
} from '@/types/base-types';

// eslint-disable-next-line import/no-duplicates
import GO from '@/assets/images/products/GO.jpg';
// eslint-disable-next-line import/no-duplicates
import CFC from '@/assets/images/products/GO.jpg';
// eslint-disable-next-line import/no-duplicates
import EU_ETS from '@/assets/images/products/CCM.png';
// eslint-disable-next-line import/no-duplicates
import CCM from '@/assets/images/products/CCM.png';
import I_REC from '@/assets/images/products/I-REC.jpg';
import F_GAS from '@/assets/images/products/F-Gas.jpg';
import BIOMETHANE_HEATING from '@/assets/images/products/BIOMETHANE_HEATING.jpg';
import BIOMETHANE_TRANSPORT from '@/assets/images/products/BIOMETHANE_TRANSPORT.jpg';
import VCM from '@/assets/images/products/VCM.jpg';
import HBE from '@/assets/images/products/HBE.jpg';
import THG_Q from '@/assets/images/products/THG_Q.jpg';
import RTFC from '@/assets/images/products/RTFC.jpg';
import BIOFUELS from '@/assets/images/products/BIOFUELS.jpg';
import PPA from '@/assets/images/products/PPA.jpg';
import REFRIGERANT_GAS from '@/assets/images/products/REFRIGERANT_GAS.jpg';
import MANAGEMENT from '@/assets/images/products/Management.jpg';
import { CategoryInfo } from '@/types/documents';
import {
  useChatRoomQuery,
  useChatMessagesQuery,
  useCreateChatMessageMutation,
  useJoinChatRoomMutation,
  ChatRoomQuery,
  useGetUsersChatQuery,
  ChatMessagesQuery,
  useGetSpecificRoomLazyQuery,
  ChatRoomPageConnection,
  ChatRoom,
  GetUsersChatQuery,
} from '@/types/graphql/chat';
import { GET_CHATS_ROOMS } from '@/api/chat';
import {
  useClientDocumentCategoriesNamesLazyQuery,
  useClientCategoryDocumentsLazyQuery,
} from '@/types/graphql';
import { formatChatTimestamp, getFormattedDate, getUTCTimezoneDate } from '@/utils/composable/date';
import { MODULES_LIST } from '@/config/constants/modules';
import { getAccount } from '@/utils/routeAndlogin';
import { apolloClient } from '@/services/apollo';

register();

export default defineComponent({
  name: 'ChatModule',
  components: {
    BaseIcon,
    BaseLoader,
    ButtonLoader,
  },
  directives: {
    ClickAway: directive,
  },
  props: {
    getSelectedIdNotification: {
      type: String,
      default: '',
    },
  },
  emits: ['on-close-selected-chat-room', 'on-open-chat-module'],
  setup(props, ctx) {
    const debounceFn = createDebounce();
    const userStore = useUserStore();
    const globalStore = useGlobalStore();
    const { toggleChat } = globalStore;
    const { isChatOpen } = storeToRefs(globalStore);
    const { accessToken } = useUserStore();
    const { setSelectedProduct } = useUserStore();
    const { addNotification } = useNotificationStore();
    const messagesStore = useMessageStore();
    const {
      updatedUnreadMessagesCount,
      removeUnreadMessagesCount,
      resetMessages,
      addMessage,
      setSelectedIdNotification,
    } = messagesStore;
    const { push, currentRoute } = useRouter();
    const { setClientCompany } = userStore;
    const route = useRoute();
    const cypressDataAttribute = `${getCurrentInstance()?.type.name}`;
    // Computed properties
    const product = computed(() => userStore.getSelectedProduct);
    const isCompanyRepresentative = computed(
      () => userStore.getUserRole === UserRole.ROLE_COMPANY_REPRESENTATIVE,
    );
    const isSustainabilityAdvisor = computed(
      () => userStore.getUserRole === UserRole.ROLE_SUSTAINABILITY_ADVISOR,
    );
    const isBSuppRole = computed(() => userStore.getUserRole === UserRole.ROLE_BUSINESS_SUPPORT);
    const isManager = computed(() => product.value?.isProductManager);
    const chatModuleRoute = computed(() => route.path === MAIN_ROUTES?.CHAT?.path);
    const minimizedChat = computed(
      () =>
        (userStore.getUserRole === UserRole.ROLE_SUSTAINABILITY_ADVISOR ||
          userStore.getUserRole === UserRole.ROLE_BUSINESS_SUPPORT) &&
        !chatModuleRoute.value,
    );

    // Refs
    const isMounted = ref<boolean>(false);
    const isUnreadMessages = ref<boolean>(false);
    const isShowMembers = ref<boolean>(false);
    const isAdvisorsChat = ref<boolean>(false);
    const isOpenDropDown = ref<boolean>(false);
    const isShowMembersAndFiles = ref<boolean>(false);
    const categoriesLoading = ref<boolean>(true);
    const chatMessagesLoading = ref<boolean>(false);
    const loadingRooms = ref<boolean>(true);
    const searchRoomsLoading = ref(false);
    const isJoinChat = ref<boolean>(false);
    const isOpenSelectedRoom = ref<boolean>(false);
    const selectedTeamRooms = ref<ChatRoomQuery>({});
    const inChatRoom = ref<boolean>(false);
    const vueAdvancedChat = ref<HTMLLinkElement>();
    const selectedRoomId = ref<string>('');
    const activeTab = ref<string>('');
    const chatRoomType = ref<string>('');
    const chatRoomsId = ref<string>('');
    const isActive = ref(0);
    const chatMessagesResult = ref<ChatMessagesQuery>();
    const messages = ref<Message[]>([]);
    const chatRoomsResult = ref<ChatRoomQuery>({});
    const categoriesList = ref<CategoryInfo[]>([]);
    const chatCategoryId = ref<string>('');
    const advisorsFirstName = ref<string>('');
    const advisorsLastName = ref<string>('');
    const downloadLink = ref<string>('');
    const focusedChatRoomId = ref<string>('');
    const isPrevent = ref<boolean>(false);
    const showEmptyState = ref(false);
    const totalRoomsCount = ref(0);
    const totalMessagesCount = ref(0);
    const otherRoom = ref(false);
    const oldChatRoomId = ref('');
    const roomListPage = ref<number>(1);
    const messagesItemsPerPage = ref<number>(30);
    const messagesPage = ref<number>(1);
    const loadingUrl = ref<boolean>(false);
    const downloadElementRef = ref<HTMLLinkElement | null>(null);
    const reqAgain = ref<boolean>(true);
    const isScrollingDisabled = ref(false);
    const chatRoomName = ref<string>('');

    const images = ref({
      GO,
      BIOMETHANE_HEATING,
      BIOMETHANE_TRANSPORT,
      CCM,
      EU_ETS,
      VCM,
      HBE,
      THG_Q,
      RTFC,
      BIOFUELS,
      F_GAS,
      PPA,
      CFC,
      I_REC,
      REFRIGERANT_GAS,
      MANAGEMENT,
    });
    const filteredSelectedRoom = ref<number>(0);
    const breakInfinityReq = ref(true);
    const worksOneTimes = ref(false);

    // Other variables
    const currentUserId = userStore?.userProfile?.id ?? '';
    const getPageTerms = getTranslationTerms.bind(null, 'pages', 'chat');
    const hasSelectedTeam = computed(() => advisorsFirstName.value && advisorsLastName.value);
    const representativeProfilePathClient = `${USER_ROLE_ROUTES.CLIENT_PROFILE.path}/`;
    const representativeProfilePathAdvisors = `${USER_ROLE_ROUTES.SUSTAINABILITY_ADVISORS.path}/`;
    const searchValue = ref('');
    const roomsScrollLoading = ref(false);

    const accountsListPage = ref(1);
    const accountsResult = ref<GetUsersChatQuery>({});
    const accountsLastPage = computed(
      () => accountsResult.value?.accounts?.paginationInfo.lastPage,
    );

    const hasActiveScrollbar = (targetElement: HTMLElement): boolean => {
      if (targetElement) {
        return targetElement.scrollHeight > targetElement.clientHeight;
      }
      return false;
    };

    let fetchNextAccounts: null | (() => void);
    const getAccountsSa = async (searchLoading = true) => {
      if (searchLoading) {
        searchRoomsLoading.value = true;
      } else {
        loadingRooms.value = true;
      }
      const {
        onError,
        onResult,
        fetchMore: fetchMoreAccounts,
      } = useGetUsersChatQuery(
        {
          type: UserRole.ROLE_SUSTAINABILITY_ADVISOR,
          sustainabilityAdvisorProducts_product_id: !isBSuppRole.value ? product.value?.id : null,
          orderByFullName: 'ASC',
          itemsPerPage: 15,
          search: searchValue.value,
        },
        {
          fetchPolicy: 'cache-and-network',
        },
      );

      onResult((result) => {
        if (!result.loading) {
          if (result?.data?.accounts?.collection) {
            loadingRooms.value = false;
            searchRoomsLoading.value = false;
            accountsResult.value = result.data;
            nextTick(() => {
              const loadedRoom = vueAdvancedChat.value?.shadowRoot?.querySelector(
                '#infinite-loader-rooms',
              ) as HTMLElement;
              if (
                loadedRoom &&
                result?.data?.accounts?.paginationInfo.totalCount ===
                  result?.data?.accounts?.collection?.length
              ) {
                loadedRoom.style.display = 'none';
              }

              /* This part was moved here from watchEffect because in watchEffect on searchValue
              change this code is run before actual component update */
              const roomEmptyList = vueAdvancedChat.value?.shadowRoot?.querySelector(
                '#rooms-list',
              ) as HTMLElement;
              const searchEmptyElement = document.getElementById('searchEmpty');
              if (searchValue.value && !result?.data?.accounts?.collection?.length) {
                if (roomEmptyList) {
                  roomEmptyList.style.display = 'none';
                }

                if (searchEmptyElement) {
                  searchEmptyElement.style.display = 'block';
                }
              } else {
                if (roomEmptyList) {
                  roomEmptyList.style.display = 'block';
                  // setTimeout here to be sure that rooms list is fully rendered. nexTick does not help
                  setTimeout(() => {
                    if (
                      roomEmptyList.clientHeight &&
                      !hasActiveScrollbar(roomEmptyList) &&
                      fetchNextAccounts
                    ) {
                      accountsListPage.value += 1;
                      fetchNextAccounts();
                    }
                  }, 100);
                }
                if (searchEmptyElement) {
                  searchEmptyElement.style.display = 'none';
                }
              }
            });
          }
        }
      });
      onError(async (error) => {
        loadingRooms.value = true;
        if (error && (error?.networkError as NetworkError)) {
          networkErrorHandler();
        }
        graphQLErrorHandler(error?.graphQLErrors);
      });

      fetchNextAccounts = async () => {
        if (accountsLastPage.value && accountsListPage.value <= accountsLastPage.value) {
          roomsScrollLoading.value = true;
          await fetchMoreAccounts({
            variables: {
              page: accountsListPage.value,
            },
            updateQuery: (previousQueryResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return previousQueryResult;
              return {
                ...previousQueryResult,
                accounts: {
                  __typename: fetchMoreResult.accounts?.__typename,
                  paginationInfo: {
                    __typename: fetchMoreResult.accounts?.paginationInfo.__typename,
                    lastPage: fetchMoreResult.accounts?.paginationInfo.lastPage || 1,
                    totalCount: fetchMoreResult.accounts?.paginationInfo.totalCount || 1,
                    itemsPerPage: fetchMoreResult.accounts?.paginationInfo.itemsPerPage || 1,
                  },
                  collection: [
                    ...(previousQueryResult.accounts?.collection || []),
                    ...(fetchMoreResult.accounts?.collection || []),
                  ],
                },
              };
            },
          });
          nextTick(() => {
            roomsScrollLoading.value = false;
          });
        }
      };
    };

    const productsCR = ref<SelectedProductType[] | undefined>([]);
    const getCrProducts = async () => {
      productsCR.value = await getAccount();
    };
    if (isCompanyRepresentative.value) {
      getCrProducts();
    }

    const sustainabilityAdvisors = computed(() => {
      if (accountsResult?.value?.accounts?.collection?.length) {
        const receivedConsultants = accountsResult.value.accounts.collection.filter(
          (advisor) => advisor?.id !== currentUserId,
        );

        return receivedConsultants.map((item) => ({
          id: item?.id,
          firstName: item?.firstName,
          lastName: item?.lastName,
        }));
      }

      return [];
    });

    const getClientCompanyId = computed(() => {
      const selected = selectedRoomId.value || chatRoomsId.value;
      const queryResult =
        activeTab.value === 'team' ? selectedTeamRooms.value : chatRoomsResult.value;
      const companyId = queryResult?.chatRooms?.collection?.find((item) => item?.id === selected)
        ?.clientCompany?.id as string;
      return companyId;
    });
    const hasChatDocuments = ref<boolean>(false);
    const getClientDocuments = async (clientCompanyId: string, categoryId: string) => {
      const {
        load: loadClientDocs,
        onResult,
        onError,
      } = useClientCategoryDocumentsLazyQuery(
        {
          clientCompany: clientCompanyId,
          id: categoryId,
          documentStatus: 'published',
          itemsPerPage: 1,
          page: 1,
          updatedAtOrder: 'DESC',
        },
        {
          fetchPolicy: 'cache-and-network',
          context: {
            headers: {
              'client-view': 0,
              'Client-View-Company-Id': '',
            },
          },
        },
      );

      onResult((result) => {
        if (!result.loading) {
          if (result.data.clientDocumentCategory?.clientCompanyDocuments?.collection?.length) {
            hasChatDocuments.value = true;
          } else {
            hasChatDocuments.value = false;
          }
        }
      });

      onError(async (error) => {
        if (error && (error?.networkError as NetworkError)) {
          networkErrorHandler();
        }
        graphQLErrorHandler(error?.graphQLErrors);
      });
      loadClientDocs();
    };
    const getClientDocumentsCategoriesNames = async (clientCompanyId: string) => {
      const { load, onResult, onError } = useClientDocumentCategoriesNamesLazyQuery(
        {
          clientCompany: clientCompanyId || null,
        },
        {
          context: {
            headers: {
              'client-view': 0,
              'Client-View-Company-Id': '',
            },
          },
        },
      );

      onResult((result) => {
        categoriesLoading.value = result.loading;
        if (!result.loading && result.data.clientDocumentCategories?.edges?.length) {
          categoriesList.value = result.data.clientDocumentCategories?.edges
            .filter((category) => category?.node?.name === 'Chat')
            .map((category) => ({
              id: category?.node?.id ?? '',
              name: category?.node?.name ?? '',
            }));

          if (categoriesList.value.length > 0 && messagesStore?.getSelectedIdNotification) {
            if (clientCompanyId && categoriesList.value[0].id) {
              getClientDocuments(clientCompanyId, categoriesList.value[0].id);
            }
            chatCategoryId.value = categoriesList.value[0].id;
          }
        }
      });

      onError(async (error) => {
        categoriesLoading.value = false;
        if (error && (error?.networkError as NetworkError)) {
          networkErrorHandler();
        }
        graphQLErrorHandler(error?.graphQLErrors);
      });
      load();
    };

    const currentUserName = computed(
      () => `${userStore?.userProfile.firstName} ${userStore?.userProfile.lastName}`,
    );

    const hideMessagesEmptyState = (messageLength: number) => {
      const loadedMessages = vueAdvancedChat.value?.shadowRoot?.querySelector(
        '#vac-circle',
      ) as HTMLElement;
      const chatMessageEdgesLength = messageLength || 0;
      showEmptyState.value = !chatMessageEdgesLength;

      if (loadedMessages) {
        loadedMessages.style.display = showEmptyState.value ? 'none' : 'block';
      }
    };

    const scrollToUnreadMessages = () => {
      if (filteredSelectedRoom.value > 5) {
        let unreadPosition = 0;
        const scrollNewMessage = Array.from(
          vueAdvancedChat.value?.shadowRoot?.querySelectorAll<HTMLElement>('.vac-line-new') || [],
        ).findLast((n) => n);
        if (scrollNewMessage) {
          unreadPosition = Math.round(scrollNewMessage.getBoundingClientRect().bottom);
        }
        const messagesListScroll = vueAdvancedChat.value?.shadowRoot?.querySelector(
          '#messages-list',
        ) as HTMLElement;
        if (messagesListScroll && !chatMessagesLoading.value) {
          setTimeout(() => {
            messagesListScroll.scrollTop = unreadPosition - 300;
          }, 100);
        }
      }
    };
    let fetchNextMessages: null | (() => void);
    const nextMessagesLoading = ref(false);

    const getChatMessages = async (chatRoomId: string, showLoader = true) => {
      if (showLoader) {
        chatMessagesLoading.value = true;
      }
      breakInfinityReq.value = false;
      const {
        onResult,
        onError,
        fetchMore: fetchMoreMessages,
      } = useChatMessagesQuery(
        {
          chatRoom: chatRoomId,
          page: messagesPage.value,
          itemsPerPage: messagesItemsPerPage.value,
        },
        {
          fetchPolicy: 'cache-and-network',
          context: {
            headers: {
              'client-view': 0,
              'Client-View-Company-Id': '',
            },
          },
        },
      );

      onResult((result) => {
        if (!result.loading) {
          hideMessagesEmptyState(Number(result?.data?.chatMessages?.collection?.length));
          chatMessagesResult.value = result.data;
          if (result.data) {
            totalMessagesCount.value =
              chatMessagesResult.value?.chatMessages?.paginationInfo.lastPage || 0;
            messages.value = (chatMessagesResult?.value?.chatMessages?.collection || [])
              .map((item, index: number) => {
                const textAvatar = `https://ui-avatars.com/api/?name=${item?.sender?.firstName} ${item?.sender?.lastName}`;
                const fileName = item?.document?.title;
                let fileTitle = '';
                let fileExtension = '';
                let fileType = '';
                if (fileName) {
                  const lastDotIndex = fileName.lastIndexOf('.');
                  if (lastDotIndex !== -1) {
                    fileTitle = fileName.substring(0, lastDotIndex) as string;
                    fileExtension = fileName.substring(lastDotIndex + 1);
                    if (
                      fileExtension === 'svg' ||
                      fileExtension === 'png' ||
                      fileExtension === 'jpeg' ||
                      fileExtension === 'jpg' ||
                      fileExtension === 'jfif'
                    ) {
                      fileType = `image/${fileExtension}`;
                    } else if (fileExtension === 'csv') {
                      fileType = `text/${fileExtension}`;
                    } else {
                      fileType = `application/${fileExtension}`;
                    }
                  }
                }
                return {
                  _id: item?.id ?? '',
                  senderId: item?.sender?.id ?? '',
                  content: item?.content,
                  username: `${item?.sender?.firstName} ${item?.sender?.middleName || ''} ${
                    item?.sender?.lastName
                  }`,
                  avatar: item?.sender?.avatar?.contentUrl || textAvatar,
                  date: getFormattedDate(item?.sentAt ?? ''),
                  timestamp: formatChatTimestamp(item?.sentAt ?? ''),
                  seen: !(filteredSelectedRoom.value > index),
                  files: item?.document
                    ? [
                        {
                          id: item?.document?.id || '',
                          name: fileTitle || 'file',
                          type: fileType,
                          extension: fileExtension,
                          url: item?.document?.contentUrl || '',
                        },
                      ]
                    : [],
                };
              })
              .reverse();
            nextTick(() => scrollToUnreadMessages());
          } else {
            messages.value = [];
          }
          nextTick(() => {
            let targetElement: HTMLElement | null = null;
            if (isCompanyRepresentative.value) {
              targetElement = document.getElementById('vueAdvancedChatIdForCR');
            } else if (minimizedChat.value) {
              targetElement = document.getElementById('vueAdvancedChatIdForMinimized');
            } else {
              targetElement = null;
            }
            if (isCompanyRepresentative.value || minimizedChat.value) {
              if (targetElement) {
                targetElement.classList.add('vueAdvancedHeight');
              }
              const vacMessages = vueAdvancedChat.value?.shadowRoot?.querySelector(
                '.vac-col-messages',
              ) as HTMLElement;
              const vacRooms = vueAdvancedChat.value?.shadowRoot?.querySelector(
                '.vac-rooms-container',
              ) as HTMLElement;
              const isCRHeader = document.getElementById('isCRHeader');
              const isSelectedItem = document.getElementById('selected_item');
              const messagesEmptyState = document.getElementById('messagesEmptyState');

              if (vacMessages) {
                vacMessages.classList.add('added-message-content');
              }
              if (isCRHeader) {
                isCRHeader.style.display = 'none';
              }
              if (isSelectedItem) {
                isSelectedItem.style.display = 'block';
              }
              if (messagesEmptyState) {
                messagesEmptyState.style.display = 'flex';
              }
              if (vacRooms) {
                vacRooms.classList.add('added-rooms-content');
                vacRooms.style.display = 'none';
              }
            }
            if (isUnreadMessages.value) {
              scrollToUnreadMessages();
            }
          });
          breakInfinityReq.value = true;
          worksOneTimes.value = true;
          chatMessagesLoading.value = false;
        }
      });
      onError(async (error) => {
        if (chatRoomId.split('/')[2] !== 'accounts') {
          chatMessagesLoading.value = false;
          if (error && (error?.networkError as NetworkError)) {
            networkErrorHandler();
          }
          graphQLErrorHandler(error?.graphQLErrors);
        }
      });
      fetchNextMessages = async () => {
        if (totalMessagesCount.value && messagesPage.value <= totalMessagesCount.value) {
          nextMessagesLoading.value = true;
          await fetchMoreMessages({
            variables: {
              page: messagesPage.value,
            },
            updateQuery: (previousQueryResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return previousQueryResult;
              return {
                ...previousQueryResult,
                chatMessages: {
                  __typename: fetchMoreResult.chatMessages?.__typename,
                  paginationInfo: {
                    __typename: fetchMoreResult.chatMessages?.paginationInfo.__typename,
                    lastPage: fetchMoreResult.chatMessages?.paginationInfo.lastPage || 1,
                    totalCount: fetchMoreResult.chatMessages?.paginationInfo.totalCount || 1,
                    itemsPerPage: fetchMoreResult.chatMessages?.paginationInfo.itemsPerPage || 1,
                  },
                  collection: [
                    ...(previousQueryResult.chatMessages?.collection || []),
                    ...(fetchMoreResult.chatMessages?.collection || []),
                  ],
                },
              };
            },
          });
          nextTick(() => {
            nextMessagesLoading.value = false;
          });
        }
      };
    };
    let fetchNextRooms: null | (() => void);
    const roomsLastPage = computed(() =>
      hasSelectedTeam.value
        ? selectedTeamRooms.value.chatRooms?.paginationInfo.lastPage
        : chatRoomsResult.value.chatRooms?.paginationInfo.lastPage,
    );

    const getChatRooms = async (searchLoading = false) => {
      reqAgain.value = false;
      if (searchLoading) {
        searchRoomsLoading.value = true;
      } else {
        loadingRooms.value = true;
      }
      const currentUser = currentUserName.value.split(' ') || '';
      const firstName = isBSuppRole.value || isManager.value ? '' : currentUser[0];
      const lastName = isBSuppRole.value || isManager.value ? '' : currentUser[1];
      const {
        onResult,
        onError,
        fetchMore: fetchMoreRooms,
      } = useChatRoomQuery(
        {
          participants_firstName: advisorsFirstName.value ? advisorsFirstName.value : firstName,
          participants_lastName: advisorsLastName.value ? advisorsLastName.value : lastName,
          page: roomListPage.value,
          search: searchValue.value,
        },
        {
          fetchPolicy: 'cache-and-network',
          enabled: isBSuppRole.value || Boolean(product.value?.id),
          context: {
            headers: {
              'client-view': 0,
              'Client-View-Company-Id': '',
            },
          },
        },
      );
      onResult((result) => {
        if (!result.loading) {
          if (
            !chatMessagesLoading.value &&
            oldChatRoomId.value &&
            oldChatRoomId.value.split('/')[2] !== 'accounts'
          ) {
            const roomElements =
              vueAdvancedChat.value?.shadowRoot?.querySelectorAll<HTMLElement>('.vac-room-item');
            if (roomElements) {
              roomElements.forEach((element: Element) => {
                const htmlElement = element as HTMLElement;
                if (htmlElement && htmlElement.id === oldChatRoomId.value) {
                  setTimeout(() => {
                    htmlElement.click();
                    getChatMessages(oldChatRoomId.value);
                  });
                }
              });
            }
          }
          reqAgain.value = true;
          loadingRooms.value = result.loading;
          searchRoomsLoading.value = false;
          if (result?.data?.chatRooms?.collection) {
            totalRoomsCount.value = result.data.chatRooms.paginationInfo.totalCount;
            const value: ChatRoomQuery = JSON.parse(JSON.stringify(result.data));
            if (hasSelectedTeam.value) {
              selectedTeamRooms.value = value;
            } else {
              chatRoomsResult.value = value;
            }
            // nextTick was added to make sure that #infinite-loader-rooms exist and can be targeted
            nextTick(() => {
              const loadedRoom = vueAdvancedChat.value?.shadowRoot?.querySelector(
                '#infinite-loader-rooms',
              ) as HTMLElement;
              if (
                loadedRoom &&
                result?.data?.chatRooms?.paginationInfo.totalCount ===
                  result?.data?.chatRooms?.collection?.length
              ) {
                loadedRoom.style.display = 'none';
              }

              /* This part was moved here from watchEffect because in watchEffect on searchValue
              change this code is run before actual component update */
              const roomEmptyList = vueAdvancedChat.value?.shadowRoot?.querySelector(
                '#rooms-list',
              ) as HTMLElement;
              const searchEmptyElement = document.getElementById('searchEmpty');
              if (searchValue.value && !result?.data?.chatRooms?.collection?.length) {
                if (roomEmptyList) {
                  roomEmptyList.style.display = 'none';
                }

                if (searchEmptyElement) {
                  searchEmptyElement.style.display = 'block';
                }
              } else {
                if (roomEmptyList) {
                  roomEmptyList.style.display = 'block';
                  // setTimeout here to be sure that rooms list is fully rendered. nexTick does not help
                  setTimeout(() => {
                    if (
                      roomEmptyList.clientHeight &&
                      !hasActiveScrollbar(roomEmptyList) &&
                      fetchNextRooms
                    ) {
                      fetchNextRooms();
                    }
                  }, 100);
                }
                if (searchEmptyElement) {
                  searchEmptyElement.style.display = 'none';
                }
              }
            });
          }

          chatRoomsResult.value.chatRooms?.collection?.forEach((item) => {
            updatedUnreadMessagesCount(item?.id as string, Number(item?.unreadCount));
          });
        }
      });
      onError(async (error) => {
        loadingRooms.value = false;
        searchRoomsLoading.value = false;
        if (error && (error?.networkError as NetworkError)) {
          networkErrorHandler();
        }
        graphQLErrorHandler(error?.graphQLErrors);
      });
      fetchNextRooms = async () => {
        if (roomsLastPage.value && roomListPage.value < roomsLastPage.value) {
          roomListPage.value += 1;
          roomsScrollLoading.value = true;
          await fetchMoreRooms({
            variables: {
              page: roomListPage.value,
            },
            updateQuery: (previousQueryResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return previousQueryResult;
              return {
                ...previousQueryResult,
                chatRooms: {
                  __typename: fetchMoreResult.chatRooms?.__typename,
                  paginationInfo: {
                    __typename: fetchMoreResult.chatRooms?.paginationInfo.__typename,
                    lastPage: fetchMoreResult.chatRooms?.paginationInfo.lastPage || 1,
                    totalCount: fetchMoreResult.chatRooms?.paginationInfo.totalCount || 1,
                    itemsPerPage: fetchMoreResult.chatRooms?.paginationInfo.itemsPerPage || 1,
                  },
                  collection: [
                    ...(previousQueryResult.chatRooms?.collection || []),
                    ...(fetchMoreResult.chatRooms?.collection || []),
                  ],
                },
              };
            },
          });
          nextTick(() => {
            roomsScrollLoading.value = false;
          });
        }
      };
    };

    const tabItems = computed(() => {
      const tabsRole = [];
      const recentPageTerms = getPageTerms('recent');
      const teamPageTerms = getPageTerms('team');
      if (isBSuppRole.value) {
        tabsRole.push(
          { name: recentPageTerms, value: 'recent' },
          { name: teamPageTerms, value: 'team' },
        );
      } else {
        const personalPageTerms = getPageTerms('personal');
        tabsRole.push(
          { name: personalPageTerms, value: 'personal' },
          { name: teamPageTerms, value: 'team' },
        );
      }
      return tabsRole;
    });

    const participantsChat = computed(() => {
      const chatMembers: chatMembersType[] = [];
      const roomsData =
        activeTab.value === 'team' && hasSelectedTeam.value
          ? selectedTeamRooms.value
          : chatRoomsResult.value;
      roomsData?.chatRooms?.collection?.forEach((item) => {
        if (chatRoomsId.value === item?.id) {
          item?.participants?.collection?.forEach((members) => {
            const textAvatar = `https://ui-avatars.com/api/?name=${members?.firstName}+${members?.lastName}`;
            chatMembers.push({
              _id: members?.id,
              username: `${members?.firstName} ${members?.lastName}`,
              avatar: members?.avatar?.contentUrl || textAvatar,
              clientCompany: members?.clientCompany?.legalName ?? 'AFS Energy',
              roles: members?.roles?.[0],
            });
          });
        }
        chatMembers?.sort((a, b) => {
          if (a.roles === 'ROLE_COMPANY_REPRESENTATIVE') {
            return -1;
          }
          if (b.roles === 'ROLE_COMPANY_REPRESENTATIVE') {
            return 1;
          }
          return 0;
        });
      });
      return chatMembers;
    });

    const getUnreadMessagesAllCounts = (roomId: string) =>
      messagesStore?.getUnreadMessagesCounts[roomId] || 0;

    const handleSearchChange = (value: string) => {
      searchValue.value = value;
    };

    const filteredData = computed(() => {
      const chatRooms = chatRoomsResult.value?.chatRooms?.collection;
      const selectedAdvisors = selectedTeamRooms.value.chatRooms?.collection;
      if (activeTab.value === 'team' && hasSelectedTeam.value) {
        return selectedAdvisors;
      }
      return chatRooms;
    });

    const textMessages = computed(() => ({
      ROOMS_EMPTY: getPageTerms('noSustainabilityAdvisorsFound'),
      ROOM_EMPTY: getPageTerms('noMessages'),
      NEW_MESSAGES: getPageTerms('unreadMessages'),
      MESSAGE_DELETED: '',
      MESSAGES_EMPTY: '',
      CONVERSATION_STARTED: '',
      TYPE_MESSAGE: getPageTerms('messagePlaceholder'),
      SEARCH:
        activeTab.value === 'team' && !isAdvisorsChat.value
          ? getPageTerms('searchForAdvisor')
          : getPageTerms('searchForClient'),
      IS_ONLINE: '',
      LAST_SEEN: '',
      IS_TYPING: '',
      CANCEL_SELECT_MESSAGE: '',
    }));

    const defaultRoomList = [
      {
        roomId: '',
        roomName: '',
        users: [],
        date: '',
        unreadCount: 0,
        sortingDate: '',
        avatar: '',
        lastMessage: {
          content: '',
          senderId: '',
          timestamp: '',
        },
      },
    ];

    const rooms = computed(() => {
      const selectedAdvisors = selectedTeamRooms.value.chatRooms?.collection;
      // sustainabilityAdvisors
      if (
        activeTab.value === 'team' &&
        (!hasSelectedTeam.value ||
          (hasSelectedTeam.value && !selectedAdvisors?.length && !searchValue.value))
      ) {
        return sustainabilityAdvisors.value?.map((room) => ({
          roomId: room?.id || '',
          roomName: `${room?.firstName} ${room?.lastName}` || '',
          users: [],
          date: '',
          unreadCount: 0,
          sortingDate: '',
          avatar: '',
          lastMessage: {
            content: '',
            senderId: '',
            timestamp: '',
          },
        }));
      }
      // recent and personal case
      return filteredData.value?.map((item) => {
        const messageList = messagesStore?.getMessages[item?.id || 0];
        const lastMessage =
          messageList && messageList.length > 0 ? messageList[messageList.length - 1] : null;
        return {
          roomId: item?.id,
          roomName:
            isCompanyRepresentative.value && !userStore.getClientCompanyId
              ? `${item?.name.replace(' - ', ' (')})`
                  .slice(`${item?.name.replace(' - ', ' (')})`.indexOf(' ('))
                  ?.replace('(', '')
                  ?.replaceAll(')', '')
                  .replaceAll('_', '-')
              : item?.name?.replaceAll('_', ' - ') || '',
          date: getFormattedDate(lastMessage?.date || item?.lastMessageTime || ''),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          sortingDate: lastMessage?.sortingDate || item?.lastMessageTime,
          unreadCount: isBSuppRole.value ? 0 : getUnreadMessagesAllCounts(item?.id || ''),
          clientCompanyId: item?.clientCompany.id,
          avatar:
            isCompanyRepresentative.value || minimizedChat.value
              ? Object.entries(images.value)?.find(([key]) => {
                  const itemName = `${item?.name?.replace(' - ', ' (')})`;
                  const startIndex = itemName?.indexOf('(');
                  const endIndex = itemName?.indexOf(' )');
                  const formattedItemName = itemName
                    ?.slice(startIndex + 1, endIndex)
                    ?.toUpperCase();
                  return key === formattedItemName?.replace(')', '');
                })?.[1]
              : '',
          lastMessage: {
            content: lastMessage?.content || item?.lastMessageContent || '',
            senderId: '',
            timestamp: lastMessage?.timestamp
              ? lastMessage?.timestamp
              : formatChatTimestamp(item?.lastMessageTime || ''),
          },
          users: participantsChat.value || [],
        };
      });
    });

    const openMembersPopup = () => {
      isShowMembers.value = !isShowMembers.value;
      isOpenDropDown.value = false;
    };

    const onClickAway = () => {
      isShowMembers.value = false;
    };

    const resetChatRoom = () => {
      let targetElement: HTMLElement | null = null;
      if (isCompanyRepresentative.value) {
        targetElement = document.getElementById('vueAdvancedChatIdForCR');
      } else if (minimizedChat.value) {
        targetElement = document.getElementById('vueAdvancedChatIdForMinimized');
      } else {
        targetElement = null;
      }
      if (isCompanyRepresentative.value || minimizedChat.value) {
        if (targetElement) {
          targetElement.classList.remove('vueAdvancedHeight');
        }
        const vacMessages = vueAdvancedChat.value?.shadowRoot?.querySelector(
          '.vac-col-messages',
        ) as HTMLElement;
        const vacRooms = vueAdvancedChat.value?.shadowRoot?.querySelector(
          '.vac-rooms-container',
        ) as HTMLElement;
        const isCRHeader = document.getElementById('isCRHeader');
        const isSelectedItem = document.getElementById('selected_item');
        const messagesEmptyState = document.getElementById('messagesEmptyState');
        if (vacMessages) {
          vacMessages.classList.remove('added-message-content');
        }
        if (isCRHeader) {
          isCRHeader.style.display = 'flex';
        }
        if (isSelectedItem) {
          isSelectedItem.style.display = 'none';
        }
        if (messagesEmptyState) {
          messagesEmptyState.style.display = 'none';
        }
        if (vacRooms) {
          vacRooms.classList.remove('added-rooms-content');
          vacRooms.style.display = 'flex';
        }
      }
    };

    const openPopup = () => {
      roomListPage.value = 1;
      getChatRooms();
      toggleChat(!isChatOpen.value);
      isShowMembers.value = false;
      isOpenSelectedRoom.value = false;
      if (isCompanyRepresentative.value || minimizedChat.value) {
        setSelectedIdNotification('');
      }
      if (!isChatOpen.value) {
        setSelectedIdNotification('');
        if (isCompanyRepresentative.value) {
          const urlQueryParamMode = new URLSearchParams(window.location.search).get('mode');
          if (urlQueryParamMode === 'chat') {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.delete('mode');
            const newURL = `${window.location.pathname}${searchParams.toString()}${
              window.location.hash
            }`;
            window.history.replaceState({}, '', newURL);
          }
        }
      } else if (isCompanyRepresentative.value) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('mode', 'chat');
        const newURL = `${window.location.pathname}?${searchParams.toString()}${
          window.location.hash
        }`;
        window.history.replaceState({}, '', newURL);
      }
      isPrevent.value = false;
      focusedChatRoomId.value = '';
      resetChatRoom();
      isScrollingDisabled.value = false;
      ctx.emit('on-close-selected-chat-room');
      isOpenDropDown.value = false;
    };

    const hasUnreadMessage = computed(() =>
      rooms.value?.some((item) => Number(item?.unreadCount) > 0),
    );

    const fetchMessages = async (details: detailsType) => {
      const companyID = details?.room?.clientCompanyId as string;
      if (isOpenSelectedRoom.value || (!isOpenSelectedRoom.value && chatModuleRoute.value)) {
        const searchInput = vueAdvancedChat.value?.shadowRoot?.querySelector(
          '.vac-input',
        ) as HTMLInputElement;
        const messagesList = vueAdvancedChat.value?.shadowRoot?.querySelector(
          '#messages-list',
        ) as HTMLElement;
        const selectedIcon = vueAdvancedChat.value?.shadowRoot?.querySelector(
          '.vac-icon-scroll',
        ) as HTMLElement;
        const roomId = details?.room?.roomId ?? '';
        // --------unread messages---------
        if (getUnreadMessagesAllCounts(roomId) !== 0) {
          filteredSelectedRoom.value = Number(getUnreadMessagesAllCounts(roomId));
          isUnreadMessages.value = true;
        }
        messagesItemsPerPage.value =
          Number(filteredSelectedRoom.value) > messagesItemsPerPage.value
            ? Number(filteredSelectedRoom.value) + 15
            : messagesItemsPerPage.value;
        if (activeTab.value === 'team') {
          selectedRoomId.value = details?.room?.roomId ?? '';
          focusedChatRoomId.value = selectedRoomId.value;
        }
        chatRoomsId.value = roomId;
        messages.value = [];
        removeUnreadMessagesCount(roomId);
        resetMessages();
        const isCRselectedId =
          !isChatOpen.value && (isCompanyRepresentative.value || minimizedChat.value) ? '' : roomId;
        setSelectedIdNotification(isCRselectedId as string);
        chatRoomType.value = roomId.split('/')[2] as string;
        if (chatRoomType.value !== 'accounts' && roomId) {
          worksOneTimes.value = false;
          messagesPage.value = 1;
          await getChatMessages(roomId);
        }
        if (
          chatRoomsResult.value?.chatRooms?.collection &&
          (!isCompanyRepresentative.value || !minimizedChat.value)
        ) {
          const findRoom = chatRoomsResult.value?.chatRooms?.collection?.findIndex(
            (x) => x?.id === roomId,
          ) as number;
          if (findRoom !== -1) {
            const findChatRoom = chatRoomsResult.value.chatRooms.collection[findRoom];
            if (findChatRoom) {
              findChatRoom.unreadCount = 0;
            }
          }
        }
        isJoinChat.value = participantsChat.value.some((item) => item._id === currentUserId);
        if (!isJoinChat.value && (isBSuppRole.value || isManager.value)) {
          if (messagesList) {
            messagesList.classList.add('messagesList');
          }
          if (selectedIcon) {
            selectedIcon.classList.add('selectedIcon');
          }
        } else {
          if (messagesList) {
            messagesList.classList.remove('messagesList');
          }
          if (selectedIcon) {
            selectedIcon.classList.remove('selectedIcon');
          }
        }
        if (searchInput) {
          if (chatRoomType.value === 'accounts' && !isAdvisorsChat.value) {
            searchInput.placeholder = getPageTerms('searchForAdvisor');
          } else {
            searchInput.placeholder = getPageTerms('searchForClient');
          }
        }
      }
      if (companyID) {
        getClientDocumentsCategoriesNames(companyID);
      }
    };

    const onSelectRoom = (selectedId: string, selectedName: string) => {
      oldChatRoomId.value = selectedId;
      hasChatDocuments.value = false;
      const pattern = /\(([^)]+)\)/;
      if (selectedName.length && isCompanyRepresentative.value && selectedName.match !== null) {
        const matchResult = selectedName?.match(pattern);
        if (matchResult) {
          chatRoomName.value = matchResult[1]?.replaceAll(' ', '');
        }
      }
      focusedChatRoomId.value = selectedId;
      if (isCompanyRepresentative.value || minimizedChat.value) {
        isOpenSelectedRoom.value = true;
      }
      const details = {
        options: {
          reset: true,
        },
        room: {
          avatar: '',
          date: '',
          lastMessage: {
            content: '',
            senderId: '',
            timestamp: '',
          },
          roomId: selectedId,
          roomName: selectedName || '',
          unreadCount: 0,
          users: [],
          clientCompanyId: getClientCompanyId.value || '',
        },
      } as detailsType;
      fetchMessages(details);
      inChatRoom.value = true;
      isShowMembersAndFiles.value = false;
      const searchInput = vueAdvancedChat.value?.shadowRoot?.querySelector(
        '.vac-input',
      ) as HTMLInputElement;
      if (activeTab.value === 'team') {
        const roomType = selectedId.split('/')[2] as string;
        chatRoomType.value = roomType;
        const selectedAdvisor = selectedName.split(' ') || '';
        if (roomType === 'accounts') {
          if (searchInput && isAdvisorsChat.value) {
            searchInput.placeholder = getPageTerms('searchForAdvisor');
          } else {
            searchInput.placeholder = getPageTerms('searchForClient');
          }
          advisorsFirstName.value = selectedAdvisor[0].trim() || '';
          advisorsLastName.value = selectedName.replace(selectedAdvisor[0], '').trim() || '';
          isAdvisorsChat.value = true;
          searchInput.value = '';
          handleSearchChange('');
          roomListPage.value = 1;
          getChatRooms();
        }
        if (searchInput && isAdvisorsChat.value) {
          searchInput.placeholder = getPageTerms('searchForClient');
        }
      }
      if (
        chatRoomsResult.value?.chatRooms?.collection &&
        (isCompanyRepresentative.value || !minimizedChat.value)
      ) {
        const findRoom = chatRoomsResult.value?.chatRooms?.collection?.findIndex(
          (x) => x?.id === selectedId,
        ) as number;
        if (findRoom !== -1) {
          const findChatRoom = chatRoomsResult.value.chatRooms.collection[findRoom];
          if (findChatRoom) {
            findChatRoom.unreadCount = 0;
          }
        }
      }
      isOpenDropDown.value = false;
      if (!isCompanyRepresentative.value && !minimizedChat.value) {
        isUnreadMessages.value = false;
      }
    };

    const allSelectedChatMessages = computed(() => {
      const selectedRoomIdValue = focusedChatRoomId.value || chatRoomsId.value;
      const otherMessages = messagesStore?.getMessages[selectedRoomIdValue] || [];

      // Here we add a filter to otherMessages to avoid displaying the same message from the query and from web-sockets.
      return messages.value.concat(
        otherMessages.filter(
          (otherMessage) =>
            !messages.value.map((message) => message._id).includes(otherMessage._id),
        ),
      );
    });

    const onClickPrevent = () => {
      isUnreadMessages.value = false;
      chatRoomsId.value = '';
      messages.value = [];
      filteredSelectedRoom.value = 0;
      isOpenDropDown.value = false;
      setSelectedIdNotification('');
      isPrevent.value = true;
      focusedChatRoomId.value = '';
      inChatRoom.value = false;
      isScrollingDisabled.value = false;
      oldChatRoomId.value = '';
      roomListPage.value = 1;
      isOpenSelectedRoom.value = false;
      getChatRooms();
      resetChatRoom();
      ctx.emit('on-close-selected-chat-room');
    };

    onUnmounted(() => {
      setSelectedIdNotification('');
    });

    const onScroll = (e: Event) => {
      setTimeout(() => {
        isScrollingDisabled.value = false;
      }, 3000);

      const target = e.target as HTMLElement;
      if (target.scrollTop === 0) {
        if (chatRoomsResult.value.chatRooms?.collection) {
          if (messagesPage.value < totalMessagesCount.value) {
            target.scrollTop = 300;
            if (
              chatRoomType.value !== 'accounts' &&
              !nextMessagesLoading.value &&
              fetchNextMessages
            ) {
              messagesPage.value += 1;
              worksOneTimes.value = false;
              messagesItemsPerPage.value = 30;
              filteredSelectedRoom.value = 0;
              fetchNextMessages();
            }
          }
          const findRoom = chatRoomsResult.value?.chatRooms?.collection?.findIndex(
            (x) => x?.id === chatRoomsId.value,
          ) as number;
          if (findRoom !== -1) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            chatRoomsResult.value.chatRooms.collection[findRoom].unreadCount = 0;
          }
        }
      }
    };

    const convertObjectToFile = async (object: MessageFile | undefined) => {
      if (!object) return null;
      const url = object.localUrl || '';
      const { type } = object;
      const { name } = object;

      const blob = await fetch(url).then((response) => response.blob());
      const file = new File([blob], name, { type });
      return file;
    };
    // Message type is extended here because it actually has roomId property but not specified in library type
    type MessageExtended = Message & { roomId: string };
    const fileUpload = async (message: MessageExtended): Promise<void> => {
      const requestData = new FormData();
      requestData.append('clientCompany', getClientCompanyId.value);
      requestData.append('file', (await convertObjectToFile(message?.files?.[0])) ?? '');
      requestData.append('title', `${message.files?.[0].name}.${message.files?.[0].extension}`);
      requestData.append('clientDocumentCategory', chatCategoryId.value);
      requestData.append('note', message?.content || '');
      requestData.append('chatRoom', message?.roomId || '');
      const res = (await axios
        .post(
          `${import.meta.env.VITE_PORTAL_GRAPHQL_URL}/${'client_company_documents'}`,
          requestData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        )
        // Unknown type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err) => ({ status: 400, error: err }))) as any;
      if (res.status !== 201) {
        addNotification({
          message: getPageTerms('uploadingIssue'),
          type: NotificationType.ERROR,
          showIcon: true,
        });
      } else {
        const chatDocuments = message?.files?.reverse();
        const chatFiles = [
          {
            name: chatDocuments?.[0].name || '',
            type: chatDocuments?.[0].type || '',
            extension: chatDocuments?.[0].extension || '',
            url: chatDocuments?.[0].localUrl || '',
          },
        ];

        const resFirstName = res.data.createdBy?.firstName || userStore.userProfile.firstName;
        const resLastName = res.data.createdBy?.lastName || userStore.userProfile.lastName;
        addMessage(
          {
            _id: `${res.data.chatRoom}`,
            content: `Uploaded file ${chatDocuments?.[0].name}`,
            senderId: currentUserId,
            username: `${resFirstName} ${resLastName}`,
            timestamp: formatChatTimestamp(
              res.data.createdAt || getUTCTimezoneDate(new Date()) || '',
            ),
            date: getFormattedDate(res.data.createdAt || getUTCTimezoneDate(new Date()) || ''),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sortingDate: res.data.createdAt || getUTCTimezoneDate(new Date()) || '',
            avatar:
              participantsChat.value.find((member) => member._id === userStore.userProfile.id)
                ?.avatar || `https://ui-avatars.com/api/?name=${resFirstName}+${resLastName}`,
            files: chatFiles,
          },
          message.roomId,
        );
      }
    };
    const sendNewMessage = (message: MessageExtended) => {
      const {
        mutate: mutateMessage,
        onDone: onDoneMessage,
        onError: onErrorMessage,
      } = useCreateChatMessageMutation({
        variables: {
          input: {
            content: message?.content ?? '',
            chatRoom: message?.roomId ?? '',
          },
        },
      });

      onDoneMessage(async (result) => {
        const resultData = result.data?.createChatMessage?.chatMessage;
        const textAvatar = `https://ui-avatars.com/api/?name=${resultData?.sender?.firstName}+${resultData?.sender?.lastName}`;
        filteredSelectedRoom.value = 0;
        addMessage(
          {
            _id: `${resultData?.id}`,
            content: resultData?.content,
            senderId: resultData?.sender.id || '',
            username: `${resultData?.sender?.firstName} ${resultData?.sender?.lastName}`,
            timestamp: formatChatTimestamp(resultData?.sentAt || ''),
            date: getFormattedDate(resultData?.sentAt || ''),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sortingDate: resultData?.sentAt || '',
            avatar: resultData?.sender?.avatar?.contentUrl || textAvatar,
          },
          message.roomId,
        );
      });

      onErrorMessage(async (error) => {
        logErrorMessages(error);
      });

      mutateMessage();
    };

    const sendMessage = (message: MessageExtended): void => {
      const fileTypes = ['jpeg', 'svg', 'jpg', 'png', 'pdf', 'docx', 'xlsx', 'xls', 'csv', 'xml'];
      const chatDocuments = message?.files?.reverse();
      if (chatDocuments && (chatDocuments?.[0]?.size || 0) > 52428800) {
        addNotification({
          message: getPageTerms('uploadingISizeError'),
          type: NotificationType.ERROR,
          showIcon: true,
        });
        return;
      }
      if (chatDocuments && !fileTypes.some((item) => item.includes(chatDocuments[0]?.extension))) {
        addNotification({
          message: getPageTerms('uploadingSupportedError'),
          type: NotificationType.ERROR,
          showIcon: true,
        });
        return;
      }
      if (message?.files?.length && !message?.content) {
        fileUpload(message);
        return;
      }
      if (message?.files?.length && message?.content) {
        fileUpload(message);
      }
      sendNewMessage(message);
    };

    const openFile = (fileData: FileData) => {
      downloadLink.value = fileData?.file?.file?.url;
      const fileExtension = fileData?.file?.file.extension;
      if (fileExtension === 'pdf') {
        window.open(downloadLink.value);
      } else {
        nextTick(() => {
          downloadElementRef.value?.click();
        });
      }
    };

    const joinChatRoom = async () => {
      const {
        mutate: joinRoom,
        onDone,
        onError,
      } = useJoinChatRoomMutation({
        variables: {
          input: {
            id: chatRoomsId.value,
          },
        },
      });

      onDone(async () => {
        getChatRooms();
        const joinRoomMessage = {
          roomId: chatRoomsId.value,
          content: `${currentUserName.value} ${getPageTerms('hasJoinChat')}`,
          _id: '',
          senderId: '',
        };
        sendNewMessage(joinRoomMessage);
        filteredSelectedRoom.value = 0;
        isJoinChat.value = true;
        const messagesList = vueAdvancedChat.value?.shadowRoot?.querySelector(
          '#messages-list',
        ) as HTMLInputElement;
        if (messagesList) {
          messagesList.classList.remove('messagesList');
        }
      });

      onError(async (error) => {
        logErrorMessages(error);
      });

      await joinRoom();
    };

    const chooseRoomsProduct = computed(() => {
      const roomProduct: RoomProduct = {
        clientCompany: null,
        id: '',
        name: '',
        abbr: '',
        isProductManager: undefined,
        available: false,
      };

      chatRoomsResult.value?.chatRooms?.collection?.forEach((item) => {
        if (chatRoomsId.value === item?.id) {
          const isProduct = item.product.sustainabilityAdvisors?.edges?.find(
            (el) =>
              el?.node?.account?.id === currentUserId && item?.product?.id === el.node.product?.id,
          );
          roomProduct.clientCompany = item?.clientCompany;
          roomProduct.id = item?.product?.id;
          roomProduct.name = item?.product.name;
          roomProduct.abbr = item?.product.abbreviation || '';
          roomProduct.isProductManager = isProduct?.node?.isProductManager;
          roomProduct.available = !isCompanyRepresentative.value;
        }
      });

      return roomProduct;
    });

    const selectProduct = async () => {
      setClientCompany(chooseRoomsProduct.value.clientCompany);
      setSelectedProduct(chooseRoomsProduct.value);

      const path = `${'/dashboard'}/${chooseRoomsProduct.value?.abbr?.toLowerCase()}/${
        !isCompanyRepresentative.value ? 'client-documents' : 'my-documents'
      }/chat`;
      await push({
        path,
      });
    };

    const hasModule = computed(() => {
      if (isCompanyRepresentative.value) {
        if (productsCR.value?.length) {
          const productModule = productsCR.value.find(
            (item: SelectedProductType) =>
              item.abbr === chatRoomName.value.replace('-', '_').toUpperCase(),
          );
          if (productModule) {
            return productModule.allowedModules?.some(
              (module: string) => module === MODULES_LIST.clientDoc,
            );
          }
        }
      }
      return false;
    });

    const onScrollRoomList = (e: Event) => {
      const target = e.target as HTMLElement;
      const scrollHeight = Number(target.scrollHeight);
      const scrollTop = Number(target.scrollTop);
      const clientHeight = Number(target.clientHeight);
      if (
        Math.floor(scrollHeight) - Math.floor(scrollTop) === Math.floor(clientHeight) ||
        Math.floor(scrollHeight) - Math.floor(scrollTop) - 5 < Math.floor(clientHeight)
      ) {
        if (
          (chatRoomsResult.value.chatRooms?.collection && activeTab.value !== 'team') ||
          (Object.keys(selectedTeamRooms.value).length !== 0 &&
            isAdvisorsChat.value &&
            activeTab.value === 'team')
        ) {
          if (
            chatRoomsResult.value?.chatRooms?.collection &&
            fetchNextRooms &&
            !roomsScrollLoading.value &&
            reqAgain.value
          ) {
            roomListPage.value += 1;
            fetchNextRooms();
          }
        } else if (fetchNextAccounts && !roomsScrollLoading.value) {
          accountsListPage.value += 1;
          fetchNextAccounts();
        }
      }
    };

    const isRoomCountEqualToChatRooms = computed(
      () =>
        chatRoomsResult.value?.chatRooms?.collection?.length === totalRoomsCount.value &&
        reqAgain.value,
    );
    const onOpenChatModule = () => {
      ctx.emit('on-open-chat-module', chatRoomsResult.value?.chatRooms?.collection?.length);
    };

    onMounted(() => {
      getChatRooms();
      const emptyRooms = vueAdvancedChat.value?.shadowRoot?.querySelector(
        '.vac-rooms-container .vac-rooms-empty',
      ) as HTMLElement;
      if (emptyRooms) {
        if (activeTab.value === 'team') {
          emptyRooms.innerText = getPageTerms('noAdvisors');
        } else {
          emptyRooms.innerText = getPageTerms('noSustainabilityAdvisorsFound');
        }
      }
      const vacRoomEmpty = vueAdvancedChat.value?.shadowRoot?.querySelector(
        '.vac-col-messages .vac-room-empty div',
      ) as HTMLElement;
      if (vacRoomEmpty) {
        if (isCompanyRepresentative.value) {
          vacRoomEmpty.innerText = getPageTerms('welcomeOurChat');
        } else {
          vacRoomEmpty.innerText = getPageTerms('noMessages');
        }
      }
    });

    const getSpecificRoom = (id: string) =>
      new Promise((resolve) => {
        const { load, onResult, onError } = useGetSpecificRoomLazyQuery({ id });
        const currentUser = currentUserName.value.split(' ') || '';
        const firstName = isBSuppRole.value || isManager.value ? '' : currentUser[0];
        const lastName = isBSuppRole.value || isManager.value ? '' : currentUser[1];
        onResult((result) => {
          if (!result.loading) {
            const room = result.data.chatRoom;
            if (room) {
              const queryVariables = {
                participants_firstName: advisorsFirstName.value
                  ? advisorsFirstName.value
                  : firstName,
                participants_lastName: advisorsLastName.value ? advisorsLastName.value : lastName,
                page: 1,
                search: searchValue.value,
              };
              const existingRooms = apolloClient.cache.readQuery<{
                chatRooms: ChatRoomPageConnection;
              }>({
                query: GET_CHATS_ROOMS,
                variables: queryVariables,
              });
              if (existingRooms?.chatRooms.collection) {
                const updatedRooms = [...existingRooms.chatRooms.collection, room];
                apolloClient.cache.writeQuery<{ chatRooms: ChatRoomPageConnection }>({
                  query: GET_CHATS_ROOMS,
                  data: {
                    ...existingRooms,
                    chatRooms: {
                      ...existingRooms.chatRooms,
                      collection: updatedRooms as ChatRoom[],
                    },
                  },
                  variables: queryVariables,
                });
              }
              nextTick(() => {
                resolve(true);
              });
            }
          }
        });
        onError(async (error) => {
          resolve(true);
          if (error && (error?.networkError as NetworkError)) {
            networkErrorHandler();
          }
          graphQLErrorHandler(error?.graphQLErrors);
        });
        load();
      });

    const openSelectedRoom = async () => {
      if (!isChatOpen.value) {
        toggleChat(!isChatOpen.value);
      }
      /* On new message notification click we check if needed room is already in loaded list.
      If room not present in list we fetch exactlly this room and merge to chatRooms query cached results.
      As a result needed room will be always present in rooms variable before runing onSelectRoom function */
      const targetRoom = rooms.value?.find(
        (item) => item?.roomId === props.getSelectedIdNotification,
      );
      if (!targetRoom?.roomId) {
        await getSpecificRoom(props.getSelectedIdNotification);
        setTimeout(() => {
          const roomElements =
            vueAdvancedChat.value?.shadowRoot?.querySelectorAll<HTMLElement>('.vac-room-item');
          if (roomElements) {
            roomElements?.forEach((element: Element) => {
              const htmlElement = element as HTMLElement;
              if (htmlElement.id === props.getSelectedIdNotification) {
                onSelectRoom(htmlElement?.id, htmlElement?.innerText);
                nextTick(() => {
                  htmlElement.click();
                });
              }
            });
          }
        }, 500);
      } else {
        const roomElements =
          vueAdvancedChat.value?.shadowRoot?.querySelectorAll<HTMLElement>('.vac-room-item');
        if (roomElements) {
          roomElements?.forEach((element: Element) => {
            const htmlElement = element as HTMLElement;
            if (htmlElement.id === props.getSelectedIdNotification) {
              htmlElement.click();
              if (
                chatRoomsResult.value?.chatRooms?.collection &&
                (!isCompanyRepresentative.value || !minimizedChat.value)
              ) {
                const findRoom = chatRoomsResult.value?.chatRooms?.collection?.findIndex(
                  (x) => x?.id === props.getSelectedIdNotification,
                ) as number;
                if (findRoom !== -1) {
                  const findChatRoom = chatRoomsResult.value.chatRooms.collection[findRoom];
                  if (findChatRoom) {
                    findChatRoom.unreadCount = 0;
                  }
                }
              }
            }
          });
        }
      }
    };
    watch(
      () => chatRoomsId.value,
      (newValue, oldValue) => {
        if (newValue !== oldValue && newValue && oldValue) {
          otherRoom.value = true;
        } else {
          otherRoom.value = false;
        }
      },
    );

    watchEffect(() => {
      if (rooms.value?.length) {
        onOpenChatModule();
        if (isChatOpen.value && isCompanyRepresentative.value) {
          push({ query: { mode: 'chat' }, hash: currentRoute.value.hash });
        }
        const footer = vueAdvancedChat.value?.shadowRoot?.querySelector(
          '.vac-box-footer',
        ) as HTMLElement;
        if (footer && chatRoomType.value === 'accounts') {
          footer.style.display = 'none';
        } else if (footer) {
          footer.style.display = 'flex';
        }
        const preventButton = vueAdvancedChat.value?.shadowRoot?.querySelector(
          '.vac-toggle-button',
        ) as HTMLElement;
        if (preventButton) {
          preventButton.onclick = () => onClickPrevent();
        }
        const uploadFIle = vueAdvancedChat.value?.shadowRoot?.querySelector(
          '.vac-icon-textarea input',
        ) as HTMLInputElement;
        if (uploadFIle) {
          uploadFIle.multiple = false;
        }
        const messagesListScroll = vueAdvancedChat.value?.shadowRoot?.querySelector(
          '#messages-list',
        ) as HTMLElement;
        if (messagesListScroll && !otherRoom.value) {
          const htmlElement = messagesListScroll as HTMLElement;
          htmlElement.onscroll = (e: Event) => onScroll(e);
        }
        const roomsListScroll = vueAdvancedChat.value?.shadowRoot?.querySelector(
          '#rooms-list',
        ) as HTMLElement;
        if (roomsListScroll) {
          const htmlElement = roomsListScroll as HTMLElement;
          if (htmlElement) {
            htmlElement.onscroll = (e: Event) => onScrollRoomList(e);
          }
        }
        const messagesContentLoaded = vueAdvancedChat.value?.shadowRoot?.querySelector(
          '#messages-list',
        ) as HTMLElement;
        if (!allSelectedChatMessages.value?.length) {
          if (messagesContentLoaded) {
            messagesContentLoaded.classList.add('messagesContentLoaded');
          }
        }
        if (allSelectedChatMessages.value?.length) {
          if (messagesContentLoaded) {
            messagesContentLoaded.classList.remove('messagesContentLoaded');
          }
        }
      }
      if (
        rooms.value?.length &&
        props.getSelectedIdNotification &&
        props.getSelectedIdNotification !== focusedChatRoomId.value &&
        !isPrevent.value
      ) {
        openSelectedRoom();
      } else {
        isPrevent.value = false;
      }
    });

    watchEffect(() => {
      if (focusedChatRoomId.value && rooms.value?.length) {
        isScrollingDisabled.value = true;
      } else if (!focusedChatRoomId.value && rooms.value?.length) {
        isScrollingDisabled.value = false;
      }
    });
    // Client documents categories will be fetched only on changing client company
    watchEffect(() => {
      if (getClientCompanyId.value) {
        getClientDocumentsCategoriesNames(getClientCompanyId.value);
      }
    });
    const debouncedEventHandler = () => {
      setTimeout(() => {
        const scrollIconElement = vueAdvancedChat.value?.shadowRoot?.querySelector(
          '.vac-icon-scroll',
        ) as HTMLInputElement;
        if (scrollIconElement) {
          if (isScrollingDisabled.value && !filteredSelectedRoom.value) {
            scrollIconElement.click();
          }
        }
      }, 100);
    };
    const onComponentUpdate = () => {
      if (!isUnreadMessages.value) {
        debouncedEventHandler();
      }
      const roomEmptyList = vueAdvancedChat.value?.shadowRoot?.querySelector(
        '#rooms-list',
      ) as HTMLElement;
      if (
        roomEmptyList &&
        Object.keys(selectedTeamRooms.value).length === 0 &&
        hasSelectedTeam.value &&
        activeTab.value === 'team'
      ) {
        roomEmptyList.classList.add('scrollHidden');
      } else if (
        roomEmptyList &&
        !(
          Object.keys(selectedTeamRooms.value).length === 0 &&
          hasSelectedTeam.value &&
          activeTab.value === 'team'
        )
      ) {
        roomEmptyList.classList.remove('scrollHidden');
      }
      const loadedRoomChild = vueAdvancedChat.value?.shadowRoot?.querySelector(
        '#vac-circle',
      ) as HTMLElement;
      if (loadedRoomChild && !isAdvisorsChat.value && activeTab.value === 'team') {
        loadedRoomChild.style.display = 'none';
      } else if (loadedRoomChild && Object.keys(selectedTeamRooms.value).length) {
        loadedRoomChild.style.display = 'block';
      }
      const searchInput = vueAdvancedChat.value?.shadowRoot?.querySelector(
        '.vac-input',
      ) as HTMLInputElement;
      if (searchInput) {
        searchInput.value = searchValue.value;
      }
      isMounted.value = true;
      const cssCheck = vueAdvancedChat.value?.shadowRoot?.querySelector(
        '[href="/static/css/vue-advanced-chat.css"]',
      );
      const jsCheck = vueAdvancedChat.value?.shadowRoot?.querySelector(
        '[src="/static/js/vue-advanced-chat.js"]',
      );
      if (!cssCheck || !jsCheck) {
        const style = document.createElement('link');
        const js = document.createElement('script');
        // Note: This script dynamically injects CSS/JS which can lead to caching issue.
        js.src = '/static/js/vue-advanced-chat.js';
        style.href = '/static/css/vue-advanced-chat.css';
        style.rel = 'stylesheet';
        vueAdvancedChat.value?.shadowRoot?.appendChild(style);
        vueAdvancedChat.value?.shadowRoot?.appendChild(js);
      }
      const roomsContentText = vueAdvancedChat.value?.shadowRoot?.querySelector(
        '.vac-box-empty',
      ) as HTMLInputElement;
      if (roomsContentText) {
        roomsContentText.innerText =
          isCompanyRepresentative.value && chatRoomsResult.value?.chatRooms?.collection?.length
            ? getPageTerms('chooseProduct')
            : '';
      }
      if (rooms.value?.length) {
        const selectedRoomElement = vueAdvancedChat.value?.shadowRoot?.querySelector(
          '.vac-room-selected',
        ) as HTMLInputElement;
        if (selectedRoomElement) {
          const roomsListElement = vueAdvancedChat.value?.shadowRoot?.getElementById('rooms-list');
          if (roomsListElement) {
            roomsListElement.onclick = (event) => {
              const roomElement: HTMLElement | null = (event.target as HTMLElement).closest(
                '.vac-room-item',
              );
              if (roomElement) {
                onSelectRoom(roomElement.id, roomElement.innerText);
              }
            };
          }
        }
      }
    };

    const changeActiveTab = (index: number, tabValue: string) => {
      const searchInput = vueAdvancedChat.value?.shadowRoot?.querySelector(
        '.vac-input',
      ) as HTMLInputElement;
      const loadedRoom = vueAdvancedChat.value?.shadowRoot?.querySelector(
        '#infinite-loader-rooms',
      ) as HTMLElement;
      if (searchInput) {
        searchInput.value = '';
      }
      handleSearchChange('');
      isActive.value = index;
      selectedRoomId.value = '';
      advisorsFirstName.value = '';
      advisorsLastName.value = '';
      messagesPage.value = 1;
      messagesItemsPerPage.value = 30;
      selectedTeamRooms.value = {};
      isAdvisorsChat.value = false;
      const roomEmptyList = vueAdvancedChat.value?.shadowRoot?.querySelector(
        '#rooms-list',
      ) as HTMLElement;
      if (roomEmptyList) {
        roomEmptyList.style.display = 'block';
      }
      const searchEmptyElement = document.getElementById('searchEmpty');
      if (searchEmptyElement) {
        searchEmptyElement.style.display = 'none';
      }
      if (tabValue === 'team') {
        nextTick(() => {
          onComponentUpdate();
        });
        if (loadedRoom) {
          loadedRoom.style.display = 'none';
        }
        isShowMembersAndFiles.value = true;
        activeTab.value = 'team';
        if (searchInput) {
          searchInput.placeholder = getPageTerms('searchForAdvisor');
        }
        accountsListPage.value = 1;
        getAccountsSa();
      } else {
        roomListPage.value = 1;
        getChatRooms(true);
        if (loadedRoom) {
          loadedRoom.style.display = 'block';
        }

        if (roomEmptyList) {
          roomEmptyList.classList.remove('scrollHidden');
        }
        activeTab.value = '';
        isShowMembersAndFiles.value = false;
        if (searchInput) {
          searchInput.placeholder = getPageTerms('searchForClient');
        }
      }
    };
    onUpdated(() => onComponentUpdate());

    const filterChatRooms = (searchTerm: string) => {
      debounceFn(() => {
        searchValue.value = searchTerm;
        if (activeTab.value === 'team' && !hasSelectedTeam.value) {
          accountsListPage.value = 1;
          getAccountsSa();
        } else {
          roomListPage.value = 1;
          getChatRooms(true);
        }
      }, 500);
    };
    const openDropDown = () => {
      isOpenDropDown.value = !isOpenDropDown.value;
      isShowMembers.value = false;
    };
    const getSelectedRoomId = computed(() => messagesStore?.getSelectedIdNotification);
    const maximizeChat = () => {
      if (getSelectedRoomId.value) {
        push({
          name: MAIN_ROUTES.CHAT.name,
          query: {
            '': getSelectedRoomId.value
              ? getSelectedRoomId.value.replace('/api/chat_rooms/', '')
              : '',
          },
        });
      } else {
        push({
          name: MAIN_ROUTES.CHAT.name,
        });
      }
    };

    watchEffect(() => {
      let routeQuery = '';
      if (route?.query) {
        if (Object?.values(route?.query)?.[0]) {
          routeQuery = `/api/chat_rooms/${Object?.values(route.query)?.[0] ?? ''}`;
          if (Object?.values(route?.query)?.[0]) {
            oldChatRoomId.value = routeQuery;
          }
        }
      }

      if (
        chatRoomsResult.value.chatRooms?.collection?.length &&
        !loadingRooms.value &&
        routeQuery &&
        !chatMessagesLoading.value
      ) {
        const roomElements =
          vueAdvancedChat.value?.shadowRoot?.querySelectorAll<HTMLElement>('.vac-room-item');
        if (roomElements) {
          roomElements.forEach((element: Element) => {
            const htmlElement = element as HTMLElement;
            if (htmlElement && htmlElement.id === routeQuery) {
              htmlElement.click();
              getChatMessages(routeQuery);
              push({
                query: {},
              });
            }
          });
        }
      }
    });

    const debounceFetch = createDebounce();
    /* this watch needed to refetch messages if new message was received via webSocket and chat
    room for this message is open */
    watch(
      () => messagesStore?.newMessages[getSelectedRoomId.value],
      (newMessagesValue, previousMessagesValue) => {
        if (!getSelectedRoomId.value || getSelectedRoomId.value !== focusedChatRoomId.value) return;
        if (JSON.stringify(newMessagesValue) === JSON.stringify(previousMessagesValue)) return;
        if (newMessagesValue?.[0].senderId === currentUserId) return;
        debounceFetch(() => getChatMessages(getSelectedRoomId.value, false), 300);
      },
    );

    return {
      filterChatRooms,
      searchValue,
      isOpenDropDown,
      isCompanyRepresentative,
      isSustainabilityAdvisor,
      chatMessagesLoading,
      chatMessagesResult,
      messagesStore,
      getSelectedRoomId,
      selectProduct,
      openDropDown,
      joinChatRoom,
      downloadLink,
      minimizedChat,
      sustainabilityAdvisors,
      fileUpload,
      isScrollingDisabled,
      getClientCompanyId,
      representativeProfilePathClient,
      representativeProfilePathAdvisors,
      isRoomCountEqualToChatRooms,
      hideMessagesEmptyState,
      inChatRoom,
      totalRoomsCount,
      totalMessagesCount,
      isShowMembersAndFiles,
      showEmptyState,
      isAdvisorsChat,
      accountsResult,
      chatRoomType,
      loadingUrl,
      chooseRoomsProduct,
      chatRoomsResult,
      hasUnreadMessage,
      selectedTeamRooms,
      getChatRooms,
      activeTab,
      allSelectedChatMessages,
      selectedRoomId,
      advisorsFirstName,
      focusedChatRoomId,
      advisorsLastName,
      getProfileLink,
      messages,
      rooms,
      isManager,
      chatCategoryId,
      isBSuppRole,
      getClientDocumentsCategoriesNames,
      getChatMessages,
      isActive,
      tabItems,
      changeActiveTab,
      images,
      COMMON_SIDE_BAR_ROUTES,
      onClickAway,
      chatRoomsId,
      isShowMembers,
      participantsChat,
      formatChatTimestamp,
      getFormattedDate,
      currentUserName,
      product,
      openFile,
      hasChatDocuments,
      sendNewMessage,
      openPopup,
      sendMessage,
      getBtnTerms,
      getPageTerms,
      categoriesList,
      isJoinChat,
      categoriesLoading,
      fetchMessages,
      cypressDataAttribute,
      currentUserId,
      vueAdvancedChat,
      openMembersPopup,
      isChatOpen,
      getClientDocuments,
      textMessages,
      openSelectedRoom,
      loadingRooms,
      chatModuleRoute,
      downloadElementRef,
      hasModule,
      maximizeChat,
      roomListPage,
      defaultRoomList,
      searchRoomsLoading,
      filteredSelectedRoom,
      isUnreadMessages,
    };
  },
});
</script>

<template>
  <BaseLoader v-if="loadingRooms && !(isCompanyRepresentative || minimizedChat)" />
  <div :class="{ chatModuleForCR: isCompanyRepresentative || minimizedChat }" class="chat-module">
    <div class="chat-module__wrapper">
      <div v-if="isCompanyRepresentative || minimizedChat">
        <div
          v-if="!isChatOpen && !loadingRooms && rooms?.length"
          class="open-chat-company"
          :class="{ hasUnreadMessage: hasUnreadMessage }"
          @click="openPopup"
        >
          <BaseIcon
            icon-name="chat"
            class="document"
            alt="document-icon"
            :size="20"
            :data-cy="`${cypressDataAttribute}DocumentIcon`"
          />
        </div>
        <div v-if="loadingRooms" class="chat-module-loader">
          <ButtonLoader />
        </div>
        <div v-show="isChatOpen" class="is-company-representative">
          <div id="isCRHeader" class="header">
            <p>{{ minimizedChat ? getPageTerms('routeTitle') : getPageTerms('chooseChat') }}</p>
            <div class="header-icons">
              <BaseIcon
                v-if="!isCompanyRepresentative"
                icon-name="maximize"
                class="close maximize"
                alt="maximize-icon"
                :size="24"
                :data-cy="`${cypressDataAttribute}Maximize`"
                @click="maximizeChat"
              />
              <BaseIcon
                icon-name="close"
                class="close"
                alt="close-icon"
                :size="32"
                :data-cy="`${cypressDataAttribute}Close`"
                @click="openPopup"
              />
            </div>
          </div>
          <div v-if="rooms?.length" id="selected_item">
            <div
              class="selected-item__wrapper"
              :class="{ hasUnreadMessage: hasUnreadMessage && !isBSuppRole }"
            >
              <div>
                <div class="participants-chat">
                  <div
                    class="roomMembers"
                    :class="[
                      {
                        'transform-style':
                          (isCompanyRepresentative && !hasModule) ||
                          (minimizedChat && !hasChatDocuments),
                      },
                      { roomMembersHover: participantsChat.length > 1 },
                      { isShowMembers: isShowMembers && participantsChat.length > 1 },
                    ]"
                  >
                    <div
                      v-for="(member, i) in participantsChat?.slice(0, 2)"
                      :key="i"
                      class="roomMembers__item"
                      @click="openMembersPopup"
                    >
                      <img :src="member.avatar" alt="avatar" />
                    </div>
                    <div v-if="participantsChat?.length > 2" class="plus">
                      <div>+ {{ participantsChat?.length - 2 }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="(isCompanyRepresentative && hasModule) || (minimizedChat && hasChatDocuments)"
                class="doc-files-cr"
                @click="openDropDown"
              >
                <BaseIcon
                  icon-name="overflow-menu--vertical"
                  class="document"
                  :data-cy="`${cypressDataAttribute}OverflowMenuVertical`"
                  :size="24"
                />
              </div>
              <div v-if="isOpenDropDown" class="chat-dropdown">
                <div
                  v-if="(minimizedChat && hasChatDocuments) || isCompanyRepresentative"
                  class="chat-dropdown__item"
                  @click="selectProduct"
                >
                  <BaseIcon
                    icon-name="document"
                    alt="document-icon"
                    :data-cy="`${cypressDataAttribute}Document`"
                  />
                  <span> {{ getPageTerms('viewChatFiles') }} </span>
                </div>
              </div>

              <div v-if="isShowMembers" v-click-away="onClickAway" class="selected-item__members">
                <div
                  v-for="(member, i) in participantsChat"
                  :key="i"
                  class="selected-item__members-user"
                >
                  <router-link
                    :to="
                      getProfileLink(
                        member._id || '',
                        '/api/accounts/',
                        member.roles === 'ROLE_COMPANY_REPRESENTATIVE'
                          ? representativeProfilePathClient
                          : representativeProfilePathAdvisors,
                      )
                    "
                    class="participants"
                  >
                    <img class="participants__image" :src="member.avatar" alt="avatar" />
                    <div class="participants__wrapper">
                      <span class="participants__username">
                        {{ member.username }}
                      </span>
                      <span class="participants__company">
                        {{ member.clientCompany }}
                      </span>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="header-icons">
                <BaseIcon
                  v-if="!isCompanyRepresentative"
                  icon-name="maximize"
                  class="close maximize"
                  alt="maximize-icon"
                  :size="24"
                  :data-cy="`${cypressDataAttribute}MinimizeIcon`"
                  @click="maximizeChat"
                />
                <BaseIcon
                  icon-name="close"
                  class="close"
                  alt="close-icon"
                  :size="32"
                  :data-cy="`${cypressDataAttribute}CloseIcon`"
                  @click="openPopup"
                />
              </div>
            </div>
          </div>
          <vue-advanced-chat
            v-show="isChatOpen && !loadingRooms && !chatMessagesLoading"
            :id="minimizedChat ? 'vueAdvancedChatIdForMinimized' : 'vueAdvancedChatIdForCR'"
            ref="vueAdvancedChat"
            :single-room="false"
            :current-user-id="currentUserId"
            :rooms="JSON.stringify(rooms) || []"
            :rooms-loaded="isRoomCountEqualToChatRooms"
            :messages-loaded="allSelectedChatMessages ? true : false"
            :show-search="false"
            :text-messages="JSON.stringify(textMessages)"
            :show-add-room="false"
            :messages="JSON.stringify(allSelectedChatMessages)"
            :show-emojis="false"
            :show-reaction-emojis="false"
            :show-send-icon="true"
            :show-new-messages-divider="true"
            :show-audio="false"
            :username-options="JSON.stringify({ minUsers: 1, currentUser: false })"
            @open-file="openFile($event.detail[0])"
            @send-message="sendMessage($event.detail[0])"
            @fetch-messages="fetchMessages($event.detail[0])"
          />
          <BaseLoader v-if="loadingRooms || chatMessagesLoading" />
          <div v-if="rooms?.length" id="messagesEmptyState" class="footer-block-forCr">
            <div
              v-if="!allSelectedChatMessages?.length && showEmptyState"
              class="footer-block-forCr__empty-state"
              :class="{ 'footer-block-CR': isCompanyRepresentative }"
            >
              <span>
                {{
                  isCompanyRepresentative
                    ? getPageTerms('welcomeOurChat')
                    : getPageTerms('noMessages')
                }}
              </span>
            </div>
            <div
              v-if="!isJoinChat && (isBSuppRole || isManager) && inChatRoom"
              class="join_chat"
              :class="{ 'minimized-join-chat': minimizedChat }"
            >
              <button @click="joinChatRoom">
                {{ getPageTerms('joinChat') }}
              </button>
              <p>
                {{ getPageTerms('joinChatInfo') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!minimizedChat && (isSustainabilityAdvisor || isBSuppRole)"
        class="sustainability-chat"
      >
        <div
          v-if="(rooms?.length && !isShowMembersAndFiles) || (!rooms?.length && searchValue)"
          id="selected_item"
          class="selected_item"
        >
          <div class="selected_item__wrapper">
            <div class="participants-chat">
              <div
                class="roomMembers"
                :class="{
                  roomMembersHover: participantsChat.length > 1,
                  isShowMembers: isShowMembers && participantsChat.length > 1,
                }"
              >
                <div
                  v-for="(member, i) in participantsChat?.slice(0, 2)"
                  :key="i"
                  class="roomMembers__item"
                  @click="openMembersPopup"
                >
                  <img :src="member.avatar" alt="avatar" />
                </div>
                <div v-if="participantsChat?.length > 2" class="plus">
                  <div>+ {{ participantsChat?.length - 2 }}</div>
                </div>
              </div>
              <div
                v-if="isShowMembers"
                v-click-away="onClickAway"
                class="selected_item__members"
                :class="{ 'empty-documents': !hasChatDocuments }"
              >
                <div
                  v-for="(member, i) in participantsChat"
                  :key="i"
                  class="selected_item__members-user"
                >
                  <router-link
                    :to="
                      getProfileLink(
                        member._id || '',
                        '/api/accounts/',
                        member.roles === 'ROLE_COMPANY_REPRESENTATIVE'
                          ? representativeProfilePathClient
                          : representativeProfilePathAdvisors,
                      )
                    "
                    class="participants"
                  >
                    <img class="participants__image" :src="member.avatar" alt="avatar" />
                    <div class="participants__wrapper">
                      <span class="participants__username">
                        {{ member.username }}
                      </span>
                      <span class="participants__company">
                        {{ member.clientCompany }}
                      </span>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div v-if="hasChatDocuments" class="doc-files" @click="openDropDown">
              <BaseIcon
                icon-name="overflow-menu--vertical"
                class="document"
                :size="24"
                :data-cy="`${cypressDataAttribute}OverflowMenuVertical`"
              />
            </div>
            <div
              v-if="isOpenDropDown"
              class="chat-dropdown"
              :class="{
                'dropdown-for-large-chat': !(isCompanyRepresentative || minimizedChat),
              }"
            >
              <div v-if="hasChatDocuments" class="chat-dropdown__item" @click="selectProduct">
                <BaseIcon
                  icon-name="document"
                  alt="document-icon"
                  :data-cy="`${cypressDataAttribute}DocumentIcon`"
                />
                <span> {{ getPageTerms('viewChatFiles') }} </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="loadingRooms && isAdvisorsChat && activeTab === 'team'" class="loading-rooms">
          <div class="loadingRooms__loader">
            <BaseLoader />
          </div>
          <div class="loadingRooms__empty"></div>
        </div>
        <vue-advanced-chat
          :id="
            isManager && isActive === 0
              ? 'vueAdvancedChatIdForManger'
              : isManager && isActive != 0
                ? 'vueAdvancedChatIdForMangerTeam'
                : isBSuppRole && isActive === 0
                  ? 'vueAdvancedChatIdForBSupp'
                  : isBSuppRole && isActive != 0
                    ? 'vueAdvancedChatIdForBSuppTeam'
                    : 'vueAdvancedChatIdForSBA'
          "
          ref="vueAdvancedChat"
          :current-user-id="currentUserId"
          :rooms="JSON.stringify(searchValue && !rooms?.length ? defaultRoomList : rooms) || []"
          :messages="JSON.stringify(allSelectedChatMessages)"
          :rooms-loaded="isRoomCountEqualToChatRooms"
          :messages-loaded="allSelectedChatMessages ? true : false"
          :text-messages="JSON.stringify(textMessages)"
          :show-search="true"
          :show-add-room="false"
          :show-send-icon="true"
          :show-audio="false"
          :show-emojis="false"
          :show-reaction-emojis="false"
          :show-new-messages-divider="true"
          :username-options="JSON.stringify({ minUsers: 1, currentUser: false })"
          :custom-search-room-enabled="true"
          @search-room="filterChatRooms($event.detail[0].value.trim())"
          @open-file="openFile($event.detail[0])"
          @send-message="sendMessage($event.detail[0])"
          @fetch-messages="fetchMessages($event.detail[0])"
        />
        <div
          v-if="(isBSuppRole || isManager) && (rooms?.length || (!rooms?.length && searchValue))"
          class="chat-filter"
          :class="{ isSelectClients: !activeTab }"
        >
          <div class="chat-filter__tabs">
            <div
              v-for="(item, index) in tabItems"
              :id="`${index}`"
              :key="index"
              class="chat-filter__tab"
              :class="[index === isActive ? 'is-active' : '']"
              @click="changeActiveTab(index, item.value)"
            >
              {{ item.name }}
            </div>
          </div>
          <p v-if="activeTab" class="selectClientText">
            {{
              isAdvisorsChat && activeTab === 'team'
                ? `${advisorsFirstName} ${advisorsLastName}  ${getPageTerms('chats')}`
                : getPageTerms('selectClients')
            }}
          </p>
          <div
            v-if="
              Object.keys(selectedTeamRooms).length === 0 && isAdvisorsChat && activeTab === 'team'
            "
            id="search-empty-clients"
          >
            <div>
              <p>
                {{ getPageTerms('noAdvisorChat') }}
              </p>
            </div>
          </div>
        </div>
        <div v-if="chatRoomsResult?.chatRooms?.collection?.length" class="footer-block">
          <div
            v-if="!allSelectedChatMessages?.length && showEmptyState"
            class="footer-block__empty-state"
          >
            <div class="hidden-block" />
            <span>
              {{
                !isCompanyRepresentative
                  ? getPageTerms('noMessages')
                  : getPageTerms('welcomeOurChat')
              }}
            </span>
          </div>
          <div
            v-if="!isJoinChat && (isBSuppRole || isManager) && chatRoomType !== 'accounts'"
            class="footer-block__join-chat"
          >
            <div class="hidden-block" />
            <div class="join_chat" :class="{ disabled: chatRoomType === 'accounts' }">
              <button @click="joinChatRoom">
                {{ getPageTerms('joinChat') }}
              </button>
              <p>
                {{ getPageTerms('joinChatInfo') }}
              </p>
            </div>
          </div>
        </div>
        <!-- This loader is styled to cover rooms list, search bar and tabs buttons on search requests -->
        <div v-if="searchRoomsLoading" class="search-loading">
          <BaseLoader />
        </div>
        <div
          v-if="!rooms?.length"
          id="searchEmpty"
          :class="{ emptySBA: isSustainabilityAdvisor && !isManager }"
        >
          <div v-if="activeTab !== 'team' || (chatRoomType !== 'accounts' && activeTab === 'team')">
            <p>
              {{ getPageTerms('noClientsFound') }}
            </p>
            <p>{{ getPageTerms('tryAgain') }}</p>
          </div>
          <div v-else>
            <p>
              {{ getPageTerms('noSustainabilityFound') }}
            </p>
            <p>{{ getPageTerms('pleaseTryAgain') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a
    ref="downloadElementRef"
    :href="downloadLink"
    class="category-file__link"
    download
    target="_blank"
  />
</template>

<style scoped lang="scss">
.chat-filter {
  position: absolute;
  padding-top: remCalc(18);
  padding-left: remCalc(18);
  border-bottom-left-radius: 8px;
  top: 71px;
  left: 0;
  width: 100%;
  max-width: 15rem;
  min-width: remCalc(100);
  z-index: 2;
  background-color: $white;
  &__tabs {
    border-radius: 16px;
    background: var(--color-bg);
    max-width: remCalc(243);
    width: 100%;
    height: 40px;
    display: flex;
    align-content: center;
  }
  &__tab {
    @extend %fw-300;
    width: 50%;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: remCalc(14);
    color: var(--color-main);
    line-height: remCalc(16);
    height: 100%;
  }
  .is-active {
    background: var(--color-primary);
    color: $white;
    transition: all 0.3s ease-in-out;
  }
}

.chat-module {
  z-index: 0;
  &__wrapper {
    width: 100%;
    height: 100%;
  }
}
.open-chat-company {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  min-height: remCalc(40);
  min-width: remCalc(40);
  padding: var(--space-3xs);
  background: var(--color-primary);
  border-radius: 50%;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  box-shadow: 0 4px 4px rgba(78, 23, 7, 0.3);
  &.hasUnreadMessage::after {
    content: '';
    position: absolute;
    background-color: var(--color-error);
    width: remCalc(10);
    height: remCalc(10);
    border-radius: 50%;
    top: 0;
    right: 0;
    animation: myMove 1s infinite;

    @keyframes myMove {
      from {
        background-color: var(--color-error);
      }
      to {
        background-color: var(--color-input-error);
      }
    }
  }
}
.is-company-representative {
  width: fit-content;
  height: 100%;
  max-height: calc(100% - remCalc(200));
  position: fixed;
  width: 100%;
  max-width: remCalc(500);
  box-sizing: border-box;
  right: 1rem;
  border-radius: 20px;
  bottom: 1rem;
  z-index: 5;
  animation: slideInDown 0.4s ease-in-out;
  background-color: var(--color-shade-8);
  box-shadow: var(--box-shadow-normal);
  @keyframes slideInDown {
    from {
      transform: translate3d(0, 500px, 0) scaleY(0.4);
      visibility: visible;
    }
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  .close {
    color: $white;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  #selected_item {
    display: none;
    position: relative;
    width: calc(100% - 55px);
  }
  .selected-item {
    &__wrapper {
      position: absolute;
      background: transparent;
      z-index: 99;
      width: 100%;
      height: 65px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: var(--space-3xs);
      left: 55px;
      &.hasUnreadMessage::after {
        content: '';
        position: absolute;
        background-color: var(--color-error);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        top: 24px;
        left: -24px;
        animation: myMove 1s infinite;

        @keyframes myMove {
          from {
            background-color: var(--color-error);
          }
          to {
            background-color: var(--color-input-error);
          }
        }
      }
    }
    &__icons {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      max-width: remCalc(60);
      @media screen and (max-width: $small-desktop) {
        max-width: remCalc(140);
      }
      @media screen and (max-width: $tablet) {
        max-width: remCalc(150);
      }
      span {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: $white;
        cursor: pointer;
        &:first-child {
          @extend %fw-300;
          line-height: var(--line-base);
          margin-right: 1.5rem;
          position: absolute;
          left: 0;
        }
        &:last-child {
          @extend %fw-400;
          font-size: var(--text-xs);
          text-decoration: underline;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
    &__members {
      position: absolute;
      width: remCalc(310);
      height: fit-content;
      max-height: remCalc(300);
      overflow-y: auto;
      background: $white;
      z-index: 9;
      top: 4rem;
      right: 0;
      padding: var(--space-xs);
      box-shadow: 0 4px 4px rgba(78, 23, 7, 0.3);
      border-radius: remCalc(8);
      display: flex;
      flex-direction: column;
      &::-webkit-scrollbar {
        width: 14px;
        &-track {
          background: var(--color-shade-12);
          padding: var(--space-5xs);
        }
        &-thumb {
          background: var(--color-light-grey);
          border-radius: 10px;
          border: remCalc(4) solid var(--color-shade-12);
          &:hover {
            opacity: 0.7;
          }
        }
      }
      &-user {
        display: flex;
        align-items: center;
        margin-bottom: var(--space-3xs);
        div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: var(--space-3xs);
          p {
            @extend %fw-300;
            font-size: var(--text-xs);
            line-height: var(--line-s);
            overflow: hidden;
            &:first-child {
              color: var(--color-main);
            }
            &:nth-child(2) {
              color: var(--color-grey);
            }
          }
        }
        img {
          width: remCalc(36);
          height: remCalc(36);
          border-radius: 50%;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .close {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      color: $white;
      svg {
        cursor: pointer;
      }
    }
  }
  .header {
    width: 100%;
    height: 64px;
    background-color: var(--color-primary);
    padding: var(--space-xs);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    p {
      @extend %fw-400;
      font-family: $font-base;
      font-size: var(--text-base);
      line-height: var(--line-base);
      display: flex;
      align-items: center;
      color: $white;
    }
    span {
      display: flex;
      align-items: center;
      cursor: pointer;
      svg path {
        fill: $white;
      }
    }
  }
}
:deep.sustainability-chat {
  position: relative;
  width: 100%;
  .selected_item {
    position: absolute;
    background: transparent;
    z-index: 99;
    width: fit-content;
    height: 54px;
    display: flex;
    justify-content: space-between;
    top: 0;
    right: 5px; // It should be with px here
    &__wrapper {
      display: flex;
      align-items: center;
      position: relative;
      .participants-chat {
        &:first-child {
          @extend %fw-300;
          font-size: var(--text-s);
          line-height: var(--line-base);
          margin-right: var(--space-3xs);
          position: relative;
          cursor: pointer;
          .selected_item__members {
            position: absolute;
            width: remCalc(310);
            height: fit-content;
            background: $white;
            z-index: 9;
            top: 0;
            padding: var(--space-xs);
            box-shadow: 0 4px 4px rgba(78, 23, 7, 0.3);
            border-radius: remCalc(8);
            display: flex;
            right: 80px; // It should be with px here
            flex-direction: column;
            height: fit-content;
            max-height: remCalc(200);
            overflow-y: auto;
            transform: translate(remCalc(90), remCalc(30));
            &::-webkit-scrollbar {
              width: 14px;
              &-track {
                background: var(--color-shade-12);
                padding: var(--space-5xs);
              }
              &-thumb {
                background: var(--color-light-grey);
                border-radius: 10px;
                border: remCalc(4) solid var(--color-shade-12);
                &:hover {
                  opacity: 0.7;
                }
              }
            }
            &-user {
              display: flex;
              align-items: center;
              margin-bottom: var(--space-3xs);
              p {
                @extend %fw-300;
                font-size: var(--text-s);
                line-height: var(--line-lg);
                color: var(--color-main);
                margin-left: var(--space-3xs);
                overflow: hidden;
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
            &.empty-documents {
              right: 100px; // It should be with px here
            }
          }
        }
        &:last-child {
          @extend %fw-700;
          font-size: var(--text-xs);
          text-decoration: underline;
          cursor: pointer;
          color: var(--color-primary);
        }
      }
      svg {
        width: 28px;
        height: 28px;
      }
    }
  }
  .selectClientText {
    @extend %font-introduction;
    color: var(--color-main);
    background: $white;
    padding: 0;
    padding-top: var(--space-3xs);
    font-size: var(--text-s);
    line-height: var(--line-lg);
  }
  .emptyState {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      @extend %fw-400;
      font-size: var(--text-s);
      line-height: var(--line-lg);
      color: var(--color-main);
      text-align: center;
    }
  }
}
.chatModuleForCR {
  position: relative;
  z-index: 10;
}
#searchEmpty,
#search-empty-clients {
  width: 100%;
  position: absolute;
  top: 9rem;
  left: 0;
  display: none;
  max-width: 21rem;
  z-index: 12;
  @media screen and (max-width: $middle-desktop) {
    top: 11rem;
    max-width: 19rem;
  }
  @media screen and (max-width: $small-desktop) {
    top: 13rem;
    max-width: 17rem;
  }
  @media screen and (max-width: $tablet) {
    top: 15rem;
  }
  div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  p {
    @extend %fw-300;
    text-align: center;
    width: 80%;
    color: var(--color-grey);
    font-size: var(--text-base);
    line-height: var(--line-base);
  }
  &.emptySBA {
    top: 90px;
  }
}
.search-loading {
  width: 100%;
  position: absolute;
  top: 10%;
  left: 0;
  width: 25%;
  min-width: 260px;
  max-width: 500px;
  z-index: 13;
  height: 90%;
}
.chatMembers {
  position: relative;
  width: 100%;
  max-width: 5rem;
  height: remCalc(40);
  display: flex;
  align-items: center;
}
.roomMembers {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  transition: 0.3s ease-in-out;
  width: fit-content;
  cursor: pointer;
  &__item {
    display: flex;
    align-items: center;
    position: absolute;
    transition: 0.3s ease-in-out;
    &:nth-child(1) {
      left: -60px;
      z-index: 2;
    }
    &:nth-child(2) {
      left: -30px;
      z-index: 1;
    }
  }
  .plus,
  img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
  }
  .plus {
    transition: 0.3s ease-in-out;
    div {
      @extend %fw-400;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: var(--color-shade-5);
      font-size: var(--text-xs);
      line-height: var(--line-s);
      text-align: center;
      color: var(--color-primary);
      z-index: 0;
      border-radius: 50%;
      transition: 0.3s ease-in-out;
    }
  }
}
.roomMembersHover:hover,
.isShowMembers {
  .roomMembers__item {
    &:nth-child(1) {
      left: -45px;
      z-index: 2;
    }
  }
  .plus div {
    position: absolute;
    left: -15px;
  }
}
.isSelectClients {
  padding-bottom: 0;
}
.participants-chat {
  position: relative;
  display: flex;
  align-items: center;
  .roomMembers {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    transition: 0.3s ease-in-out;
    width: fit-content;
    cursor: pointer;
    &__item {
      display: flex;
      align-items: center;
      position: absolute;
      transition: 0.3s ease-in-out;
      &:nth-child(1) {
        left: -60px;
        z-index: 2;
      }
      &:nth-child(2) {
        left: -30px;
        z-index: 1;
      }
    }
    .plus,
    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      overflow: hidden;
    }
    .plus {
      transition: 0.3s ease-in-out;
      div {
        @extend %fw-400;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: var(--color-shade-5);
        font-size: var(--text-xxs);
        line-height: var(--line-s);
        text-align: center;
        color: var(--color-primary);
        z-index: 0;
        border-radius: 50%;
        transition: 0.3s ease-in-out;
      }
    }
    &.transform-style {
      right: 10px; // It should be with px here
    }
  }
  .roomMembersHover:hover,
  .isShowMembers {
    .roomMembers__item {
      &:nth-child(1) {
        left: -45px;
        z-index: 2;
      }
    }
    .plus div {
      position: absolute;
      left: -15px;
    }
  }
}
.doc-files {
  @extend %fw-700;
  font-family: $font-base;
  font-size: var(--text-xs);
  line-height: var(--line-md);
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: var(--color-primary);
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
.doc-files-cr {
  color: var(--color-shade-8);
  display: flex;
  align-items: center;
  margin-left: var(--space-3xs);
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
.participants {
  display: flex;
  align-items: center;
  &__image {
    width: remCalc(36);
    height: remCalc(36);
    border-radius: 50%;
    overflow: hidden;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: var(--space-xs);
  }
  &__username {
    @extend %fw-700;
    font-family: $font-base;
    font-size: var(--text-s);
    line-height: var(--line-lg);
    color: var(--color-primary);
    margin: 0;
    padding: 0;
  }
  &__company {
    @extend %fw-400;
    font-family: $font-base;
    font-size: var(--text-s);
    line-height: var(--line-lg);
    color: var(--color-secondary);
    margin: 0;
    padding: 0;
  }
}
.footer-block {
  width: 100%;
  max-width: 100%;
  z-index: 10;
  position: absolute;
  right: 0;
  bottom: 0;
  &__empty-state {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: remCalc(90);
    display: flex;
    span {
      @extend %fw-400;
      font-family: $font-base;
      font-size: var(--text-xl);
      line-height: var(--line-xxl);
      letter-spacing: 0px;
      color: var(--color-main);
      display: flex;
      flex-flow: column;
      flex: 1;
    }
  }
  &__join-chat {
    display: flex;
    .join_chat {
      border-left: 1px solid #e1e4e8;
    }
  }
}
.join_chat {
  width: 100%;
  border-bottom-right-radius: remCalc(8);
  border-top: 1px solid var(--color-light-grey);
  background: $white;
  display: flex;
  flex-direction: column;
  flex-flow: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0.6rem;
  &.disabled {
    pointer-events: none;
  }
  p {
    @extend %fw-400;
    font-family: $font-base;
    font-size: var(--text-base);
    line-height: var(--line-base);
    color: var(--color-main);
    padding-top: var(--space-5xs);
  }
  button {
    @extend %fw-700;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    width: fit-content;
    height: remCalc(32);
    background: var(--color-primary);
    border-radius: remCalc(20);
    font-family: $font-base;
    font-size: var(--text-xs);
    line-height: var(--line-md);
    color: $white;
    &:hover {
      background: var(--color-primary-hover);
    }
  }
  &.minimized-join-chat {
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    p {
      font-size: var(--text-xxs);
      line-height: var(--line-xs);
    }
    button {
      height: remCalc(24);
      font-size: var(--text-xs);
      line-height: var(--line-md);
    }
  }
}

#search-empty-clients {
  display: flex;
  top: 8rem;
  left: remCalc(26);
}

.footer-block-forCr {
  width: 100%;
  z-index: 10;
  position: absolute;
  text-align: center;
  bottom: remCalc(80);
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  &__empty-state {
    width: 90%;
    text-align: center;
    position: absolute;
    bottom: 7.5rem;
    span {
      @extend %fw-400;
      font-family: $font-base;
      font-size: var(--text-base);
      line-height: var(--line-base);
      letter-spacing: 0px;
      color: var(--color-main);
    }
    &.footer-block-CR {
      bottom: 4rem;
    }
  }
  .join_chat {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}
.hidden-block {
  width: 100%;
  background: transparent;
  height: remCalc(10);
  display: flex;
  flex-flow: column;
  flex: 0 0 25%;
  min-width: 260px;
  max-width: 500px;
}

.chat-module-loader {
  position: fixed;
  bottom: var(--space-xs);
  right: var(--space-xs);
  padding: var(--space-3xs);
  background: var(--color-primary);
  min-height: remCalc(40);
  min-width: remCalc(40);
  border-radius: 50%;
}
.loading-rooms {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 12;
  display: flex;
  &__loader {
    display: flex;
    flex-flow: column;
    flex: 0 0 25%;
    min-width: 260px;
    max-width: 500px;
    position: relative;
    background: var(--chat-sidemenu-bg-color);
    height: 100%;
    background-color: $white;
  }
  &__empty {
    position: relative;
    height: 100%;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-flow: column;
  }
}
.chat-dropdown {
  max-width: remCalc(242);
  width: max-content;
  height: fit-content;
  position: absolute;
  background-color: $white;
  top: 64px; // It should be with px here
  right: 0;
  box-shadow: var(--box-shadow-dropdown);
  border-bottom-left-radius: var(--space-3xs);
  border-bottom-right-radius: var(--space-3xs);
  padding: var(--space-sm);
  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      @extend %fw-400;
      font-family: $font-base;
      font-size: var(--text-base);
      line-height: var(--line-base);
      letter-spacing: 0px;
      color: var(--color-main);
      padding-left: var(--space-xs);
    }
    svg {
      color: var(--color-primary);
    }
    &:hover {
      span,
      svg {
        color: var(--color-primary-hover);
      }
    }
  }
  &.dropdown-for-large-chat {
    top: 55px; // It should be with px here
    right: -5px; // It should be with px here
  }
}
.header-icons {
  display: flex;
  align-items: center;
  transition: 0.5s ease-in-out;
  margin-left: var(--space-5xs);
  .maximize {
    margin-right: var(--space-5xs);
  }
}
</style>

import { UserRole } from '@/types/user';

export const userRoles = Object.freeze({
  ROLE_ADMIN: UserRole.ROLE_ADMIN,
  ROLE_BUSINESS_SUPPORT: UserRole.ROLE_BUSINESS_SUPPORT,
  ROLE_SUSTAINABILITY_ADVISOR: UserRole.ROLE_SUSTAINABILITY_ADVISOR,
  ROLE_COMPANY_REPRESENTATIVE: UserRole.ROLE_COMPANY_REPRESENTATIVE,
  ROLE_API_USER: UserRole.ROLE_API_USER,
});

export const frontendRolesToBackendRoles = Object.freeze({
  [UserRole.ROLE_ADMIN]: 'ROLE_ADMIN',
  [UserRole.ROLE_BUSINESS_SUPPORT]: 'ROLE_BUSINESS_SUPPORT',
  [UserRole.ROLE_SUSTAINABILITY_ADVISOR]: 'ROLE_SUSTAINABILITY_ADVISOR',
  [UserRole.ROLE_COMPANY_REPRESENTATIVE]: 'ROLE_COMPANY_REPRESENTATIVE',
  [UserRole.ROLE_API_USER]: 'ROLE_API_USER',
});

import { acceptHMRUpdate, defineStore } from 'pinia';
import { NotificationModel, NotificationType } from '@/types/notification';

export const useNotificationStore = defineStore('notification', {
  state: (): NotificationModel => ({
    message: '',
    type: NotificationType.MESSAGE,
    description: '',
    showIcon: false,
    callBackOnClick: undefined,
  }),

  getters: {
    getNotification(state: NotificationModel): NotificationModel {
      return { ...state };
    },
  },
  actions: {
    addNotification(notification: NotificationModel) {
      this.$patch(notification);
    },
    removeNotification() {
      this.$reset();
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot));
}

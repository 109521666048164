import { AllDashBoardItemsType, AllSidebarItemsType, navItem, Status } from '@/types/navigation';
import {
  COMMON_SIDE_BAR_ROUTES,
  DASHBOARD_NESTED_ROUTES,
  MAIN_ROUTES,
  USER_ROLE_ROUTES,
} from '@/config/constants/routes';
import { MODULES_LIST } from '@/config/constants/modules';
import { UserRole } from '@/types/user';

export const userRoleSidebarItems: navItem[] = [
  {
    name: USER_ROLE_ROUTES.CLIENTS.name,
    label: USER_ROLE_ROUTES.CLIENTS.title,
    componentPath: 'components.sidebar.link.clients',
    path: '/clients',
    status: Status.active,
    icon: 'user--multiple',
    type: 'icon',
    role: [
      UserRole.ROLE_ADMIN,
      UserRole.ROLE_BUSINESS_SUPPORT,
      UserRole.ROLE_SUSTAINABILITY_ADVISOR,
    ],
  },
  {
    name: USER_ROLE_ROUTES.SUSTAINABILITY_ADVISORS.name,
    label: USER_ROLE_ROUTES.SUSTAINABILITY_ADVISORS.title,
    componentPath: 'components.sidebar.link.sustainability-advisors',
    status: Status.active,
    path: '/sustainability-advisors',
    icon: 'inventory-management',
    type: 'icon',
    role: [UserRole.ROLE_ADMIN, UserRole.ROLE_BUSINESS_SUPPORT],
  },
  {
    name: USER_ROLE_ROUTES.BUSINESS_SUPPORTS.name,
    label: USER_ROLE_ROUTES.BUSINESS_SUPPORTS.title,
    componentPath: 'components.sidebar.link.business-support',
    path: '/business-supports',
    status: Status.active,
    icon: 'help',
    type: 'icon',
    role: [UserRole.ROLE_ADMIN, UserRole.ROLE_BUSINESS_SUPPORT],
  },
  {
    name: USER_ROLE_ROUTES.COMPANY.name,
    label: USER_ROLE_ROUTES.COMPANY.title,
    componentPath: 'components.sidebar.link.client-company',
    status: Status.active,
    icon: 'enterprise',
    type: 'icon',
    role: [UserRole.ROLE_COMPANY_REPRESENTATIVE],
  },
];

export const iconBarNavItems: navItem[] = [
  {
    name: MAIN_ROUTES.DASHBOARD.name,
    label: MAIN_ROUTES.DASHBOARD.title,
    componentPath: 'component.navbar.link.dashboard',
    status: Status.active,
    icon: 'template',
    type: 'icon',
    path: MAIN_ROUTES.DASHBOARD.path,
  },
  // {
  //   name: MAIN_ROUTES.GLOSSARY.name,
  //   label: MAIN_ROUTES.GLOSSARY.title,
  //   componentPath: 'component.navbar.link.glossary',
  //   status: Status.active,
  //   type: 'icon',
  //   icon: 'language',
  // },
  // {
  //   name: MAIN_ROUTES.SETTINGS.name,
  //   label: MAIN_ROUTES.SETTINGS.title,
  //   componentPath: 'component.navbar.link.personalSettings',
  //   status: Status.active,
  //   type: 'icon',
  //   icon: 'settings',
  //   classes: 'align-bottom',
  // },
];

export const modulesSideNavItems: navItem[] = [
  {
    name: MAIN_ROUTES.RFQ.name,
    label: MAIN_ROUTES.RFQ.title,
    componentPath: 'component.navbar.link.rfq',
    status: Status.active,
    type: 'icon',
    icon: 'request-quote',
    path: MAIN_ROUTES.RFQ.path,
    moduleId: MODULES_LIST.rfq,
  },
  {
    name: COMMON_SIDE_BAR_ROUTES.BLOG.name,
    label: COMMON_SIDE_BAR_ROUTES.BLOG.title,
    componentPath: 'components.sidebar.link.blog',
    status: Status.active,
    icon: 'book',
    type: 'icon',
    path: COMMON_SIDE_BAR_ROUTES.BLOG.path,
    moduleId: MODULES_LIST.blog,
  },
  {
    name: COMMON_SIDE_BAR_ROUTES.NEWSLETTER.name,
    label: COMMON_SIDE_BAR_ROUTES.NEWSLETTER.title,
    componentPath: 'components.sidebar.link.blog',
    status: Status.active,
    icon: 'email',
    type: 'icon',
    path: COMMON_SIDE_BAR_ROUTES.NEWSLETTER.path,
    moduleId: MODULES_LIST.newsletters,
  },
  {
    name: COMMON_SIDE_BAR_ROUTES.DOCUMENT_LIB.name,
    label: COMMON_SIDE_BAR_ROUTES.DOCUMENT_LIB.title,
    componentPath: 'components.sidebar.link.doclib',
    status: Status.active,
    icon: 'document',
    type: 'icon',
    path: COMMON_SIDE_BAR_ROUTES.DOCUMENT_LIB.path,
    moduleId: MODULES_LIST.docLib,
  },
  {
    name: COMMON_SIDE_BAR_ROUTES.MY_DOCUMENTS.name,
    label: COMMON_SIDE_BAR_ROUTES.MY_DOCUMENTS.title,
    componentPath: 'component.navbar.link.client-documents',
    status: Status.active,
    icon: 'license--third-party',
    type: 'icon',
    moduleId: MODULES_LIST.clientDoc,
    role: [UserRole.ROLE_COMPANY_REPRESENTATIVE],
  },
  {
    name: COMMON_SIDE_BAR_ROUTES.CLIENT_DOCUMENTS.name,
    label: COMMON_SIDE_BAR_ROUTES.CLIENT_DOCUMENTS.title,
    componentPath: 'component.navbar.link.client-documents',
    status: Status.active,
    icon: 'license--third-party',
    type: 'icon',
    moduleId: MODULES_LIST.clientDoc,
    role: [UserRole.ROLE_BUSINESS_SUPPORT, UserRole.ROLE_SUSTAINABILITY_ADVISOR],
  },
  {
    name: COMMON_SIDE_BAR_ROUTES.TRANSACTIONS.name,
    label: COMMON_SIDE_BAR_ROUTES.TRANSACTIONS.title,
    componentPath: 'components.sidebar.link.transactions',
    status: Status.active,
    icon: 'data-share',
    type: 'icon',
    path: COMMON_SIDE_BAR_ROUTES.TRANSACTIONS.path,
    moduleId: MODULES_LIST.transactions,
  },
  {
    name: COMMON_SIDE_BAR_ROUTES.PRICES.name,
    label: COMMON_SIDE_BAR_ROUTES.PRICES.title,
    componentPath: 'components.sidebar.link.price',
    status: Status.active,
    icon: 'money',
    type: 'icon',
    path: COMMON_SIDE_BAR_ROUTES.PRICES.path,
    moduleId: MODULES_LIST.price,
  },
  {
    name: COMMON_SIDE_BAR_ROUTES.WEBINARS.name,
    label: COMMON_SIDE_BAR_ROUTES.WEBINARS.title,
    componentPath: 'components.sidebar.link.webinars',
    status: Status.active,
    icon: 'media--library',
    type: 'icon',
    path: COMMON_SIDE_BAR_ROUTES.WEBINARS.path,
    moduleId: MODULES_LIST.webinars,
  },
  {
    name: MAIN_ROUTES.CHAT.name,
    label: MAIN_ROUTES.CHAT.title,
    componentPath: 'component.navbar.link.chat',
    status: Status.active,
    icon: 'chat',
    type: 'icon',
    moduleId: MODULES_LIST.chat,
    role: [
      UserRole.ROLE_ADMIN,
      UserRole.ROLE_BUSINESS_SUPPORT,
      UserRole.ROLE_SUSTAINABILITY_ADVISOR,
    ],
  },
  {
    name: MAIN_ROUTES.CALENDAR.name,
    label: MAIN_ROUTES.CALENDAR.title,
    componentPath: 'component.navbar.link.calendar',
    status: Status.active,
    type: 'icon',
    icon: 'calendar--heat-map',
    moduleId: MODULES_LIST.calendar,
  },
  {
    name: COMMON_SIDE_BAR_ROUTES.COMMODITY_VOLUMES.name,
    label: COMMON_SIDE_BAR_ROUTES.COMMODITY_VOLUMES.title,
    componentPath: 'components.sidebar.link.commodity-volumes',
    status: Status.active,
    icon: 'chart--combo-stacked',
    type: 'icon',
    path: COMMON_SIDE_BAR_ROUTES.COMMODITY_VOLUMES.path,
    moduleId: MODULES_LIST.commodityVolumes,
  },
  {
    name: MAIN_ROUTES.IOI_OVERVIEW.name,
    label: MAIN_ROUTES.IOI_OVERVIEW.title,
    componentPath: 'components.sidebar.link.indication-of-interests',
    status: Status.active,
    icon: 'user--activity',
    type: 'icon',
    path: MAIN_ROUTES.IOI_OVERVIEW.path,
    moduleId: MODULES_LIST.indicationOfInterests,
  },
  {
    name: MAIN_ROUTES.ENERGY_EXCHANGE_TRADE.name,
    label: MAIN_ROUTES.ENERGY_EXCHANGE_TRADE.title,
    componentPath: 'components.sidebar.link.energy-exchange-trade',
    status: Status.active,
    icon: 'analytics',
    type: 'icon',
    path: MAIN_ROUTES.ENERGY_EXCHANGE_TRADE.path,
    moduleId: MODULES_LIST.energyExchange,
  },
  {
    name: MAIN_ROUTES.ENERGY_EXCHANGE_MANAGEMENT.name,
    label: MAIN_ROUTES.ENERGY_EXCHANGE_MANAGEMENT.title,
    componentPath: 'components.sidebar.link.energy-exchange-trade',
    status: Status.active,
    icon: 'analytics',
    type: 'icon',
    path: MAIN_ROUTES.ENERGY_EXCHANGE_MANAGEMENT.path,
    moduleId: MODULES_LIST.energyExchangeManagement,
  },
  {
    name: MAIN_ROUTES.ENERGY_EXCHANGE_CLIENT.name,
    label: MAIN_ROUTES.ENERGY_EXCHANGE_CLIENT.title,
    componentPath: 'components.sidebar.link.energy-exchange-client',
    status: Status.active,
    icon: 'analytics',
    type: 'icon',
    path: MAIN_ROUTES.ENERGY_EXCHANGE_CLIENT.path,
    moduleId: MODULES_LIST.energyExchangeClient,
  },
];

export const allSideNavItems: AllSidebarItemsType = {
  common: [
    {
      name: COMMON_SIDE_BAR_ROUTES.DOCUMENT_LIB.name,
      label: COMMON_SIDE_BAR_ROUTES.DOCUMENT_LIB.title,
      componentPath: 'components.sidebar.link.doclib',
      status: Status.active,
      icon: 'archive',
      type: 'horizontal',
      path: COMMON_SIDE_BAR_ROUTES.DOCUMENT_LIB.path,
    },
    {
      name: COMMON_SIDE_BAR_ROUTES.BLOG.name,
      label: COMMON_SIDE_BAR_ROUTES.BLOG.title,
      componentPath: 'components.sidebar.link.blog',
      status: Status.active,
      icon: 'blog',
      type: 'horizontal',
      path: COMMON_SIDE_BAR_ROUTES.BLOG.path,
    },
  ],
  ppa: [
    {
      name: DASHBOARD_NESTED_ROUTES.PPA.name,
      label: DASHBOARD_NESTED_ROUTES.PPA.title,
      componentPath: 'component.sidemenu.ppa',
      status: Status.active,
      icon: 'dashboard',
      type: 'horizontal',
      path: '/dashboard/ppa',
    },
    {
      name: DASHBOARD_NESTED_ROUTES.ADD_WIND_FARM.name,
      label: DASHBOARD_NESTED_ROUTES.ADD_WIND_FARM.title,
      componentPath: 'component.sidemenu.ppa.add-wind-farm',
      status: Status.active,
      icon: 'wind-power',
      type: 'horizontal',
      path: '/dashboard/ppa/add-wind-farm',
    },
    {
      name: DASHBOARD_NESTED_ROUTES.ADD_SOLAR_FARM.name,
      label: DASHBOARD_NESTED_ROUTES.ADD_SOLAR_FARM.title,
      componentPath: 'component.sidemenu.captable.companyCapital',
      status: Status.active,
      icon: 'solar-panel',
      type: 'horizontal',
      path: '/dashboard/ppa/add-solar-farm',
    },
    {
      name: DASHBOARD_NESTED_ROUTES.MARKET_DATA.name,
      label: DASHBOARD_NESTED_ROUTES.MARKET_DATA.title,
      componentPath: 'component.sidemenu.captable.holders',
      status: Status.active,
      icon: 'events',
      type: 'horizontal',
      path: '/dashboard/ppa/market-data',
    },
  ],
  fgas: [
    {
      name: DASHBOARD_NESTED_ROUTES.FGAS.name,
      label: DASHBOARD_NESTED_ROUTES.FGAS.title,
      componentPath: 'component.sidemenu.captable.holders',
      status: Status.active,
      icon: 'carbon-accounting',
      type: 'horizontal',
      path: '/dashboard/fgas',
    },
    {
      name: 'testing-sub-product-fgas',
      label: DASHBOARD_NESTED_ROUTES.FGAS.title,
      componentPath: 'component.sidemenu.captable.holders',
      status: Status.active,
      icon: 'carbon-accounting',
      type: 'horizontal',
      path: '/dashboard/fgas/testing-sub-product-fgas',
    },
  ],
  go: [
    {
      name: DASHBOARD_NESTED_ROUTES.GO.name,
      label: DASHBOARD_NESTED_ROUTES.GO.title,
      componentPath: 'component.sidemenu.captable.holders',
      status: Status.active,
      icon: 'certificate--check',
      type: 'horizontal',
      path: '/dashboard/go',
    },
    {
      name: 'testing-sub-product-go',
      label: DASHBOARD_NESTED_ROUTES.GO.title,
      componentPath: 'component.sidemenu.captable.something',
      status: Status.active,
      icon: 'certificate--check',
      type: 'horizontal',
      path: '/dashboard/go/testing-sub-product-go',
    },
  ],
  ggc: [
    {
      name: DASHBOARD_NESTED_ROUTES.GGC.name,
      label: DASHBOARD_NESTED_ROUTES.GGC.title,
      componentPath: 'component.sidemenu.captable.holders',
      status: Status.active,
      icon: 'energyRenewableIcon',
      type: 'horizontal',
      path: '/dashboard/gcc',
    },
    {
      name: 'testing-sub-product-gcc',
      label: DASHBOARD_NESTED_ROUTES.GGC.title,
      componentPath: 'component.sidemenu.captable.holders',
      status: Status.active,
      icon: 'energyRenewableIcon',
      type: 'horizontal',
      path: '/dashboard/gcc/testing-sub-product-gcc',
    },
  ],
};

export const allDashBoardItems: AllDashBoardItemsType = [
  {
    ppa: [
      {
        name: DASHBOARD_NESTED_ROUTES.PPA.name,
        label: DASHBOARD_NESTED_ROUTES.PPA.title,
        componentPath: 'component.sidemenu.ppa',
        status: Status.active,
        icon: 'dashboard',
        type: 'dashboard',
        path: '/dashboard/ppa',
      },
    ],
  },
  {
    fgas: [
      {
        name: DASHBOARD_NESTED_ROUTES.FGAS.name,
        label: DASHBOARD_NESTED_ROUTES.FGAS.title,
        componentPath: 'component.sidemenu.captable.holders',
        status: Status.active,
        icon: 'carbon-accounting',
        type: 'dashboard',
        path: '/dashboard/fgas',
      },
    ],
  },
  {
    go: [
      {
        name: DASHBOARD_NESTED_ROUTES.GO.name,
        label: DASHBOARD_NESTED_ROUTES.GO.title,
        componentPath: 'component.sidemenu.captable.holders',
        status: Status.active,
        icon: 'certificate--check',
        type: 'dashboard',
        path: '/dashboard/go',
      },
    ],
  },
  {
    ggc: [
      {
        name: DASHBOARD_NESTED_ROUTES.GGC.name,
        label: DASHBOARD_NESTED_ROUTES.GGC.title,
        componentPath: 'component.sidemenu.captable.holders',
        status: Status.active,
        icon: 'energy--renewable',
        type: 'dashboard',
        path: '/dashboard/ggc',
      },
    ],
  },
];

import axios from 'axios';
import debug from 'debug';
import { ref, onUnmounted, Ref, computed, onMounted } from 'vue';
import * as Sentry from '@sentry/vue';
import { HistoricalPrice, PriceServiceMessage } from '@/types/energy-exchange';
import { useNotificationStore, useUserStore } from '@/store';
import { NotificationType } from '@/types/notification';

interface PriceServiceWebSocket {
  socket: Ref<WebSocket | null>;
  messages: Ref<PriceServiceMessage[]>;
  connect: (url: string) => void;
  disconnect: () => void;
  status: Ref<string>;
  latestPrices: () => void;
}

const messages = ref<PriceServiceMessage[]>([]);
export const latestIntradayPrice = ref<HistoricalPrice | null>(null);
export const latestBidAsk = computed(() => {
  const latestValidAsk = messages.value.find((msg) => msg.ask);
  const latestValidBid = messages.value.find((msg) => msg.bid);

  return {
    bid: latestValidBid,
    ask: latestValidAsk,
  };
});

const log = debug('websocket:price-service');
const RECONNECT_INTERVAL = 3000;
const MAX_RECONNECT_ATTEMPTS = 5;

export function useWebSocketPriceService(): PriceServiceWebSocket {
  const socket = ref<WebSocket | null>(null);
  const status = ref('Disconnected');
  const manuallyDisconnected = ref(false);
  const userStore = useUserStore();
  const { addNotification } = useNotificationStore();
  const reconnectAttempts = ref(0);
  const reconnectTimeout = ref<number | null>(null);

  const connect = () => {
    const url = `${import.meta.env.VITE_PRICE_SERVICE_URL}/ws`;
    log('Attempting to connect to %s', url);
    socket.value = new WebSocket(url);

    socket.value.onopen = () => {
      status.value = 'Connected';
      const subscriptionMessage = {
        action: 'subscribe',
        channel: 'ETS',
        token: userStore.accessToken,
      };
      socket.value?.send(JSON.stringify(subscriptionMessage));
      reconnectAttempts.value = 0;
      log('WebSocket connection opened');
    };

    socket.value.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.id && (message.ask || message.bid)) {
        messages.value.splice(0, 0, message);

        // Limit length of array for long time performance
        if (messages.value.length > 500) {
          messages.value.splice(300);
        }
      }

      log('Received message: %o', message);
    };

    socket.value.onclose = (event) => {
      status.value = 'Disconnected';

      if (!manuallyDisconnected.value) {
        log('WebSocket disconnected. Code: %d, Reason: %s', event.code, event.reason);
        Sentry.captureException(new Error('websocket connection failed'), {
          extra: {
            code: event.code,
            reason: event.reason,
          },
        });
        if (reconnectAttempts.value < MAX_RECONNECT_ATTEMPTS) {
          reconnectAttempts.value++;
          reconnectTimeout.value = window.setTimeout(() => {
            log('Attempting to reconnect (%d/%d)', reconnectAttempts.value, MAX_RECONNECT_ATTEMPTS);
            connect();
          }, RECONNECT_INTERVAL);
        } else {
          addNotification({
            message: 'Failed to reconnect to the price service. Please refresh the page.',
            type: NotificationType.ERROR,
            showIcon: true,
          });
          Sentry.captureException(new Error('websocket failed to reconnect'));
        }
      }
    };

    socket.value.onerror = () => {
      status.value = 'Error';
      Sentry.captureException(new Error('websocket onerror'));
    };
  };

  const disconnect = () => {
    if (reconnectTimeout.value) {
      clearTimeout(reconnectTimeout.value);
    }
    socket.value?.close();
    socket.value = null;
    status.value = 'Disconnected';
    manuallyDisconnected.value = true;
    log('WebSocket disconnected');
  };

  const latestPrices = async () => {
    try {
      const res = await axios.get(
        `${import.meta.env.VITE_PRICE_SERVICE_URL}/price/latest?commodity=ETS`,
        {
          headers: {
            Authorization: `Bearer ${userStore.accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );
      if (!latestBidAsk.value.bid && !latestBidAsk.value.ask) {
        res.data.forEach((data: PriceServiceMessage) => {
          if (data.bid && !latestBidAsk.value.bid) {
            messages.value.push(data);
          }
          if (data.ask && !latestBidAsk.value.ask) {
            messages.value.push(data);
          }
        });
      }
    } catch (error) {
      addNotification({
        message: String(error),
        type: NotificationType.ERROR,
        showIcon: true,
      });
    }
  };

  let heartbeatInterval: null | number = null;

  onUnmounted(() => {
    disconnect();
    if (heartbeatInterval) {
      clearInterval(heartbeatInterval);
    }
  });

  onMounted(() => {
    heartbeatInterval = window.setInterval(() => {
      if (socket.value?.readyState === WebSocket.OPEN) {
        socket.value.send(JSON.stringify({ action: 'heartbeat' }));
        log('Heartbeat sent');
      }
    }, 30000);
  });

  return {
    socket,
    messages,
    connect,
    disconnect,
    status,
    latestPrices,
  };
}
